import React, {useMemo, useCallback} from 'react';
import scriptLoader from 'react-async-script-loader';

import {Stack, Button, Skeleton, Divider, Box} from '@mui/material';
import dayjs from 'dayjs';

import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {FeedbackAppointment} from '@/organisms/Feedback/FeedbackAppointment';
import {FeedbackNotes} from '@/organisms/Feedback/FeedbackNotes';
import {FeedbackRefusalReasons} from '@/organisms/Feedback/FeedbackRefusalReasons';
import {FeedbackReminder} from '@/organisms/Feedback/FeedbackReminder';
import {FeedbackStatus} from '@/organisms/Feedback/FeedbackStatus';
import {statusDisplay} from '@/organisms/Feedback/constants';
import {
  FeedbackProps,
  DisplayFieldsConfiguration,
  DisplayFields,
} from '@/organisms/Feedback/interfaces';
import {feedbackDisplayConfiguration} from '@/pages/Clienteling/SubPages/TaskDetails';
import {
  FEEDBACK_FIELD_CODES,
  FeedbackStatusConfiguration,
  FeedbackConfiguration,
  FEEDBACK_STATUS,
  FeedbackRefusalReasonConfiguration,
  FeedbackAppointmentSettingConfiguration,
} from '@/store/feedback/v2';

const Feedback = ({
  customer,
  feedbackState,
  feedbackChanged,
  feedbackConfiguration,
  onFeedbackSave,
  handleFeedbackChange,
  appointment,
  taskHeaderData,
  taskId,
  handleCreateTaskAppointment,
  handleDisassociateTaskAppointment,
  handleUpdateTaskAppointment,
  handleDeleteAppointment,
  customerError,
}: FeedbackProps) => {
  const {getTranslationWithValue} = useBaseTranslation('FeedbackBox');

  /**
   * @brief Display fields
   * @note This function is used to display fields based on feedback configuration
   */
  const displayFields = useMemo(() => {
    let fields: DisplayFieldsConfiguration = {
      [FEEDBACK_FIELD_CODES.NOTE]: false,
      [FEEDBACK_FIELD_CODES.C_DATE_TIME]: false,
      [FEEDBACK_FIELD_CODES.REFUSAL_REASON]: false,
      [FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING]: false,
    };

    if (!feedbackConfiguration || !feedbackState) {
      return fields;
    }

    feedbackConfiguration?.forEach(config => {
      switch (config.fieldCode) {
        case FEEDBACK_FIELD_CODES.NOTE:
          fields[FEEDBACK_FIELD_CODES.NOTE] = true;
          break;
        case FEEDBACK_FIELD_CODES.C_DATE_TIME:
          const showCallbackDateTime = statusDisplay[
            FEEDBACK_FIELD_CODES.C_DATE_TIME
          ].includes(feedbackState?.status!);

          fields[FEEDBACK_FIELD_CODES.C_DATE_TIME] = showCallbackDateTime;

          if (
            feedbackState?.callBackDateTime !== null &&
            !showCallbackDateTime
          ) {
            handleFeedbackChange('callBackDateTime', null);
          }

          break;
        case FEEDBACK_FIELD_CODES.REFUSAL_REASON:
          const showRefusalReason = statusDisplay[
            FEEDBACK_FIELD_CODES.REFUSAL_REASON
          ].includes(feedbackState?.status!);

          fields[FEEDBACK_FIELD_CODES.REFUSAL_REASON] = showRefusalReason;

          if (feedbackState?.refusalReason !== null && !showRefusalReason) {
            handleFeedbackChange('refusalReason', null);
          }

          break;
        case FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING:
          fields[FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING] =
            config?.fieldAcceptedValue!?.TAB_LITE;
          break;
      }
    });

    return fields;
  }, [feedbackConfiguration, feedbackState, handleFeedbackChange]);

  /**
   * @brief Is loading component
   * @note This variable is used to check if the component is loading
   */
  const isLoadingComponent = useMemo(
    () => !feedbackState || !feedbackConfiguration,
    [feedbackConfiguration, feedbackState],
  );

  /**
   * @brief Can save feedback
   * @note This variable is used to check if feedback can be saved
   */
  const canSaveFeedback = useMemo(
    () => !isLoadingComponent && feedbackChanged,
    [feedbackChanged, isLoadingComponent],
  );

  /**
   * @brief Render heading
   * @note This function is used to render heading
   */
  const renderHeading = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeadlineText superHeavy>
          {getTranslationWithValue(0, 'title')}
        </HeadlineText>
        <Button
          variant="basePrimary"
          disabled={!canSaveFeedback}
          {...(canSaveFeedback && {
            onClick: () => onFeedbackSave(),
          })}>
          {getTranslationWithValue(0, 'saveChanges')}
        </Button>
      </Stack>
    );
  }, [canSaveFeedback, getTranslationWithValue, onFeedbackSave]);

  /**
   * @brief Get configuration
   * @note This function is used to get configuration based on field code
   */
  const getConfiguration = useCallback(
    <T,>(fieldCode: FEEDBACK_FIELD_CODES): FeedbackConfiguration<T> => {
      return feedbackConfiguration?.find(
        config => config.fieldCode === fieldCode,
      ) as FeedbackConfiguration<T>;
    },
    [feedbackConfiguration],
  );

  /**
   * @brief Current feedback display config
   * @note This variable is used to get current feedback display configuration
   */
  const currentFbDisplayConfig = useMemo(() => {
    return feedbackDisplayConfiguration[feedbackState?.status!];
  }, [feedbackState?.status]);

  /**
   * @brief Render content
   * @note This function is used to render content
   */
  const renderContent = useMemo(() => {
    if (isLoadingComponent) {
      return <Skeleton height="370px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack
        width="100%"
        direction="column"
        gap={1.6}
        divider={<Divider orientation="horizontal" />}>
        <FeedbackStatus
          key={FEEDBACK_FIELD_CODES.STATUS}
          selectedStatus={feedbackState!.status!}
          configuration={
            getConfiguration<FeedbackStatusConfiguration>(
              FEEDBACK_FIELD_CODES.STATUS,
            )!
          }
          onStatusChange={status => handleFeedbackChange('status', status)}
          taskIsEditable={taskHeaderData?.taskIsEditable!}
        />
        {currentFbDisplayConfig.reduce((acc, fieldCode) => {
          if (!displayFields?.[fieldCode as DisplayFields]) return acc;

          switch (fieldCode) {
            case FEEDBACK_FIELD_CODES.NOTE:
              return [
                ...acc,
                <Box key={fieldCode} marginLeft={1.6}>
                  <FeedbackNotes
                    taskIsEditable={taskHeaderData?.taskIsEditable!}
                    currentNote={feedbackState!.note}
                    updatedOn={feedbackState!.updatedNote}
                    onNoteChange={note => {
                      handleFeedbackChange('note', note);
                      handleFeedbackChange(
                        'updatedNote',
                        note !== null ? dayjs().toISOString() : null,
                      );
                    }}
                  />
                </Box>,
              ];
            case FEEDBACK_FIELD_CODES.C_DATE_TIME:
              return [
                ...acc,
                <Box key={fieldCode} marginLeft={1.6}>
                  <FeedbackReminder
                    taskIsEditable={taskHeaderData?.taskIsEditable!}
                    taskHeaderData={taskHeaderData}
                    currentReminder={feedbackState!.callBackDateTime}
                    onReminderChange={reminder => {
                      handleFeedbackChange('status', FEEDBACK_STATUS.WAITING);
                      handleFeedbackChange('callBackDateTime', reminder);
                    }}
                  />
                </Box>,
              ];
            case FEEDBACK_FIELD_CODES.REFUSAL_REASON:
              return [
                ...acc,
                <Box key={fieldCode}>
                  <FeedbackRefusalReasons
                    taskIsEditable={taskHeaderData?.taskIsEditable!}
                    selectedReason={feedbackState!.refusalReason}
                    configuration={
                      getConfiguration<FeedbackRefusalReasonConfiguration>(
                        FEEDBACK_FIELD_CODES.REFUSAL_REASON,
                      )!
                    }
                    onReasonChange={reason =>
                      handleFeedbackChange('refusalReason', reason)
                    }
                  />
                </Box>,
              ];
            case FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING:
              if (!customer || customerError) return acc;

              return [
                ...acc,
                <FeedbackAppointment
                  customer={customer}
                  key={fieldCode}
                  taskIsEditable={taskHeaderData?.taskIsEditable!}
                  configuration={
                    getConfiguration<FeedbackAppointmentSettingConfiguration>(
                      FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
                    )!
                  }
                  appointment={appointment}
                  taskHeaderData={taskHeaderData}
                  taskId={taskId}
                  handleCreateTaskAppointment={handleCreateTaskAppointment}
                  handleDisassociateTaskAppointment={
                    handleDisassociateTaskAppointment
                  }
                  handleUpdateTaskAppointment={handleUpdateTaskAppointment}
                  handleDeleteAppointment={handleDeleteAppointment}
                  feedbackStatus={feedbackState!.status!}
                />,
              ];
            default:
              return acc;
          }
        }, [] as Array<JSX.Element>)}
      </Stack>
    );
  }, [
    appointment,
    currentFbDisplayConfig,
    customer,
    customerError,
    displayFields,
    feedbackState,
    getConfiguration,
    handleCreateTaskAppointment,
    handleDeleteAppointment,
    handleDisassociateTaskAppointment,
    handleFeedbackChange,
    handleUpdateTaskAppointment,
    isLoadingComponent,
    taskHeaderData,
    taskId,
  ]);

  return (
    <Card>
      <Stack direction="column" gap={2.4}>
        {renderHeading}
        {renderContent}
      </Stack>
    </Card>
  );
};

export default React.memo(
  scriptLoader([
    process.env.REACT_APP_WIDGET_SRC,
    process.env.REACT_APP_WIDGET_POLYFILL_SRC,
    process.env.REACT_APP_WIDGET_UTILS_SRC,
  ])(Feedback),
);
