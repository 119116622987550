import {PropsWithChildren, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Utc from 'dayjs/plugin/utc';

import {useAuthentication} from '../authentication';

import('dayjs/locale/it');
import('dayjs/locale/en');
import('dayjs/locale/es');

dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(Utc);

export const useLocale = () => {
  const {i18n} = useTranslation();
  const {userData} = useAuthentication();

  const getLocale = useCallback(() => {
    if (!userData?.language) {
      return 'en';
    }
    switch (userData?.language.toLowerCase()) {
      case 'us':
        return 'en';
      default:
        return userData?.language.toLowerCase();
    }
  }, [userData]);

  const setLocale = useCallback(() => {
    const formattedCountry = getLocale();
    i18n.changeLanguage(formattedCountry).finally(() => {
      dayjs.locale(formattedCountry);
    });
  }, [getLocale, i18n]);

  useEffect(() => {
    setLocale();
  }, [setLocale]);

  const formatDate = useCallback(
    (date: string | Dayjs) => {
      const dayObj: Dayjs = dayjs(date).locale(i18n?.language);
      return `${dayObj.utc().format('ll')}`;
    },
    [i18n?.language],
  );

  return {getLocale, setLocale, formatDate};
};

export const LocalizationDayjs = ({children}: PropsWithChildren) => {
  const {getLocale} = useLocale();
  const {t} = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocale()}
      localeText={{
        clearButtonLabel: t('Calendar.ctas.clear'),
        okButtonLabel: t('Calendar.ctas.ok'),
        cancelButtonLabel: t('Calendar.ctas.cancel'),
      }}
      children={children}
    />
  );
};
