import {useMemo, useCallback} from 'react';

export const useScrollPosition = () => {
  const saveScrollPositionById = useCallback((divId: string) => {
    const elementById = document.getElementById(divId);
    if (!elementById) return;

    const scrollPosition = elementById.scrollTop;
    sessionStorage.setItem(divId, scrollPosition.toString());
  }, []);

  const deleteScrollPositionById = useCallback((divId: string) => {
    sessionStorage.removeItem(divId);
  }, []);

  const getScrollPositionById = useCallback((divId: string) => {
    const scrollPosition = sessionStorage.getItem(divId);
    return scrollPosition ? parseInt(scrollPosition) : null;
  }, []);

  return useMemo(
    () => ({
      saveScrollPositionById,
      deleteScrollPositionById,
      getScrollPositionById,
    }),
    [saveScrollPositionById, deleteScrollPositionById, getScrollPositionById],
  );
};
