import React, {useMemo} from 'react';

import {Stack} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {CellProps} from '@/molecules/TableCell/interfaces';

interface IconsCellProps extends CellProps {
  icons: Array<IconNames>;
}

const IconsCell = ({icons}: IconsCellProps) => {
  const renderIcons = useMemo(() => {
    if (icons?.length === 0) {
      return '-';
    }

    return icons?.map((icon, index) => {
      return <IconSVG key={`${icon}_${index}`} icon={icon} size={24} />;
    });
  }, [icons]);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap={0.8}
      justifyContent={'center'}>
      {renderIcons}
    </Stack>
  );
};

export default React.memo(IconsCell);
