import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ColumnDef} from '@tanstack/react-table';

import {Data} from '@/atoms/DataTable/interfaces';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import IconsCell from '@/molecules/TableCell/IconsCell/IconsCell';
import {LOYALTY_CHANNEL} from '@/store/benefits/interfaces.d';

import {
  BaseCell,
  ButtonCell,
  DateCell,
  StatusCell,
} from '../../molecules/TableCell';
import {useStyle} from './styles';

export const useColumnDef = () => {
  const {t} = useTranslation();
  const {chip} = useStyle();

  const RewardListColumnDef = useMemo<ColumnDef<Data>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => t('CustomerProfile.tables.rewardList.header.id'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'name',
        header: () => t('CustomerProfile.tables.rewardList.header.reward'),
        cell: info => (
          <BaseCell {...info} tooltip={info?.row?.original?.description} />
        ),
      },
      {
        accessorKey: 'promocodeEcommerce',
        header: () => t('CustomerProfile.tables.rewardList.header.coupon'),
        cell: info => {
          if (
            info.row.original.status === 'EXPIRED' ||
            info.row.original.status === 'USED'
          ) {
            return <></>;
          }
          return <BaseCell {...info} />;
        },
      },
      {
        accessorKey: 'upc',
        header: () => t('CustomerProfile.tables.rewardList.header.upc'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'status',
        header: () => t('CustomerProfile.tables.rewardList.header.status'),
        cell: info => (
          <StatusCell
            {...info}
            sx={chip(info.row.original.statusColor)}
            {...(info.row.original.statusAction && {
              onClick: info.row.original.statusAction,
            })}
          />
        ),
      },
      {
        accessorKey: 'channel',
        header: () => t('CustomerProfile.tables.rewardList.header.channel'),
        cell: info => {
          const value = info?.getValue() as LOYALTY_CHANNEL;
          const icons = [] as Array<IconNames>;
          switch (value) {
            case LOYALTY_CHANNEL.ECOMM:
              icons.push('web');
              break;
            case LOYALTY_CHANNEL.RETAIL:
              icons.push('shop');
              break;
            case LOYALTY_CHANNEL.ECOMM_RETAIL:
              icons.push('shop');
              icons.push('web');
              break;
          }

          return <IconsCell {...info} icons={icons} />;
        },
      },
      {
        accessorKey: 'lastUsage',
        header: () => t('CustomerProfile.tables.rewardList.header.lastUsageDT'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'remainingUsages',
        header: () =>
          t('CustomerProfile.tables.rewardList.header.remainingUsages'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'expirationDate',
        header: () =>
          t('CustomerProfile.tables.rewardList.header.expirationDate'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'actions',
        header: () => t('CustomerProfile.tables.rewardList.header.action'),
        cell: info => <ButtonCell {...info} />,
      },
    ],
    [chip, t],
  );

  return {RewardListColumnDef};
};
