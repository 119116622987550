import React from 'react';
import {createRoot} from 'react-dom/client';
import ReactGA from 'react-ga4';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {CssBaseline, ThemeProvider} from '@mui/material';
import {BrowserTracing} from '@sentry/browser';
import * as Sentry from '@sentry/react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import 'reset-css';

import {LocalizationDayjs} from '@/store/locale';
import TranslationProvider from '@/translations/TranslationProvider';

import './hooks/toast/Toast.scss';
import {Navigation} from './navigation';
import reportWebVitals from './reportWebVitals';
import {initializeStore} from './store/app';
import theme from './themes/theme';
import './themes/variables.scss';

if (
  process.env.REACT_APP_ENVIRONMENT !== 'local' &&
  process.env.REACT_APP_ENVIRONMENT !== 'production'
) {
  Sentry.init({
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENVIRONMENT,

    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (
  process.env.REACT_APP_ENVIRONMENT !== 'local' &&
  process.env.REACT_APP_ENVIRONMENT !== 'development'
) {
  ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
}

export const queryClient = new QueryClient();

const store = initializeStore();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <LocalizationDayjs>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Navigation />
              <ToastContainer />
            </ThemeProvider>
          </LocalizationDayjs>
        </TranslationProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
