import React, {ChangeEvent, useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {Box, Button} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Dispatch} from 'redux';
import {useDebounce} from 'use-debounce';

import {Dialog} from '@/atoms/Dialog';
import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';
import {RoleName} from '@/definitions/users';
import {useToast} from '@/hooks/toast';
import {useCurrentModule} from '@/hooks/useCurrentModule';
import SearchInputRestyle from '@/molecules/SearchInputRestyle';
import {AuthRoutes, roleToRoute} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useAuthentication} from '@/store/authentication';
import {MembersReportFiltersAction} from '@/store/membersReportFilters/definitions';
import {MembersReportFiltersReset} from '@/store/membersReportFilters/membersReportFilters.actions';
import {usePersonas} from '@/store/personas';
import {ReportDetailsFiltersAction} from '@/store/reportDetailsFilters/definitions';
import {ReportDetailsFiltersReset} from '@/store/reportDetailsFilters/reportDetailsFilters.actions';
import {ReportFiltersAction} from '@/store/reportFilters/interfaces';
import {ReportFiltersReset} from '@/store/reportFilters/reportFilters.actions';
import {useStores} from '@/store/stores';
import {Colors} from '@/themes/variables';

import {StoresList} from './Screens';
import {SearchStoreProps} from './interfaces';
import {useStyles} from './styles';

const SearchStore = ({
  open,
  setOpen,
  currentStoreId,
  currentBanner,
  currentCountry,
  role,
}: SearchStoreProps) => {
  const {showSuccessStoreChangeToast, showErrorToast} = useToast();
  const {t} = useTranslation();
  const {header, dialog, contentStoreWrapper, bottomGradient, cta} =
    useStyles();
  const {getStores, patchStoreByStoreId} = useStores();
  const {getBannerCountry} = usePersonas();
  const {replacePath} = useNavigation();
  const {getAuth} = useAuthentication();
  const {currentModule} = useCurrentModule();
  const dispatch =
    useDispatch<
      Dispatch<
        | ReportDetailsFiltersAction
        | MembersReportFiltersAction
        | ReportFiltersAction
      >
    >();

  const [searchString, setSearchString] = useState<string>('');
  const [debouncedString] = useDebounce(searchString, 300);
  const [selectedStoreId, setSelectedStoreId] = useState<number | undefined>(
    currentStoreId,
  );
  const [selectedBanner, setSelectedBanner] = useState<string | undefined>(
    currentBanner,
  );
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    currentCountry,
  );

  const canSearch = useMemo(() => {
    if (role?.name !== RoleName.AREA_MANAGER) {
      return true;
    }

    return !!selectedBanner && !!selectedCountry;
  }, [role?.name, selectedBanner, selectedCountry]);

  /**
   * Close modal
   */
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const {data: BannerCountry} = useQuery(
    ['getBannerCountry', selectedBanner],
    () => getBannerCountry(selectedBanner),
    {
      enabled: role?.name === RoleName.AREA_MANAGER,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: ({countries}) => {
        if (!countries?.find(country => country.name === selectedCountry)) {
          setSelectedCountry(countries[0]?.name || selectedCountry);
        }
      },
    },
  );

  /**
   * API to retrieve stores by debounceString
   */
  const {data: Stores, isLoading: StoresLoading} = useQuery(
    ['stores', debouncedString, selectedBanner, selectedCountry],
    () =>
      getStores({
        q: debouncedString,
        page: 1,
        limit: 20,
        ...(role?.name === RoleName.AREA_MANAGER && {
          banner: selectedBanner,
          country: selectedCountry,
        }),
      }),
    {
      enabled: canSearch,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleChangeStoreSuccess = useCallback(async () => {
    await getAuth().then(value => {
      if (value) {
        dispatch(ReportDetailsFiltersReset());
        dispatch(MembersReportFiltersReset());
        dispatch(ReportFiltersReset());
        closeModal();
        const role = value?.roles[0]?.name;
        const newModules = value?.configuration?.moduleActive;
        sessionStorage.clear();

        if (newModules?.includes(currentModule!)) {
          const defaultRootForCurrentModule =
            roleToRoute[role][currentModule!]!;
          replacePath(defaultRootForCurrentModule);
        } else {
          replacePath(AuthRoutes.MAIN);
        }
        showSuccessStoreChangeToast(
          t('Stores.toast.success'),
          `${value?.currentStore?.storeId}`,
          `${value?.currentStore?.storeName}`,
        );
      }
    });
  }, [
    closeModal,
    currentModule,
    dispatch,
    getAuth,
    replacePath,
    showSuccessStoreChangeToast,
    t,
  ]);

  /**
   * API to change store
   */
  const {isLoading: SingleStoreLoading, mutate: PatchSingleStore} = useMutation(
    patchStoreByStoreId,
    {
      onSuccess: async () => {
        await handleChangeStoreSuccess();
      },
      onError: () => {
        showErrorToast(t('Stores.toast.error'));
      },
    },
  );

  /**
   * Handle api loading state
   */
  const apiIsLoading = StoresLoading || SingleStoreLoading;

  /**
   * Check if store selection can be submitted
   * A store must be selected
   * The selected store must not be the currently active
   */
  const storeIsSelected = Boolean(selectedStoreId);
  const isNotCurrentStore = selectedStoreId !== currentStoreId;
  const canSubmit = storeIsSelected && isNotCurrentStore && !apiIsLoading;

  /**
   * Handle store selection
   * Deselect store if the active one is clicked
   */
  const handleStoreSelection = useCallback((newStoreId: number) => {
    setSelectedStoreId(prevStoreId => {
      return prevStoreId === newStoreId ? undefined : newStoreId;
    });
  }, []);

  const handleStoreSubmit = useCallback(() => {
    PatchSingleStore(selectedStoreId);
  }, [PatchSingleStore, selectedStoreId]);

  /**
   * Handle input search
   */
  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(_ => e.target.value);
  }, []);

  return (
    <Dialog
      sx={dialog}
      open={open}
      dialogContent={
        <>
          <Box sx={header}>
            <Box>
              <Box>
                <Text variant="body" sx={{fontSize: '22px'}} superHeavy>
                  {t('Stores.search.searchStore')}
                </Text>
              </Box>
              <Box sx={{marginTop: '8px'}}>
                <Text variant="body" color={Colors.GreyText}>
                  {t('Stores.search.searchForNameOrId')}
                </Text>
              </Box>
            </Box>
            <Box onClick={closeModal}>
              <IconSVG icon="close" size={16} className="close" />
            </Box>
          </Box>
          <Box sx={{width: '100%', marginTop: '32px'}}>
            <SearchInputRestyle
              placeholder={t('Stores.search.search')}
              value={searchString}
              onChange={handleSearch}
              {...(role?.name === RoleName.AREA_MANAGER && {
                selectedBanner,
                selectedCountry,
                setSelectedBanner: (newBanner: string) => {
                  setSelectedBanner(newBanner);
                  setSelectedCountry(undefined);
                },
                setSelectedCountry: (newCountry: string) => {
                  setSelectedCountry(newCountry);
                },
                banners: BannerCountry?.banners || [],
                countries: BannerCountry?.countries || [],
              })}
            />
          </Box>
          <Box sx={contentStoreWrapper}>
            <StoresList
              isLoading={apiIsLoading}
              stores={Stores?.data}
              onStoreSelect={handleStoreSelection}
              activeStoreId={selectedStoreId}
            />
            <Box sx={bottomGradient} />
          </Box>
        </>
      }
      dialogActions={
        <Button
          variant="primary"
          sx={cta}
          disabled={!canSubmit}
          onClick={handleStoreSubmit}>
          {t('Stores.search.button')}
        </Button>
      }
    />
  );
};

export default React.memo(SearchStore);
