import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const lineWithText = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      '&::before, &::after': {
        content: '""',
        flex: 1,
        borderBottom: `1px solid ${theme.palette.primary[200]}`,
        marginBottom: theme.spacing(0.3),
      },
      '.lineText': {
        whiteSpace: 'nowrap',
        padding: theme.spacing(0, 0.8),
      },
    }),
    [theme],
  );

  return useMemo(() => ({lineWithText}), [lineWithText]);
};
