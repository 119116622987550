import React, {useCallback, useMemo, useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';

import {Box, Stack, useTheme, CircularProgress} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useParamsState} from '@/hooks/useParamsState';
import {useScrollPosition} from '@/hooks/useScrollPosition/useScrollPosition';
import {sendGaEvent, GaEventCategory} from '@/lib/utils';
import {ClientResult} from '@/molecules/ClientResult';
import {MyAccountSearchBar} from '@/molecules/MyAccountSearchBar';
import {useAuthentication} from '@/store/authentication';
import {
  ClientProps,
  usePersonas,
  GetPersonasResponse,
  GetPersonasParams,
} from '@/store/personas';
import {Base} from '@/templates/Base';

import {useStyle} from './styles';

const ClientResults = () => {
  const {getScrollPositionById, deleteScrollPositionById} = useScrollPosition();
  const {t} = useTranslation();
  const styles = useStyle();
  const theme = useTheme();
  const {showErrorToast} = useToast();
  const {getPersonas, getBannerCountry} = usePersonas();
  const {
    getAuth,
    userData,
    isStoreAssociate,
    isStoreManager,
    isAreaManager,
    isContactCenterSupervisor,
    isContactCenterAgent,
  } = useAuthentication();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Client results',
    });
  }, []);

  useEffect(() => {
    const scrollPositionById = getScrollPositionById('clientResultsContainer');
    if (typeof scrollPositionById === 'number') {
      const elementById = document.getElementById('clientResultsContainer');
      if (elementById) {
        elementById.scrollTop = scrollPositionById;
      }
      deleteScrollPositionById('clientResultsContainer');
    }
  }, [deleteScrollPositionById, getScrollPositionById]);

  const {paramsState, setParamsState} = useParamsState<{
    q: string;
    onlyInThisStore: string | undefined;
    bannerSearch: string;
    countrySearch: string;
    tab: string;
  }>(null, [], 'q', 'onlyInThisStore', 'bannerSearch', 'countrySearch', 'tab');

  const [localState, setLocalState] = useState<{
    q: string;
    onlyInThisStore: string | undefined;
    bannerSearch: string;
    countrySearch: string;
    tab: string;
  }>({
    ...paramsState,
    bannerSearch: paramsState?.bannerSearch || userData?.banner!,
    countrySearch: paramsState?.countrySearch || userData?.country!,
  });

  const [toggleSearch, setToggleSearch] = useState<boolean>(true);

  const {refetch: refetchUserData} = useQuery({
    queryKey: ['profile'],
    queryFn: () => getAuth(),
    enabled: false,
    retry: false,
  });

  const isSAorSM = useMemo(() => {
    return isStoreAssociate || isStoreManager;
  }, [isStoreAssociate, isStoreManager]);

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  const isAMorCC = useMemo(() => {
    return isAreaManager || isCC;
  }, [isAreaManager, isCC]);

  const personasPayload = useMemo<GetPersonasParams>(() => {
    return {
      q: paramsState.q,
      bannerSearch: paramsState.bannerSearch,
      countrySearch: paramsState.countrySearch,
      ...(!!paramsState.onlyInThisStore && {
        storeId: userData?.currentStore?.storeId,
      }),
      searchType: paramsState.tab,
    };
  }, [
    paramsState.bannerSearch,
    paramsState.countrySearch,
    paramsState.onlyInThisStore,
    paramsState.q,
    userData?.currentStore?.storeId,
    paramsState.tab,
  ]);

  const {data: Personas, isLoading: PersonasLoading} =
    useQuery<GetPersonasResponse>(['customer', personasPayload], {
      queryFn: () => getPersonas(personasPayload),
      retry: false,
      refetchOnWindowFocus: false,
      enabled: toggleSearch,
      onError: () => {
        showErrorToast(t('ClientResults.personasToast.error'));
      },
      onSettled: async () => {
        if (isSAorSM) {
          sendGaEvent(
            GaEventCategory.SEARCH,
            'only_in_this_store',
            !!paramsState.onlyInThisStore
              ? userData?.currentStore?.storeId!
              : 'FALSE',
          );
        }
        setToggleSearch(false);
        await refetchUserData();
      },
    });

  const handleToggleOnlyInThisStore = useCallback(
    (_e: any, checked: boolean) => {
      setLocalState(prevState => ({
        ...prevState,
        onlyInThisStore: checked ? userData?.currentStore?.storeId : undefined,
      }));
    },
    [userData?.currentStore?.storeId],
  );

  const {data: BannerCountry} = useQuery(
    ['getBannerCountry', localState?.bannerSearch],
    () => getBannerCountry(localState?.bannerSearch),
    {
      retry: false,
      enabled: isCC,
      refetchOnWindowFocus: false,
      onSuccess: ({countries}) => {
        if (
          !countries?.find(
            country => country.name === localState?.countrySearch,
          )
        ) {
          setLocalState(prevState => ({
            ...prevState,
            countrySearch: countries[0]?.name || prevState.countrySearch,
          }));
        }
      },
    },
  );

  const searchBarProps = useMemo(
    () => ({
      freeSearch: localState?.q,
      onReset: () => {
        setLocalState(prevState => ({...prevState, q: ''}));
      },
      onFreeSearchChange: (newValue: string) =>
        setLocalState(prevState => ({...prevState, q: newValue})),
      onTabChange: (newValue: string) =>
        setLocalState(prevState => ({
          ...prevState,
          q: paramsState?.tab === newValue ? paramsState?.q : '',
          tab: newValue,
        })),
      onSubmit: () => {
        setParamsState(localState);
        setToggleSearch(true);
      },
      onlyInThisStoreVisible: isSAorSM,
      onlyInThisStoreValue: !!localState?.onlyInThisStore,
      handleToggleOnlyInThisStore,
      showPickers: isAMorCC,
      disablePickers: isAreaManager,
      selectedBanner: localState?.bannerSearch,
      setSelectedBanner: (newBanner: string) =>
        setLocalState(prevState => ({
          ...prevState,
          bannerSearch: newBanner,
        })),
      selectedCountry: localState?.countrySearch,
      setSelectedCountry: (newCountry: string) =>
        setLocalState(prevState => ({
          ...prevState,
          countrySearch: newCountry,
        })),
      loading: PersonasLoading,
      selectedTabParam: localState?.tab,
    }),
    [
      PersonasLoading,
      handleToggleOnlyInThisStore,
      isAMorCC,
      isAreaManager,
      isSAorSM,
      localState,
      paramsState?.q,
      paramsState?.tab,
      setParamsState,
    ],
  );

  //Render Content based on results length and searchBar value
  const renderContent = useMemo(() => {
    if (PersonasLoading) {
      return (
        <Box sx={styles.centerBox}>
          <CircularProgress />
        </Box>
      );
    }

    if (Personas && Personas.data && Personas.data.length > 0) {
      return (
        <Stack sx={styles.cardsWrapper} direction="column">
          <Stack
            sx={styles.cardsContainer}
            direction="column"
            rowGap={theme.spacing(4.8)}>
            {Personas.data.map((person: ClientProps) => {
              return (
                <ClientResult
                  item={person}
                  key={person.individualDetails.email}
                />
              );
            })}
          </Stack>
        </Stack>
      );
    }

    return <></>;
  }, [
    PersonasLoading,
    Personas,
    styles.centerBox,
    styles.cardsWrapper,
    styles.cardsContainer,
    theme,
  ]);

  return (
    <Base>
      <Box>
        <MyAccountSearchBar
          {...searchBarProps}
          personas={Personas}
          banners={
            isCC ? BannerCountry?.banners : [{name: localState?.bannerSearch}]
          }
          countries={
            isCC
              ? BannerCountry?.countries
              : [{name: localState?.countrySearch}]
          }
        />
      </Box>
      {Personas && Personas.data && Personas.data.length > 0 ? (
        <Box>
          <Text variant="headline" superHeavy>
            {t('MyAccount.Search.resultsSize', {
              size: Personas.size,
            })}
            {' - '}
            <Text variant="headline" medium>
              {t('MyAccount.Search.resultsTabSearch', {
                tab: t(`MyAccount.Search.Tabs.${paramsState?.tab}`),
                searchQuery: paramsState?.q,
                interpolation: {escapeValue: false},
              })}
            </Text>
          </Text>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={styles.content} id={'clientResultsContainer'}>
        {renderContent}
      </Box>
    </Base>
  );
};

export default React.memo(ClientResults);
