import {faker} from '@faker-js/faker';

import {PURCHASE_CATEGORIES} from '@/atoms/PurchaseCategory/interfaces.d';
import {TransactionDataProps} from '@/organisms/TransactionCard/interfaces.d';
import {
  LastPurchasesInformation,
  GetLastPurchasesResponse,
} from '@/store/tasks/types';

const generateMockLastPurchaseInformation = (): LastPurchasesInformation => {
  return {
    customerSegment: faker.helpers.arrayElement([
      'EXPLORER',
      'TOP',
      'NOT_ELIGIBLE',
      'LOST',
      'LAPSED',
      'LOW_VALUE_MTP',
      'HIGH_VALUE_MTP',
      'STANDARD_MTP',
      'STANDARD_VALUE_MTP',
      'STANDARD_OTP',
      'HIGH_VALUE_OTP',
      'UNKNOWN',
    ]),
    lifeTimeValue: faker.commerce.price(),
    purchaseCategory: faker.helpers.arrayElements(
      [
        PURCHASE_CATEGORIES.LENSES,
        PURCHASE_CATEGORIES.OTHER,
        PURCHASE_CATEGORIES.FRAMES,
        PURCHASE_CATEGORIES.APPAREL,
        PURCHASE_CATEGORIES.CONTACTS,
        PURCHASE_CATEGORIES.SUNGLASSES,
        PURCHASE_CATEGORIES.OPTICAL_SERVICE,
        PURCHASE_CATEGORIES.STORE_SERVICES,
        PURCHASE_CATEGORIES.FOOTWEAR,
        PURCHASE_CATEGORIES.ACCESSORIES,
        PURCHASE_CATEGORIES.CENTRAL_PURCHASING,
        PURCHASE_CATEGORIES.RETAIL_ACCESSORIES,
        PURCHASE_CATEGORIES.MANUFACTURING_SERVICE,
        PURCHASE_CATEGORIES.READERS,
        PURCHASE_CATEGORIES.SUN,
        PURCHASE_CATEGORIES.CONTRACTS,
        PURCHASE_CATEGORIES.ONESIGHT,
        PURCHASE_CATEGORIES.MISC,
        PURCHASE_CATEGORIES.WELLNESS_CATEGORY,
        PURCHASE_CATEGORIES.COMPLETE_PAIR_OPTICAL,
      ],
      {min: 1, max: 5},
    ),
    lastPurchase: faker.date.past().toISOString(),
  };
};

const generateMockTransactions = (): Array<TransactionDataProps> => {
  return [
    {
      name: 'Micheal Accessorio Occhiali Ferronato',
      customerId: '000800051858802710000',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        storeName: '248 CR-C.C. CREMONA PO - CREMONA',
        postalCode: '26100',
        phoneNumber: '0372/461775',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-05-06T15:59:07',
      transactionId: '0008000510067400100',
      orderId: '0008000516012361910147',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 0.01,
      totalDiscountValue: 0,
      totalNetValue: 0.01,
      totalTaxValue: 0,
      totalTenderValue: 0.01,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'DEFAULT',
          specifyProductKind: 'ACCESSORIES',
          quantity: '1',
          productType: 'Accessories',
          description: 'LANYARD RB VIBES RED',
          styleName: 'ARB0002ST Ray-Ban Lanyard',
          brand: 'Ray-Ban',
          model: 'ARB0002ST',
          moco: 'ARB0002ST__000001',
          upc: '8056597950268',
          productHierarchy1: 'Eyewear Accessories',
          productHierarchy0: 'Accessories',
          productHierarchy2: 'Spare Parts',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/1cb53f65-9486-4dc0-b723-afcf01664ae1/5bd8e78e-2b05-4f0d-9518-afcf01664ba8/ARB0002ST__000001.png?imwidth=231',
        },
      ],
    },
    {
      name: 'Michael Ferronato',
      customerId: '000816000216610351',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Termoli',
        stateProvince: 'CB',
        address: 'Corso Nazionale 44',
        storeName: '796 Cb-Termoli C.So Nazionale',
        postalCode: '86039',
        phoneNumber: '0875/880804',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-03-25T17:11:31',
      transactionId: '000810007840100',
      orderId: '000816000218410351',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 230.77,
      totalDiscountValue: 115.38462,
      totalNetValue: 115.38,
      totalTaxValue: 4.62,
      totalTenderValue: 120,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'LENS_PACKAGES',
          specifyProductKind: 'LENSES',
          quantity: '1',
          productType: 'Rox_lens',
          description: 'GA STANDARD 1.50 CL SV SPH PRM AR BLUE',
          styleName: 'GA STANDARD 1.50 CL SV SPH PRM AR BLUE',
          model: 'Lenses',
          upc: '20500001817958',
          lensColorLabel: 'Blue Filter',
          lensMaterial: 'Plastic 1.50 Clear',
          lensType: 'Optical',
        },
      ],
    },
    {
      name: 'Michael Accessorio CL Ferranato',
      customerId: '0008000516003206510070',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Genova',
        stateProvince: 'GE',
        address: 'Via Xx Settembre 102/R',
        storeName: '130 GE-GENOVA VIA XX SETTEMBRE',
        postalCode: '16121',
        phoneNumber: '010/541944',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-02-04T17:35:41',
      transactionId: '0008000530041450602',
      orderId: '0008000516009344610070',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 10.66,
      totalDiscountValue: 0,
      totalNetValue: 10.66,
      totalTaxValue: 2.34,
      totalTenderValue: 13,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'DEFAULT',
          specifyProductKind: 'LENSES',
          quantity: '1',
          productType: 'Contact Lenses Accessories',
          description: 'AOSEPT PLUS C/HYDRAGLYDE 360ML',
          styleName: 'Aosept Plus Con Hydraglyde',
          upc: '300650363075',
        },
      ],
    },
    {
      name: 'Giorgia Rausa',
      customerId: '00081834418600000',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Torino',
        stateProvince: 'TO',
        address: 'Via Roma 33',
        storeName: '135 TO-TORINO VIA ROMA',
        postalCode: '10123',
        phoneNumber: '011/5629062',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-02-04T16:27:27',
      transactionId: '000810055190200',
      orderId: '000816012413010075',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 21.15,
      totalDiscountValue: 5.2884617,
      totalNetValue: 15.87,
      totalTaxValue: 0.63,
      totalTenderValue: 16.5,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'LENS_PACKAGES',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Contact Lenses',
          description: 'FODACP30P 087 140 VISI',
          styleName: 'DAILIES® AquaComfort Plus® 30',
          brand: 'DAILIES',
          upc: '630175475862',
          baseCurve: '8.7',
          diameter: 14,
          packageQuantity: '30',
          modalityFacet: 'Daily',
          geometry: 'SPHERIC',
          materialSeg: 'SOFT',
          supplier: 'Alcon',
          brandFamily: 'DAILIES',
        },
      ],
    },
    {
      name: 'Michael Cleaning Kit Ferronato',
      customerId: '000816005319710132',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Milano',
        stateProvince: 'MI',
        address: 'Via Marco Ulpio Traiano 79',
        storeName: '230 MI-C.C. PORTELLO - MILANO',
        postalCode: '20155',
        phoneNumber: '02/39257194',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-02-04T15:59:02',
      transactionId: '000830028180100',
      orderId: '000816005327010132',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 623.94,
      totalDiscountValue: 414.21033,
      totalNetValue: 209.52,
      totalTaxValue: 9.570001,
      totalTenderValue: 217.9,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'FRAME',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Optical',
          description: '15368 ACTIVE/VIOLA/FUCSIA/42',
          styleName: '15368N',
          brand: 'Centrostyle',
          model: '0SY000022',
          moco: '0SY000022__3820',
          upc: '8058692043333',
          gender: 'Kids',
          productHierarchy1: 'Eyeglasses',
          productHierarchy0: 'Eyewear',
          frameShapeLabel: 'Oval',
          frameType: 'Full Rim',
          frameMaterialLabel: 'Injected',
          sizeOrdinal: 'XXS',
          size: '42',
          bridgeSize: '14',
          modelCodeDisplay: 'SY000022',
          frameMaterial: 'Nylon & Propionate',
          modelStylename: '15368N',
          frameColorLabel: 'Purple Pink',
          faceShape: 'Oval-Square',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/1cb53f65-9486-4dc0-b723-afcf01664ae1/5bd8e78e-2b05-4f0d-9518-afcf01664ba8/ARB0002ST__000001.png?imwidth=231',
        },
        {
          productKind: 'LENS_PACKAGES',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Rox_lens',
          description: 'GA TOP 1.67 GEN8 AMBER SV PREMIUM AR',
          styleName: 'GA TOP 1.67 GEN8 AMBER SV PREMIUM AR',
          model: 'Lenses',
          upc: '20500001811987',
          lensColorLabel: 'Amber',
          lensMaterial: 'Plastic 1.67 GEN8',
          lensType: 'Optical',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/f2b96cd0-f2cc-49c7-b155-ac4100813925/923863df-fb14-41f2-ae14-ac4100813994/ASV0004CK__000001.png?imwidth=231',
        },
        {
          productKind: 'DEFAULT',
          specifyProductKind: 'SUN',
          description: 'MOUNTING',
          styleName: 'MOUNTING',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/f4b6e220-05fc-4cba-917b-a599007aa5ab/61d68ba5-ed5d-4847-801d-ad790161a5ab/0OO9188__918858__P21__noshad__qt.png?imwidth=231',
        },
        {
          productKind: 'DEFAULT',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Accessories',
          description: 'ANTIMICROB LENS CLEAN 30ML SPR',
          styleName: 'ASV0004CK Antimicrobial Lens Cleaning 30ml',
          brand: 'Salmoiraghi E Vigano',
          model: 'ASV0004CK',
          moco: 'ASV0004CK__000001',
          upc: '8056597393089',
          gender: 'Unisex',
          productHierarchy1: 'Eyewear Accessories',
          productHierarchy0: 'Accessories',
          productHierarchy2: 'Cleaning Kits & Cases',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/f2b96cd0-f2cc-49c7-b155-ac4100813925/923863df-fb14-41f2-ae14-ac4100813994/ASV0004CK__000001.png?imwidth=231',
        },
      ],
    },
    {
      name: null,
      customerId: '00081014349371930',
      customerPhone: '0267108588',
      customerEmail: 'nomail@luxottica.com',
      isBossOrder: true,
      store: {
        banner: 'SV',
        city: 'Milano',
        stateProvince: 'MI',
        address: 'Corso Matteotti 22',
        storeName: '113 MI-MILANO PIAZZA SAN BABILA',
        postalCode: '20122',
        phoneNumber: '02/76000100',
      },
      shippingAddress: {
        city: 'Cremona',
        stateProvince: 'CR',
        address: 'Via Castelleone 108',
        postalCode: '26100',
      },
      transactionDate: '2023-02-04T12:45:09',
      transactionId: '0008330023500100',
      orderId: '00081019366011930',
      channel: 'STORE',
      prescriptionChannel: 'RETAIL',
      orderStatus: 'DELIVERED',
      trackerOrderUrl: null,
      totalGrossValue: 509,
      totalDiscountValue: 125.3,
      totalNetValue: 383.7,
      totalTaxValue: 2.9,
      totalTenderValue: 386.6,
      discounts: [],
      prescription: null,
      products: [
        {
          productKind: 'FRAME',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Sun',
          description: 'OO9188 59 FLAK 2.0 XL',
          styleName: 'OO9188 Flak® 2.0 XL',
          brand: 'Oakley',
          model: '0OO9188',
          moco: '0OO9188__918858',
          upc: '888392225245',
          gender: 'Man',
          productHierarchy1: 'Sunglasses',
          productHierarchy0: 'Eyewear',
          frameShapeLabel: 'Rectangle',
          frameType: 'Semi Rim',
          frameFitting: 'Narrow',
          lensTreatmentLabel: 'Prizm_polarized',
          frameMaterialLabel: 'O_matter',
          lensColorLabel: 'Prizm Deep Water Polarized',
          sizeOrdinal: 'S',
          size: '59',
          bridgeSize: '12',
          modelCodeDisplay: 'OO9188',
          frameMaterial: 'Nylon & Propionate',
          modelStylename: 'FLAK 2.0 XL',
          frameColorLabel: 'Matte Black',
          faceShape: 'Oval-Round',
          image:
            'https://webapp-im2.luxottica.com//cdn-record-files-pi/f4b6e220-05fc-4cba-917b-a599007aa5ab/61d68ba5-ed5d-4847-801d-ad790161a5ab/0OO9188__918858__P21__noshad__qt.png?imwidth=231',
        },
        {
          productKind: 'LENS_PACKAGES',
          specifyProductKind: 'SUN',
          quantity: '1',
          productType: 'Rox_lens',
          description: 'SV OAKLEYTD II 1.59 CLEAR OS',
          styleName: 'Oakley Clear Classic',
          brand: 'ESSENTIALS',
          model: 'Lenses',
          upc: '20500001780931',
          lensMaterial: 'High-Index',
          lensType: 'Optical',
        },
        {
          productKind: 'DEFAULT',
          specifyProductKind: 'SUN',
          description: 'ONE YEAR EYEWEAR PROTECTION PLAN',
          styleName: 'ONE YEAR EYEWEAR PROTECTION PLAN',
        },
      ],
    },
  ];
};

const generateMockLastPurchasesResponse = (): GetLastPurchasesResponse => {
  return {
    purchaseInformation: generateMockLastPurchaseInformation(),
    transactions: generateMockTransactions(),
  };
};

export const lastPurchasesResponseMock = generateMockLastPurchasesResponse();
