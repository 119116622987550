import React, {useState, useCallback, useMemo, useRef, useEffect} from 'react';

import {Stack, Button, useMediaQuery, useTheme} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {HeadlineText} from '@/atoms/HeadlineText';
import {Modal} from '@/atoms/Modal';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {AppointmentHistoryCard} from '@/organisms/AppointmentHistory/AppointmentHistory';
import {AppointmentV2} from '@/store/appointments/v2';

interface UseLinkToTaskModalProps {
  disassociateAndAssociateTaskAppointment: (appointment: AppointmentV2) => void;
}

const TRANSLATION_KEY = 'LinkToTaskModal';

export const useLinkToTaskModal = ({
  disassociateAndAssociateTaskAppointment,
}: UseLinkToTaskModalProps) => {
  const {getTranslationWithValue} = useBaseTranslation(TRANSLATION_KEY);
  const [currentAppointment, setCurrentAppointment] = useState<
    AppointmentV2 | undefined
  >(undefined);
  const [newAppointment, setNewAppointment] = useState<
    AppointmentV2 | undefined
  >(undefined);

  const toggleModal = useCallback(
    (newApp?: AppointmentV2, currApp?: AppointmentV2) => {
      setNewAppointment(newApp);
      setCurrentAppointment(currApp);
    },
    [],
  );

  const newAppAlreadyLinked = useMemo(() => {
    return (
      !!newAppointment &&
      !!newAppointment?.assigneeFirstName &&
      !!newAppointment?.assigneeLastName &&
      !!newAppointment?.taskCampaignName
    );
  }, [newAppointment]);

  const modalTitle = useMemo(() => {
    if (newAppAlreadyLinked) {
      return getTranslationWithValue(0, 'alreadyLinked');
    }

    return getTranslationWithValue(0, 'confirmLinkTo');
  }, [getTranslationWithValue, newAppAlreadyLinked]);

  const linkToTaskModal = useMemo(() => {
    if (!newAppointment) return null;

    return (
      <Modal
        open={!!newAppointment}
        onClose={() => toggleModal()}
        maxWidth={60}
        dialogTitle={<HeadlineText heavy>{modalTitle}</HeadlineText>}
        dialogContent={
          <LinkToTaskModalContent
            newAppointment={newAppointment}
            currentAppointment={currentAppointment}
            toggleModal={toggleModal}
            disassociateAndAssociateTaskAppointment={
              disassociateAndAssociateTaskAppointment
            }
            newAppAlreadyLinked={newAppAlreadyLinked}
          />
        }
      />
    );
  }, [
    currentAppointment,
    disassociateAndAssociateTaskAppointment,
    modalTitle,
    newAppAlreadyLinked,
    newAppointment,
    toggleModal,
  ]);

  return {
    toggleModal,
    linkToTaskModal,
  };
};

interface LinkToTaskModalContentProps {
  newAppointment: AppointmentV2;
  currentAppointment?: AppointmentV2;
  toggleModal: (newApp?: AppointmentV2, currApp?: AppointmentV2) => void;
  disassociateAndAssociateTaskAppointment: (appointment: AppointmentV2) => void;
  newAppAlreadyLinked: boolean;
}

const LinkToTaskModalContent = React.memo(
  ({
    newAppointment,
    currentAppointment,
    toggleModal,
    disassociateAndAssociateTaskAppointment,
    newAppAlreadyLinked,
  }: LinkToTaskModalContentProps) => {
    const {getTranslationWithValue} = useBaseTranslation(TRANSLATION_KEY);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const appointmentsContainerRef = useRef<HTMLDivElement>(null);

    const calculateHeight = useCallback((numElements: number) => {
      const container = appointmentsContainerRef.current;
      if (!container) return 0;

      let totalHeight = 0;
      for (let i = 0; i < numElements; i++) {
        if (container.children[i]) {
          totalHeight += container.children[i].clientHeight;
        }
      }

      return totalHeight;
    }, []);

    useEffect(() => {
      if (isDesktop && appointmentsContainerRef.current) {
        const numOfElements = 1;
        const addBorders = numOfElements * 2 + 8 * (numOfElements - 1);
        appointmentsContainerRef.current.style.height = `${
          (calculateHeight(numOfElements) + addBorders) * 1.5
        }px`;
      } else if (!isDesktop && appointmentsContainerRef.current) {
        appointmentsContainerRef.current.style.height = `100%`;
      }
    }, [calculateHeight, isDesktop]);

    const modalType = useMemo<'SUBSTITUTE' | 'INFO'>(() => {
      if (newAppAlreadyLinked) {
        return 'INFO';
      }

      return 'SUBSTITUTE';
    }, [newAppAlreadyLinked]);

    return (
      <Stack
        direction={'column'}
        gap={2.4}
        paddingTop={2.4}
        {...(modalType === 'INFO' && {
          alignItems: 'center',
          justifyContent: 'center',
        })}>
        {modalType === 'INFO' && (
          <>
            <BodyText textAlign={'center'}>
              {getTranslationWithValue(0, 'appointmentAlreadyLinked', {
                firstName: newAppointment?.assigneeFirstName ?? '-',
                lastName: newAppointment?.assigneeLastName ?? '-',
                taskCampaignName: newAppointment?.taskCampaignName ?? '-',
              })}
            </BodyText>
            <Button variant={'basePrimary'} onClick={() => toggleModal()}>
              {getTranslationWithValue(0, 'ok')}
            </Button>
          </>
        )}
        {modalType === 'SUBSTITUTE' && (
          <>
            <BodyText>
              {getTranslationWithValue(0, 'thisTaskAlreadyLinked')}
            </BodyText>
            <Stack
              gap={2.4}
              direction={'column'}
              ref={appointmentsContainerRef}
              sx={{
                overflowY: 'auto',
                scrollbarWidth: 'none',
              }}>
              <Stack direction={'column'} gap={0.8}>
                <Headline5 heavy>
                  {getTranslationWithValue(0, 'appointmentToDisassociate')}
                </Headline5>
                <AppointmentHistoryCard
                  taskIsEditable={false}
                  cardType={'NEXT'}
                  assignType={'DELETE'}
                  readOnly
                  {...currentAppointment!}
                />
              </Stack>
              <Stack direction={'column'} gap={0.8}>
                <Headline5 heavy>
                  {getTranslationWithValue(0, 'appointmentToLink')}
                </Headline5>
                <AppointmentHistoryCard
                  taskIsEditable={false}
                  cardType={'NEXT'}
                  assignType={'ASSIGN'}
                  readOnly
                  {...newAppointment!}
                />
              </Stack>
            </Stack>
            <BodyText>{getTranslationWithValue(0, 'doYouConfirm')}</BodyText>
            <Stack
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              alignItems={'center'}
              gap={0.8}>
              <Button variant={'baseSecondary'} onClick={() => toggleModal()}>
                {getTranslationWithValue(0, 'noKeep')}
              </Button>
              <Button
                variant={'basePrimary'}
                onClick={() => {
                  disassociateAndAssociateTaskAppointment(newAppointment);
                  toggleModal();
                }}>
                {getTranslationWithValue(0, 'yesChange')}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    );
  },
);

LinkToTaskModalContent.displayName = 'LinkToTaskModalContent';
