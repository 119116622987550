import dayjs from 'dayjs';

import {
  ReportDetailFilters,
  ReportDetailsFiltersAction,
  ReportDetailsFiltersActions,
  ReportDetailFiltersReducer,
} from '@/store/reportDetailsFilters/definitions';

export const reportDetailsFiltersInitialStartDate = dayjs()
  .utc()
  .startOf('month');
export const reportDetailsFiltersInitialEndDate = dayjs().utc();

export const getInitialCampaignState = (): ReportDetailFilters => ({
  assignees: [],
  startDate: reportDetailsFiltersInitialStartDate,
  endDate: reportDetailsFiltersInitialEndDate,
  orderBy: 'ASC',
  sortBy: null,
  page: 1,
});

export const reportDetailFiltersReducer = (
  state: ReportDetailFiltersReducer = {},
  action: ReportDetailsFiltersAction,
) => {
  switch (action.type) {
    case ReportDetailsFiltersActions.changeField:
      const {campaignId, data} = action?.payload;
      return {
        ...state,
        [campaignId]: {
          ...state![campaignId],
          [data?.key]: data?.value,
          page: 1,
        },
      };
    case ReportDetailsFiltersActions.changeDates:
      return {
        ...state,
        [action?.payload?.campaignId]: {
          ...state![action?.payload?.campaignId],
          startDate: action?.payload?.data?.startDate,
          endDate: action?.payload?.data?.endDate,
          page: 1,
        },
      };
    case ReportDetailsFiltersActions.changePage:
      return {
        ...state,
        [action?.payload?.campaignId]: {
          ...state![action?.payload?.campaignId],
          page: action?.payload?.data,
        },
      };
    case ReportDetailsFiltersActions.initializeFilters:
      return {
        ...state,
        [action?.payload?.campaignId]: {
          ...getInitialCampaignState(),
        },
      };
    case ReportDetailsFiltersActions.resetFilters:
      return {};
    default:
      return state;
  }
};
