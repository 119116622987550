import {Theme} from '@mui/material';
import {createTheme} from '@mui/material/styles';

import {Colors, FontSizes} from './variables';

// TODO: add font family
const fontFamily =
  "'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";

const theme = createTheme({
  /**
   * Spacing multiplier *
   * Usage:
   * marginRight: theme.spacing(2) // '20px'
   * or
   * marginRight: 2 // '20px'
   * (to be used on layout properties - margins, paddings, gaps, ... -)
   */
  spacing: 10,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 926,
      lg: 1240,
      xl: 1440,
    },
  },

  typography: {
    fontFamily: fontFamily,
    xxs: {
      fontSize: FontSizes.XXS,
    },
    xs: {
      fontSize: FontSizes.XS,
    },
    tiny: {
      fontSize: FontSizes.Tiny,
    },
    body: {
      fontSize: FontSizes.Body,
    },
    headline5: {
      fontSize: FontSizes.Headline5,
    },
    headline4: {
      fontSize: FontSizes.Headline4,
    },
    headline3: {
      fontSize: FontSizes.Headline3,
    },
    headline2: {
      fontSize: FontSizes.Headline2,
    },
    headline1: {
      fontSize: FontSizes.Headline1,
    },
    headline: {
      fontSize: FontSizes.Headline,
    },
    titleBig: {
      fontSize: FontSizes.TitleBig,
    },
  },

  palette: {
    dialog: {
      main: Colors.Backdrop,
    },
    common: {
      white: Colors.White,
      black: Colors.Black,
    },
    badge: {
      main: '',
      newCollection: Colors.NEWCOLLECTION,
      callback: Colors.CALLBACK,
      birthday: Colors.BIRTHDAY,
      quote: Colors.QUOTE,
    },
    primary: {
      main: Colors.Primary,
      200: Colors.Primary200,
      300: Colors.Primary300,
      700: Colors.Primary700,
      disabled: Colors.PrimaryDisabled,
    },
    secondary: {
      main: Colors.Secondary,
    },
    tertiary: {
      main: Colors.Tertiary,
      light: Colors.TertiaryLight,
    },
    tertiaryDark: {
      main: Colors.TertiaryDark,
    },
    black: {
      main: Colors.Black,
      opacity70: Colors.BlackOpacity70,
    },
    grey: {
      900: Colors.Grey900,
      800: Colors.Grey800,
      700: Colors.Grey700,
      600: Colors.Grey600,
      500: Colors.Grey500,
      400: Colors.Grey400,
      300: Colors.Grey300,
      200: Colors.Grey200,
      100: Colors.Grey100,
    },
    green: {
      main: Colors.Green,
    },
    error: {
      main: Colors.Error,
      light: Colors.ErrorLight,
    },
    success: {
      main: Colors.Success,
      light: Colors.SuccessLight,
    },
    warning: {
      main: Colors.Warning,
      yellow: Colors.WarningYellow,
      dark: Colors.WarningDark,
      light: Colors.WarningLight,
    },
    info: {
      main: Colors.Blue,
      light: Colors.LightBlue,
    },
    tag: {
      main: '',
      red: Colors.TagRed,
      green: Colors.TagGreen,
    },
    boxShadow: {
      main: Colors.BoxShadow,
    },
  },
});

const getButtonCommonStyles = (theme: Theme) => ({
  borderRadius: '5px',
  fontSize: theme.typography.body.fontSize,
  lineHeight: '18px',
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'none',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: '44px',
  maxHeight: '44px',
});

const getChipCommonStyles = (theme: Theme) => ({
  // textTransform: 'capitalize',
  borderRadius: '100px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.common.black,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.6)}`,
  '& .MuiChip-deleteIcon': {
    margin: theme.spacing(0),
    lineHeight: theme.spacing(1.4),
    color: theme.palette.common.black,
    fill: theme.palette.common.black,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '& .MuiChip-label': {
    padding: '2px 0 0',
  },
});

// custom mui components style
const extendedTheme = createTheme(theme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.primary[700],
          color: theme.palette.common.black,
          border: `0.5px solid ${theme.palette.grey[700]}`,
          width: 136,
          padding: 8,
          textAlign: 'center',
          borderRadius: 6,
          fontSize: theme.typography.xxs,
          fontWeight: theme.typography.fontWeightLight,
        },
        tooltipPlacementTop: {
          '&.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop': {
            marginBottom: theme.spacing(1),
          },
        },
        arrow: {
          color: theme.palette.grey[700],
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: {variant: 'tabDefault'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: '#FFFFFF00',
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: {variant: 'tabActive'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: '800',
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              fontWeight: '800',
            },
          },
        },
        {
          props: {variant: 'firstLevelTabDefault'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.white,
            borderWidth: 0,
            borderRadius: 0,
            borderBottomWidth: theme.spacing(0.3),
            borderColor: 'transparent',
            padding: `${theme.spacing(0.8, 0, 0.4, 0)}`,
            fontSize: theme.typography.headline5.fontSize,
            ':hover': {
              backgroundColor: theme.palette.common.white,
              borderBottomWidth: theme.spacing(0.3),
              borderStyle: 'solid',
              borderColor: theme.palette.common.black,
            },
          },
        },
        {
          props: {variant: 'firstLevelTabActive'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.white,
            borderWidth: 0,
            borderRadius: 0,
            borderBottomWidth: theme.spacing(0.3),
            borderStyle: 'solid',
            borderColor: theme.palette.common.black,
            padding: `${theme.spacing(0.8, 0, 0.4, 0)}`,
            fontSize: theme.typography.headline5.fontSize,
            fontWeight: '800',
            ':hover': {
              backgroundColor: theme.palette.common.white,
            },
          },
        },
        {
          props: {variant: 'secondLevelTabDefault'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: '#FFFFFF00',
            borderRadius: '6px',
            borderWidth: '0',
            padding: `${theme.spacing(0.8)}`,
            fontSize: theme.typography.body.fontSize,
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: {variant: 'secondLevelTabActive'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            borderRadius: '6px',
            borderWidth: '0',
            padding: `${theme.spacing(0.8)}`,
            fontSize: theme.typography.body.fontSize,
            fontWeight: '800',
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: {variant: 'filterDefault'},
          style: {
            ...getChipCommonStyles(theme),
            fontSize: theme.typography.tiny.fontSize,
            height: 'auto',
            lineHeight: theme.spacing(1.4),
            backgroundColor: '#FFFFFF00',
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
            '.MuiChip-deleteIcon': {
              color: theme.palette.common.black,
              fill: theme.palette.common.black,
            },
            /* Apply hover effect only if viewport is desktop - issue on tablet touch */
            ':hover': {
              backgroundColor: '#FFFFFF00',
              color: theme.palette.common.black,
              '.MuiChip-deleteIcon': {
                color: theme.palette.common.black,
                fill: theme.palette.common.black,
              },
            },
            [theme.breakpoints.up('lg')]: {
              ':hover': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                '.MuiChip-deleteIcon': {
                  color: theme.palette.common.white,
                  fill: theme.palette.common.white,
                },
              },
            },
            /* end fix */
          },
        },
        {
          props: {variant: 'filterActive'},
          style: {
            ...getChipCommonStyles(theme),
            fontSize: theme.typography.tiny.fontSize,
            height: 'auto',
            lineHeight: theme.spacing(1.4),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            '.MuiChip-deleteIcon': {
              color: theme.palette.common.white,
            },
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              '.MuiChip-deleteIcon': {
                color: theme.palette.common.white,
              },
            },
          },
        },
        {
          props: {variant: 'filterDefaultTable'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: 'rgba(187, 187, 187, 0.25)',
            color: 'rgba(47, 47, 48, 0.35)',
            fontWeight: '800',
            border: 'none',
            /* Apply hover effect only if viewport is desktop - issue on tablet touch */
            ':hover': {
              backgroundColor: '#FFFFFF00',
              color: theme.palette.common.black,
            },
            [theme.breakpoints.up('lg')]: {
              ':hover': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
              },
            },
            /* end fix */
          },
        },
        {
          props: {variant: 'filterDefaultTableActive'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: '800',
            border: 'none',
            /* Apply hover effect only if viewport is desktop - issue on tablet touch */
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
            [theme.breakpoints.up('lg')]: {
              ':hover': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
              },
            },
            /* end fix */
          },
        },
        {
          props: {variant: 'filterCustomer'},
          style: {
            ...getChipCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightMedium,
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              fontWeight: theme.typography.fontWeightMedium,
            },
            '.MuiChip-deleteIcon': {
              margin: theme.spacing(0, 0, 0, 1.0),
            },
          },
        },
        {
          props: {variant: 'customer'},
          style: {
            ...getChipCommonStyles(theme),
            borderRadius: theme.spacing(0.6),
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.tiny.fontSize,
            padding: theme.spacing(0.8),
            '.MuiChip-deleteIcon': {
              color: theme.palette.common.black,
              fill: theme.palette.common.black,
              margin: theme.spacing(0, 0, 0, 0.8),
            },
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              '.MuiChip-deleteIcon': {
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
              },
            },
          },
        },
        {
          props: {variant: 'customerActive'},
          style: {
            ...getChipCommonStyles(theme),
            borderRadius: theme.spacing(0.6),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.tiny.fontSize,
            padding: theme.spacing(0.8),
            '.MuiChip-deleteIcon': {
              color: theme.palette.common.white,
              fill: theme.palette.common.white,
              margin: theme.spacing(0, 0, 0, 0.8),
            },
            ':hover': {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              '.MuiChip-deleteIcon': {
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
              },
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'primary'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            ':hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
            },
            ':disabled': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[700],
            },
          },
        },
        {
          props: {variant: 'secondary'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.common.black}`,
            color: theme.palette.common.black,
            ':hover': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
            },
            ':disabled': {
              color: theme.palette.grey[700],
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.grey[700],
            },
          },
        },
        {
          props: {variant: 'tertiary'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: 'transparent',
            padding: '0px',
            height: '20px',
            color: theme.palette.common.black,
            ':after': {
              content: "''",
              height: '2px',
              width: '100%',
              backgroundColor: theme.palette.common.black,
              position: 'absolute',
              bottom: '-2px',
            },
            ':hover': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent',
              ':after': {
                content: "''",
                backgroundColor: theme.palette.primary.main,
              },
            },
            ':disabled': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.grey[700],
              ':after': {
                content: "''",
                backgroundColor: theme.palette.grey[700],
              },
            },
          },
        },
        {
          props: {variant: 'cta'},
          style: {
            textTransform: 'initial',
            backgroundColor: 'transparent',
            color: theme.palette.common.black,

            '.MuiTypography-root': {
              textDecoration: 'underline',
            },

            svg: {
              color: theme.palette.primary[300],
            },
            ':hover': {
              color: theme.palette.common.black,
              backgroundColor: 'transparent',
            },
            ':disabled': {
              color: theme.palette.primary[300],
              backgroundColor: 'transparent',
              borderBottom: `1px solid ${theme.palette.primary[300]}`,

              '.MuiButton-endIcon path': {
                fill: theme.palette.primary[300],
              },
            },
          },
        },
        {
          props: {variant: 'basePrimary'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: theme.palette.common.black,
            border: `2px solid ${theme.palette.common.black}`,
            color: theme.palette.common.white,
            textTransform: 'none',
            svg: {
              fill: theme.palette.common.white,
              marginBottom: theme.spacing(0.2),
            },
            ':hover': {
              color: theme.palette.common.white,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.main,
              svg: {
                fill: theme.palette.common.white,
              },
            },
            ':disabled': {
              color: theme.palette.common.white,
              borderColor: theme.palette.grey[700],
              backgroundColor: theme.palette.grey[700],
              svg: {
                fill: theme.palette.common.white,
              },
            },
            height: 'auto',
            padding: theme.spacing(0.6),
            maxHeight: 'auto',
            fontSize: theme.typography.tiny.fontSize,
            lineHeight: 1.2,
          },
        },
        {
          props: {variant: 'baseSecondary'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.common.black}`,
            color: theme.palette.common.black,
            textTransform: 'none',
            svg: {
              marginBottom: theme.spacing(0.2),
            },
            ':hover': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
              svg: {
                fill: theme.palette.primary.main,
              },
            },
            ':disabled': {
              color: theme.palette.grey[700],
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.grey[700],
              svg: {
                fill: theme.palette.grey[700],
              },
            },
            height: 'auto',
            padding: theme.spacing(0.6),
            maxHeight: 'auto',
            fontSize: theme.typography.tiny.fontSize,
            lineHeight: 1.2,
          },
        },
        {
          props: {variant: 'outlined'},
          style: {
            borderRadius: theme.spacing(0.6),
            fontSize: theme.typography.tiny.fontSize,
            lineHeight: '120%',
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            padding: `${theme.spacing(0.8)} ${theme.spacing(1.6)}`,
            backgroundColor: theme.palette.common.black,
            border: `${theme.spacing(0.1)} solid ${theme.palette.common.white}`,
            color: theme.palette.common.white,
            svg: {
              fill: theme.palette.common.white,
            },
            alignItems: 'flex-start',
            gap: theme.spacing(0.4),
            '.MuiButton-endIcon': {
              margin: 0,
            },
            ':hover': {
              color: theme.palette.common.black,
              borderColor: theme.palette.common.black,
              backgroundColor: theme.palette.common.white,
              svg: {
                fill: theme.palette.common.black,
              },
            },
          },
        },
        {
          props: {variant: 'outlinedInverted'},
          style: {
            ...getButtonCommonStyles(theme),
            backgroundColor: 'transparent',
            border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
            color: theme.palette.common.black,
            textTransform: 'none',
            svg: {
              marginBottom: theme.spacing(0.2),
              fill: theme.palette.common.black,
            },
            ':hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.common.black,
              svg: {
                fill: theme.palette.common.white,
              },
            },
            ':disabled': {
              color: theme.palette.grey[700],
              backgroundColor: 'transparent',
              borderColor: theme.palette.grey[700],
              svg: {
                fill: theme.palette.grey[700],
              },
            },
            height: 'auto',
            padding: theme.spacing(0.6, 1.6),
            maxHeight: 'auto',
            fontSize: theme.typography.tiny.fontSize,
            lineHeight: 1.2,
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: 8,
            borderColor: theme.palette.grey[700],
          },

          '&.Mui-focused': {
            fieldset: {
              border: `1px solid ${theme.palette.grey[700]} !important`,
            },
          },

          '& ::placeholder': {
            color: theme.palette.grey[700],
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,

          '&.Mui-focused': {
            color: theme.palette.common.black,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'right',
          color: theme.palette.grey[700],
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.primary[200]}`,
          borderRadius: theme.spacing(0.8),
          padding: `0px ${theme.spacing(2.4)}`,
          '.MuiPickersLayout-actionBar': {
            paddingBottom: '20px',
          },
          '.MuiPickersLayout-actionBar .MuiButton-root': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            borderRadius: '5px',
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: '700',
            textTransform: 'capitalize',
            padding: '10px',
          },
          '.MuiPickersLayout-actionBar .MuiButton-root:hover': {
            color: theme.palette.common.white,
            backgroundColor: '#005291',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: `${theme.spacing(2.4)} 0px`,
          '.MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize',
            fontSize: theme.typography.headline3.fontSize,
          },
        },
        switchViewButton: {
          display: 'none',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          justifyContent: 'space-between',
          '.MuiDayCalendar-weekDayLabel': {
            fontSize: theme.typography.tiny.fontSize,
            textTransform: 'capitalize',
          },
        },
        weekContainer: {
          justifyContent: 'space-between',
          '.MuiButtonBase-root': {
            fontSize: theme.typography.headline5.fontSize,
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          backgroundColor: theme.palette.grey[500],
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: 0,

          '&.Mui-selected': {
            backgroundColor: `${theme.palette.common.black} !important`,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-maxWidthXl': {
            padding: theme.spacing(0, 3),
            [theme.breakpoints.up('xl')]: {
              padding: theme.spacing(0, 6),
            },
            '&.MuiContainer-disableGutters': {
              padding: 0,
            },
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          padding: theme.spacing(0, 0.8, 1.6, 0.8),
          borderRadius: theme.spacing(1.2),
          border: 'none',
        },
        columnSeparator: {
          display: 'none',
        },
        iconButtonContainer: {
          width: 'fit-content',
          visibility: 'visible',
          '.MuiButtonBase-root': {
            padding: 0,
          },
        },
        columnHeaderTitleContainer: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          wordBreak: 'break-word',
          fontWeight: 500,
          display: 'flex',
          gap: theme.spacing(0.2),
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            justifyContent: 'center',
          },
        },
        columnHeader: {
          padding: `${theme.spacing(0, 1.6)} !important`,
          ':focus': {
            outline: 'none',
          },
        },
        row: {
          '&:nth-of-type(odd)': {
            backgroundColor: `${Colors.GreyLight} !important`,
          },
          '&:nth-of-type(even)': {
            backgroundColor: `${Colors.White} !important`,
          },
          borderRadius: theme.spacing(0.6),
          padding: theme.spacing(2, 0),
        },
        cell: {
          border: 'none',
          padding: `${theme.spacing(0, 1.6)} !important`,
          '.Mui-disabled': {
            visibility: 'hidden',
            pointerEvents: 'none',
            cursor: 'default',
          },
          ':focus-within': {
            outline: 'none',
          },
        },
        virtualScroller: {
          overflowX: 'auto',
          [theme.breakpoints.up('lg')]: {
            overflowX: 'hidden',
          },
        },
        virtualScrollerRenderZone: {
          marginTop: theme.spacing(0.8),
        },
        footerContainer: {
          justifyContent: 'center',
          minHeight: 'auto',
          marginTop: theme.spacing(2.4),
          borderTop: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.common.black,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiFormHelperText-root': {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
          },
        },
      },
    },
  },
});

export default extendedTheme;
