import React from 'react';

import {Box} from '@mui/material';

import {CardProps} from '@/atoms/Card/definitions';
import {useStyles} from '@/atoms/Card/styles';

const Card = ({
  children,
  className,
  disabled = false,
  onClick,
  id,
}: CardProps) => {
  const {card} = useStyles(onClick, disabled);

  return (
    <Box
      {...(className && {className: className})}
      {...(id && {id: id})}
      sx={card}
      {...(onClick && !disabled && {onClick})}>
      {children}
    </Box>
  );
};

export default Card;
