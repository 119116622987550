import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const loyaltyList = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(0.8),
      width: '100%',
      '.boxList': {
        flexDirection: 'column',
        gap: theme.spacing(1.6),
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '.progressContainer': {
          position: 'relative',
          display: 'inline-flex',
          width: theme.spacing(11),
          height: theme.spacing(11),
          '.absolute': {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
          },
        },
      },
    }),
    [theme],
  );

  const dialog = useMemo(
    () => ({
      '.MuiDialog-paper': {
        maxWidth: theme.spacing(40),
        width: '100%',
        borderRadius: '16px',
        padding: theme.spacing(2.4),
      },
      '.MuiDialogTitle-root': {
        textAlign: 'left',
        padding: theme.spacing(0, 1.6, 0, 0),
        span: {
          fontSize: theme.typography.headline.fontSize,
          lineHeight: theme.typography.headline.fontSize,
        },
      },
      '.closeIconDialog': {
        right: theme.spacing(2.4),
        top: theme.spacing(2.4),
      },
      '.MuiDialogContent-root': {
        margin: theme.spacing(3.2, 0),
        img: {
          width: theme.spacing(20),
          height: theme.spacing(20),
          borderRadius: theme.spacing(0.8),
        },
      },
      '.MuiDialogActions-root': {
        p: 0,
        '.MuiButtonBase-root': {
          width: theme.spacing(20),
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({loyaltyList, dialog}), [loyaltyList, dialog]);
};
