import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {ContextMenu} from '@/atoms/ContextMenu';
import {SubMenuItem} from '@/atoms/ContextMenu/interfaces';
import {Dialog} from '@/atoms/Dialog';
import {CommonButtons} from '@/atoms/DialogButtons';
import {useToast} from '@/hooks/toast';
import {numberFormatter} from '@/lib/utils';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import {ProgressWheel} from '@/molecules/ProgressWheel';
import {TierBenefits} from '@/molecules/TierBenefits';
import {TierBlock} from '@/molecules/TierBlock';
import {Card, CardHeader} from '@/organisms/Card';
import {EditForm} from '@/organisms/CustomerBalance/EditForm';
import {IProps} from '@/organisms/CustomerBalance/interfaces';
import {TierInfo} from '@/organisms/TierInfo';
import {useAuthentication} from '@/store/authentication';
import {PatchPointsBody, useCustomerBalance} from '@/store/customerbalance';

import {useStyle} from './styles';

const CustomerBalance = ({
  points,
  pointsToNextTier,
  lastUpdate,
  tier,
  tierLabel,
  cardNumber,
  refetchCustomersInfo,
  refetchTierInfos,
  tierInfo,
  setUpdateFlag,
  currentMin,
  nextMin,
}: IProps) => {
  const {t} = useTranslation();
  const {updatePoints} = useCustomerBalance();
  const {showSuccessToast, showErrorToast} = useToast();
  const {isContactCenterSupervisor} = useAuthentication();
  const styles = useStyle();

  const [pointsError, setPointsError] = useState<boolean>(false);
  const [tierBenefitDialogOpen, setTierBenefitDialogOpen] =
    useState<boolean>(false);
  const [tierInfoDialogOpen, setTierInfoDialogOpen] = useState<boolean>(false);
  const [editPointsDialogOpen, setEditPointsDialogOpen] =
    useState<boolean>(false);
  const [updatedInfo, setUpdatedInfo] = useState<PatchPointsBody | null>(null);

  const toggleTierBenefitsDialog = useCallback((value?: boolean) => {
    setTierBenefitDialogOpen(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const toggleTierInfoDialog = useCallback((value?: boolean) => {
    setTierInfoDialogOpen(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const toggleEditPointsDialog = useCallback((value?: boolean) => {
    setEditPointsDialogOpen(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const dialogFormProps = useMemo(
    () => ({
      points,
      onChange: (updatedInfo: PatchPointsBody | null) =>
        setUpdatedInfo(updatedInfo),
    }),
    [points],
  );

  const disabledSubmit = useMemo(
    () => !updatedInfo || pointsError,
    [pointsError, updatedInfo],
  );

  const {mutate: patchUserMutate} = useMutation(updatePoints, {
    onSuccess: () => {
      showSuccessToast(t('CustomerInfo.edit.toast.success'));
      refetchCustomersInfo();
      refetchTierInfos();
      setUpdateFlag((prevFlag: number) => prevFlag + 1);
    },
    onError: () => showErrorToast(t('CustomerInfo.edit.toast.error')),
  });

  const onSubmit = useCallback(() => {
    if (updatedInfo) {
      patchUserMutate({
        ...updatedInfo,
      });
      setUpdatedInfo(null);
      toggleEditPointsDialog(false);
    }
  }, [patchUserMutate, toggleEditPointsDialog, updatedInfo]);

  const editPoints: SubMenuItem[] = useMemo(() => {
    return [
      {
        id: '0',
        icon: 'edit',
        label: 'Edit points balance',
        onClick: toggleEditPointsDialog,
      },
    ];
  }, [toggleEditPointsDialog]);

  const tierMenu: SubMenuItem[] = useMemo(() => {
    return [
      {
        id: '1',
        icon: 'information_circle',
        label: 'Customer tier information',
        onClick: toggleTierInfoDialog,
      },
      {
        id: '2',
        icon: 'tier_benefits',
        label: 'Rewards list',
        onClick: toggleTierBenefitsDialog,
      },
    ];
  }, [toggleTierBenefitsDialog, toggleTierInfoDialog]);

  const subMenuItems: SubMenuItem[] = useMemo(() => {
    if (isContactCenterSupervisor) {
      return [...editPoints, ...tierMenu];
    }
    return tierMenu;
  }, [editPoints, isContactCenterSupervisor, tierMenu]);

  return (
    <>
      <Card>
        <Stack gap={1.6} height="100%">
          <CardHeader
            title={t('CustomerBalance.sectionTitle')}
            action={
              <Box sx={styles.actions}>
                <ContextMenu
                  sx={styles.menuItems}
                  iconName="card_actions"
                  iconSize={24}
                  subMenuItems={subMenuItems}
                />
              </Box>
            }
          />
          <Box sx={styles.externalContainer}>
            <ProgressWheel
              value={points}
              currentMin={currentMin}
              nextMin={nextMin}
              pointsToNextTier={pointsToNextTier}
            />
            <Box sx={styles.container}>
              <TierBlock tier={tier} tierLabel={tierLabel} />
              <KeyValuePair
                small
                keyLabel={t('CustomerBalance.nextTier')}
                value={numberFormatter.format(pointsToNextTier)}
              />
              <KeyValuePair
                small
                keyLabel={t('CustomerBalance.lastUpdate')}
                value={dayjs(lastUpdate).utc().format('LL h:mm a')}
              />
            </Box>
          </Box>
        </Stack>
      </Card>
      {tierInfoDialogOpen && (
        <TierInfo
          tierInfo={tierInfo}
          open={tierInfoDialogOpen}
          onClose={() => toggleTierInfoDialog(false)}
        />
      )}
      {tierBenefitDialogOpen && (
        <TierBenefits
          open={tierBenefitDialogOpen}
          onClose={() => toggleTierBenefitsDialog(false)}
        />
      )}
      {editPointsDialogOpen && (
        <Dialog
          dialogTitle={t('CustomerBalance.editTitle')}
          open={editPointsDialogOpen}
          onClose={() => toggleEditPointsDialog(false)}
          dialogContent={
            <EditForm
              cardNumber={cardNumber}
              onError={setPointsError}
              {...dialogFormProps}
            />
          }
          dialogActions={
            <CommonButtons
              onClose={() => toggleEditPointsDialog(false)}
              onConfirm={onSubmit}
              confirmDisabled={disabledSubmit}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(CustomerBalance);
