import React, {useState, useCallback, useMemo} from 'react';
import {PiCaretDownFill} from 'react-icons/pi';

import {Stack, Menu, Divider, IconButton} from '@mui/material';

import {BlackTooltip} from '@/atoms/BlackTooltip';
import {BodyText} from '@/atoms/BodyText';
import {IconSVG} from '@/atoms/IconSVG';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useStyles} from '@/organisms/Feedback/FeedbackStatus/styles';
import {
  FeedbackConfiguration,
  FeedbackStatusConfiguration,
  FEEDBACK_STATUS,
} from '@/store/feedback/v2';
import {Colors} from '@/themes/variables';

interface FeedbackStatusProps {
  configuration: FeedbackConfiguration<FeedbackStatusConfiguration>;
  selectedStatus: FEEDBACK_STATUS;
  onStatusChange: (status: FEEDBACK_STATUS) => unknown;
  taskIsEditable: boolean;
}

const FeedbackStatus = ({
  configuration,
  selectedStatus,
  onStatusChange,
  taskIsEditable,
}: FeedbackStatusProps) => {
  const styles = useStyles();
  const {getTranslationWithValue} = useBaseTranslation('FeedbackBox.STATUS');
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  /**
   * @brief Current feedback status
   * @note This variable is used to get current feedback status (OPEN, IN_PROGRESS, CLOSED)
   */
  const currentFeedbackStatus = useMemo(() => {
    return Object.keys(configuration?.fieldAcceptedValue!).find(key => {
      const fieldAcceptedValueElement = configuration?.fieldAcceptedValue![key];

      return fieldAcceptedValueElement?.find(value => {
        return Object.values(value).includes(selectedStatus);
      });
    });
  }, [configuration?.fieldAcceptedValue, selectedStatus]);

  /**
   * @brief Current status label
   * @note This variable is used to get current status label
   */
  const currentStatusLabel = useMemo(() => {
    return (
      <>
        <BodyText superHeavy>
          {getTranslationWithValue(
            0,
            `FEEDBACK_STATUS.${selectedStatus}`,
            {},
            selectedStatus,
          )}
        </BodyText>{' '}
        {currentFeedbackStatus && (
          <BodyText color={Colors.Primary300} italic>
            (
            {getTranslationWithValue(
              0,
              `FEEDBACK_STATUS_TYPES.${currentFeedbackStatus!}`,
            ).toLowerCase()}
            )
          </BodyText>
        )}
      </>
    );
  }, [currentFeedbackStatus, getTranslationWithValue, selectedStatus]);

  const tooltipText = useMemo<JSX.Element | undefined>(() => {
    let tooltip = undefined;
    switch (selectedStatus) {
      case FEEDBACK_STATUS.NOT_CONTACTABLE:
      case FEEDBACK_STATUS.ALREADY_PURCHASED:
        tooltip = undefined;
        break;
      default:
        tooltip = getTranslationWithValue(0, `tooltipTexts.${selectedStatus}`);
        break;
    }

    if (typeof tooltip !== 'undefined') {
      return (
        <BlackTooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={tooltip}
          leaveTouchDelay={3000}
          placement="left"
          enterTouchDelay={0}>
          <IconButton className="statusIcon" disableRipple sx={{padding: 0}}>
            <IconSVG icon="error" size={18} color={Colors.GreyDark} />
          </IconButton>
        </BlackTooltip>
      );
    }

    return undefined;
  }, [getTranslationWithValue, selectedStatus]);

  return (
    <>
      <Stack sx={styles.statusContainer(taskIsEditable)}>
        <XSText className="statusLabel">
          {getTranslationWithValue(0, 'title')}
        </XSText>
        <Stack
          className="statusValue"
          {...(taskIsEditable && {onClick: toggleMenu})}>
          <BodyText>{currentStatusLabel}</BodyText>
          <PiCaretDownFill fontSize={24} />
        </Stack>
        {tooltipText && tooltipText}
      </Stack>
      {anchorEl && (
        <FeedbackStatusMenu
          anchorEl={anchorEl}
          toggleMenu={toggleMenu}
          configuration={configuration}
          selectedStatus={selectedStatus}
          onStatusChange={onStatusChange}
        />
      )}
    </>
  );
};

export default React.memo(FeedbackStatus);

interface FeedbackStatusMenuProps
  extends Omit<FeedbackStatusProps, 'taskIsEditable'> {
  anchorEl: HTMLElement | undefined;
  toggleMenu: (event?: React.MouseEvent<HTMLDivElement> | any) => void;
}

const FeedbackStatusMenu = React.memo(
  ({
    anchorEl,
    toggleMenu,
    configuration,
    selectedStatus,
    onStatusChange,
  }: FeedbackStatusMenuProps) => {
    const {getTranslationWithValue} = useBaseTranslation('FeedbackBox.STATUS');

    const styles = useStyles();

    /**
     * @brief On change
     * @note This function is used to handle on change
     */
    const onChange = useCallback(
      (status: FEEDBACK_STATUS) => {
        onStatusChange(status);
        toggleMenu();
      },
      [onStatusChange, toggleMenu],
    );

    return (
      <Menu
        sx={styles.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={true}
        onClose={() => toggleMenu()}>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" color={Colors.Black} />}>
          {Object.keys(configuration?.fieldAcceptedValue!).map(key => {
            const fieldAcceptedValueElement =
              configuration?.fieldAcceptedValue![key];
            return (
              <Stack key={key} className="statusMenu">
                <BodyText
                  heavy
                  color={Colors.Primary300}
                  className="statusGroup">
                  {getTranslationWithValue(0, `FEEDBACK_STATUS_TYPES.${key}`)}
                </BodyText>
                {fieldAcceptedValueElement.map(element => {
                  const label = Object.keys(element)[0];
                  const value = Object.values(element)[0];
                  return (
                    <BodyText
                      onClick={() => onChange(value)}
                      key={value}
                      className={`statusItem ${
                        selectedStatus === value ? 'statusItem__active' : ''
                      }`}>
                      {getTranslationWithValue(
                        0,
                        `FEEDBACK_STATUS.${value}`,
                        {},
                        label,
                      )}
                    </BodyText>
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
      </Menu>
    );
  },
);

FeedbackStatusMenu.displayName = 'FeedbackStatusMenu';
