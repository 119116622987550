import React, {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import {PiSealCheckLight} from 'react-icons/pi';

import {Box, Stack, CircularProgress} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {SuspenseBackgroundImage} from '@/atoms/SuspenseBackgroundImage';
import {TinyText} from '@/atoms/TinyText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useStyles} from '@/hooks/useRelateProductsModal/styles';
import {ProductProps} from '@/organisms/TransactionCard/interfaces';
import {AppointmentV2} from '@/store/appointments/v2';
import {Colors} from '@/themes/variables';

const useRelatedProductsModal = () => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.APPOINTMENT_SETTING',
  );
  const [appointment, setAppointment] = useState<AppointmentV2 | undefined>(
    undefined,
  );

  const toggleModal = useCallback((products: AppointmentV2 | undefined) => {
    setAppointment(products);
  }, []);

  const appointmentModal = useMemo(() => {
    if (typeof appointment === 'undefined') return null;

    return (
      <Modal
        open={!!appointment}
        onClose={() => toggleModal(undefined)}
        maxWidth={60}
        dialogTitle={
          <HeadlineText heavy>
            {getTranslationWithValue(
              0,
              `card.appointmentTypes.${appointment?.type}`,
            )}
          </HeadlineText>
        }
        dialogContent={<ProductsModalContent {...appointment} />}
      />
    );
  }, [appointment, getTranslationWithValue, toggleModal]);

  return {
    toggleModal,
    appointmentModal,
  };
};

export default useRelatedProductsModal;

interface ProductsModalContentProps extends AppointmentV2 {}

const ProductsModalContent = React.memo(
  ({...appointment}: ProductsModalContentProps) => {
    const styles = useStyles();
    const {getTranslationWithValue} = useBaseTranslation(
      'FeedbackBox.APPOINTMENT_SETTING',
      'CustomerProfile.AppointmentHistory',
    );

    const containerRef = useRef<HTMLDivElement>(null); // Reference to the container

    const calculateHeight = useCallback((numElements: number) => {
      const container = containerRef.current;
      if (!container) return 0;

      let totalHeight = 0;
      for (let i = 0; i < numElements; i++) {
        if (container.children[i]) {
          totalHeight += container.children[i].clientHeight;
        }
      }

      return totalHeight;
    }, []);

    useEffect(() => {
      if (containerRef.current) {
        const numOfElements = 3;
        const addBorders = 8 * (numOfElements - 1);
        containerRef.current.style.height = `${
          calculateHeight(numOfElements) + addBorders
        }px`;
      }
    }, [calculateHeight]);

    return (
      <Box width={'100%'}>
        <Stack
          display={'grid'}
          gridTemplateColumns={'repeat(2, 1fr)'}
          alignItems={'center'}
          paddingTop={1.6}
          paddingBottom={0.8}
          marginBottom={2.2}
          sx={{
            borderBottom: `1px solid ${Colors.Primary200}`,
          }}>
          <LabelValue
            label={getTranslationWithValue(0, 'card.appointmentDate')}
            value={
              appointment?.date
                ? dayjs(appointment?.date).utc().format('ll - LT')
                : '-'
            }
          />
          <LabelValue
            label={getTranslationWithValue(0, 'card.contacts')}
            value={`${appointment?.email ?? '-'} | ${
              appointment?.phone ?? '-'
            } `}
          />
        </Stack>
        <Stack display={'grid'} gridTemplateColumns={'1fr'} gap={1.6}>
          <Headline5 heavy>
            {getTranslationWithValue(1, 'relatedProducts')}
          </Headline5>
          <Stack ref={containerRef} sx={styles.productListContainer}>
            {appointment?.products?.map(product => {
              return (
                <ProductCard key={`product_${product?.upc}`} {...product} />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    );
  },
);

ProductsModalContent.displayName = 'ProductsModalContent';

interface ProductCardProps extends ProductProps {}

const ProductCard = React.memo(({...product}: ProductCardProps) => {
  const styles = useStyles();

  const productKind = useMemo(
    () => product?.specifyProductKind,
    [product?.specifyProductKind],
  );

  const productImage = useMemo(() => {
    if (!product?.images) return undefined;

    return `${product?.images[0].split('?')[0]}?imwidth=160`;
  }, [product?.images]);

  const productIcon = useMemo<JSX.Element>(() => {
    let icon: IconNames;

    switch (productKind) {
      case 'SUN':
        icon = 'lens_sunglasses';
        break;
      case 'LENSES':
        icon = 'lens_glasses';
        break;
      case 'FRAMES':
        icon = 'glasses';
        break;
      case 'CONTACTS':
        icon = 'lens_contact';
        break;
      case 'APPAREL':
        icon = 'clothes';
        break;
      case 'READERS':
        icon = 'readers';
        break;
      case 'WARRANTY':
        return <PiSealCheckLight color={Colors.GreyText} fontSize={32} />;
      case 'ACCESSORIES':
        icon = 'retail';
        break;
      default:
        icon = 'no_kind';
        break;
    }

    return <IconSVG icon={icon} color={Colors.GreyText} size={28} />;
  }, [productKind]);

  return (
    <Stack sx={styles.productCard}>
      <Box sx={styles.productImageContainer}>
        {productImage ? (
          <React.Suspense
            key={`${productImage}_small`}
            fallback={
              <CircularProgress disableShrink color="primary" size={25} />
            }>
            <SuspenseBackgroundImage
              sx={styles.productImageContainer}
              src={productImage}
            />
          </React.Suspense>
        ) : (
          productIcon
        )}
      </Box>
      <LabelValue
        value={
          <BodyText
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            heavy>
            {product?.styleName ?? '-'}
          </BodyText>
        }
        label={
          <TinyText color={Colors.GreyDark}>
            UPC: {product?.upc ?? '-'}
          </TinyText>
        }
      />
    </Stack>
  );
});

ProductCard.displayName = 'ProductCard';
