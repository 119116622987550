import {useCallback} from 'react';
import {generatePath, useHistory} from 'react-router-dom';

import {User} from '@/definitions/user';
import {Modules} from '@/definitions/users';
import {createSearchParams} from '@/lib/utils';
import {SingleTask} from '@/store/campaigns';

import {AuthRoutes} from './routes';

export const useNavigation = () => {
  const history = useHistory();

  const replacePath = useCallback(
    (path: AuthRoutes | string, search?: string) => {
      history.replace({pathname: path, search});
    },
    [history],
  );

  const goToPath = useCallback(
    (path: AuthRoutes | string, search?: string) => {
      history.push({pathname: path, search});
    },
    [history],
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goToMain = useCallback(() => {
    history.push(AuthRoutes.MAIN);
  }, [history]);

  const goToLoyaltyProfile = useCallback(
    (
      cardNumber: User['cardNumber'],
      banner: User['banner'],
      country: User['country'],
      origin: 'search' | 'customer' | 'task',
    ) => {
      goToPath(
        generatePath(AuthRoutes.LOYALTY_PROFILE, {
          cardNumber,
          banner,
          country,
        }),
        createSearchParams({origin}),
      );
    },
    [goToPath],
  );

  const goToTask = useCallback(
    (taskId: SingleTask['id'] | string, currentModule: Modules) => {
      const route =
        currentModule === Modules.MYACCOUNT
          ? AuthRoutes.MYACCOUNT_CUSTOMER_TASK_DETAILS
          : AuthRoutes.CLIENTELING_TASK_DETAILS;
      goToPath(generatePath(route, {taskId}));
    },
    [goToPath],
  );

  const goToClientResults = useCallback(
    (
      q: string,
      bannerSearch: string,
      countrySearch: string,
      tab?: string,
      onlyInThisStore?: string,
    ) => {
      goToPath(
        AuthRoutes.MYACCOUNT_CLIENT_RESULTS,
        createSearchParams({
          q,
          bannerSearch,
          countrySearch,
          tab,
          onlyInThisStore,
        }),
      );
    },
    [goToPath],
  );

  const goToCustomerProfile = useCallback(
    (
      email: string,
      banner: string,
      country: string,
      customerId?: string,
      personaChannel?: string,
      showCustomers?: boolean,
    ) => {
      goToPath(
        AuthRoutes.MYACCOUNT_CUSTOMER,
        createSearchParams({
          email,
          banner,
          country,
          customerId,
          personaChannel,
          showCustomers,
        }),
      );
    },
    [goToPath],
  );

  const goToPurchaseList = useCallback(
    (name: string, email: string, banner: string, country: string) => {
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('bannerSearch', banner);
      localStorage.setItem('countrySearch', country);
      goToPath(AuthRoutes.MYACCOUNT_PURCHASE_LIST);
    },
    [goToPath],
  );

  const goToCampaignsReports = useCallback(() => {
    goToPath(AuthRoutes.CLIENTELING_CAMPAIGNS_REPORTS);
  }, [goToPath]);

  const goToCampaignsReportDetail = useCallback(
    (campaignCode: string) => {
      history.push(
        generatePath(AuthRoutes.CLIENTELING_CAMPAIGNS_REPORT_DETAIL, {
          campaignCode,
        }),
      );
    },
    [history],
  );

  const updateQueryParams = useCallback(
    (params: {[key: string]: any}) => {
      replacePath(history.location.pathname, createSearchParams(params));
    },
    [history.location.pathname, replacePath],
  );

  return {
    history,
    replacePath,
    goToPath,
    goBack,
    goToMain,
    goToLoyaltyProfile,
    goToClientResults,
    goToCustomerProfile,
    goToTask,
    goToCampaignsReports,
    goToCampaignsReportDetail,
    updateQueryParams,
    goToPurchaseList,
  };
};
