import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, useMediaQuery, useTheme} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {PurchaseCategory} from '@/atoms/PurchaseCategory';
import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useScrollPosition} from '@/hooks/useScrollPosition/useScrollPosition';
import {composeAddress} from '@/lib/utils';
import {useLocale} from '@/store/locale';
import {Colors} from '@/themes/variables';

import {useNavigation} from '../../../navigation';
import {ClientCardProps, PersonasDetailsProps} from './interfaces';
import {useStyle} from './styles';

const ClientResult = ({item}: ClientCardProps) => {
  const {saveScrollPositionById} = useScrollPosition();
  const styles = useStyle();
  const theme = useTheme();
  const {formatDate} = useLocale();
  const {t} = useTranslation();
  const {goToCustomerProfile} = useNavigation();
  const {showSuccessToast} = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [expanded, setExpanded] = useState<boolean>(false);

  const copyToClipboard = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
      event.stopPropagation();
      showSuccessToast(t('MyAccount.ClientResult.copiedToClipboard'));
      navigator.clipboard.writeText(value);
    },
    [showSuccessToast, t],
  );

  const MyAccountRow = useCallback(
    (itemRow: PersonasDetailsProps) => {
      return (
        <Box
          sx={styles.cardRow}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start">
          <Text variant="body" medium color={Colors.Grey100Text}>
            {t('MyAccount.ClientResult.myAccount')}
          </Text>
          {itemRow.myAccount ? (
            <Box sx={styles.consentRow}>
              <IconSVG icon="consent" size={12} />
              <Text variant="caption" color={Colors.Black}>
                {t('MyAccount.ClientResult.active')}
              </Text>
            </Box>
          ) : (
            <Box sx={styles.consentRow}>
              <IconSVG icon="not_consent" size={12} />
              <Text variant="caption" color={Colors.Black}>
                {t('MyAccount.ClientResult.inactive')}
              </Text>
            </Box>
          )}
        </Box>
      );
    },
    [styles.cardRow, styles.consentRow, t],
  );

  const MobileRow = useCallback(
    (itemRow: PersonasDetailsProps) => {
      return (
        <Box sx={styles.cardRow}>
          <Box>
            <Text variant="body" medium color={Colors.Grey100Text}>
              {itemRow.mobile || '-'}
            </Text>
          </Box>
          <Box>
            <Text variant="caption" medium color={Colors.GreyDark}>
              {t('MyAccount.ClientResult.mobile')}
            </Text>
          </Box>
        </Box>
      );
    },
    [styles.cardRow, t],
  );

  const handleClick = useCallback(
    (itemRow?: PersonasDetailsProps) => {
      saveScrollPositionById('clientResultsContainer');
      goToCustomerProfile(
        item.individualDetails.email,
        item.individualDetails.banner,
        item.individualDetails.country,
        itemRow ? itemRow.customerId : item.personasDetails?.[0].customerId,
        itemRow
          ? itemRow.personaChannel
          : item.personasDetails?.[0].personaChannel,
        true,
      );
    },
    [
      goToCustomerProfile,
      item.individualDetails.banner,
      item.individualDetails.country,
      item.individualDetails.email,
      item.personasDetails,
    ],
  );

  const renderContentRow = useCallback(
    (itemRow: PersonasDetailsProps, i: number) => {
      return (
        <Box
          sx={i > 0 ? styles.contentBoxAlt : styles.contentBox}
          key={`${i}-${itemRow.customerId}`}
          onClick={() => handleClick(itemRow)}>
          <Box sx={styles.content}>
            <Box sx={styles.cardRow}>
              <Box>
                <Text variant="body" medium color={Colors.Grey100Text}>
                  {itemRow.email || item.individualDetails.email || '-'}
                </Text>
              </Box>
              <Box>
                <Text variant="caption" medium color={Colors.GreyDark}>
                  {t('MyAccount.ClientResult.email')}
                </Text>
              </Box>
            </Box>
            <Box
              sx={styles.cardRow}
              onClick={event => copyToClipboard(event, itemRow.customerId)}>
              <Box>
                <Text
                  variant="body"
                  medium
                  color={Colors.Grey100Text}
                  sx={{cursor: 'pointer'}}>
                  {itemRow.customerId || '-'}
                </Text>
                <Box
                  sx={{
                    display: 'inline-block',
                    marginLeft: '16px',
                    cursor: 'pointer',
                  }}>
                  <IconSVG icon="copy" size={18} color={Colors.Grey100Text} />
                </Box>
              </Box>
              <Box>
                <Text
                  variant="caption"
                  medium
                  color={Colors.GreyDark}
                  sx={{cursor: 'pointer'}}>
                  {t('MyAccount.ClientResult.customerID')}
                </Text>
              </Box>
            </Box>
            {isMobile ? MobileRow(itemRow) : MyAccountRow(itemRow)}
            {isMobile ? MyAccountRow(itemRow) : MobileRow(itemRow)}
            <Box sx={styles.cardRow}>
              <Box>
                <Text variant="body" medium color={Colors.Grey100Text}>
                  {itemRow.address ? composeAddress(itemRow.address) : '-'}
                </Text>
              </Box>
              <Box>
                <Text variant="caption" medium color={Colors.GreyDark}>
                  {t('MyAccount.ClientResult.address')}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.chevron}>
            <IconSVG icon="chevron_right" size={40} />
          </Box>
        </Box>
      );
    },
    [
      handleClick,
      isMobile,
      MyAccountRow,
      MobileRow,
      copyToClipboard,
      item.individualDetails.email,
      styles.cardRow,
      styles.chevron,
      styles.content,
      styles.contentBox,
      styles.contentBoxAlt,
      t,
    ],
  );

  const renderContent = useMemo(() => {
    if (!expanded) {
      return renderContentRow(item.personasDetails[0], 0);
    } else {
      return item.personasDetails.map((row, i) => {
        return renderContentRow(row, i);
      }, []);
    }
  }, [expanded, item.personasDetails, renderContentRow]);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.heading} onClick={() => handleClick()}>
        {/* First heading column */}
        <Box>
          <Box>
            <Text variant="headline" superHeavy color={Colors.White}>
              {item.individualDetails.name}
            </Text>
          </Box>
          <Box>
            <Text variant="body" medium color={Colors.Grey10}>
              {item.individualDetails.email}
            </Text>
          </Box>
        </Box>
        {/* Second heading column */}
        <Box>
          <Box>
            <Text variant="headline" superHeavy color={Colors.White}>
              {item.individualDetails.lastPurchase
                ? formatDate(item.individualDetails.lastPurchase)
                : '-'}
            </Text>
          </Box>
          <Box>
            <Text variant="caption" color={Colors.Primary200}>
              {t('MyAccount.ClientResult.lastPurchase')}
            </Text>
          </Box>
        </Box>
        {/* Third heading column */}
        <Box>
          <Box
            sx={styles.purchaseCategories}
            onClick={e => e.stopPropagation()}>
            <PurchaseCategory
              categories={item.individualDetails.purchaseCategory}
              color={Colors.White}
            />
          </Box>
          <Box>
            <Text variant="caption" color={Colors.Primary200}>
              {t('MyAccount.ClientResult.purchaseCategories')}
            </Text>
          </Box>
        </Box>
      </Box>
      {renderContent}
      <Box sx={styles.bottom} onClick={() => handleClick()}>
        {item.personasDetails.length > 1 ? (
          <Box sx={styles.bottomAction}>
            <Text
              variant="tiny"
              superHeavy
              style={{cursor: 'pointer', padding: '8px', margin: '-8px'}}
              onClick={e => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}>
              {expanded
                ? t('MyAccount.ClientResult.hide')
                : t('MyAccount.ClientResult.show')}
              <Box
                style={{
                  display: 'inline-block',
                  marginLeft: '8px',
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}>
                <IconSVG icon="chevron_down" size={12} />
              </Box>
            </Text>
          </Box>
        ) : (
          <Box sx={styles.emptyBottom} />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ClientResult);
