import {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {getParamByISO} from 'iso-country-currency';

const useCurrencyFormat = () => {
  const {i18n} = useTranslation();

  const formatToCurrency = useCallback(
    (value: number | null, country: string) => {
      if (value === null) {
        return '-';
      }

      return value.toLocaleString(i18n.language, {
        style: 'currency',
        currency: getParamByISO(country, 'currency'),
        maximumFractionDigits: 2,
      });
    },
    [i18n.language],
  );

  return useMemo(
    () => ({
      formatToCurrency,
    }),
    [formatToCurrency],
  );
};

export default useCurrencyFormat;
