import React, {useState, useMemo, useCallback, memo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Modal} from '@/atoms/Modal';
import {Text} from '@/atoms/Typography/Text';
import {usePaginationStyles} from '@/hooks/useQuoteModal/styles';
import {Transaction} from '@/organisms/Transaction';
import {useAuthentication} from '@/store/authentication';
import {QuotationData} from '@/store/quotations';

export const useQuoteModal = (quotes: Array<QuotationData>) => {
  const {t} = useTranslation();
  const {userCountry} = useAuthentication();

  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<
    number | undefined
  >(undefined);

  const showModal = useMemo(
    () => typeof selectedQuoteIndex !== 'undefined',
    [selectedQuoteIndex],
  );

  const toggleModal = useCallback((quoteIndex?: number) => {
    setSelectedQuoteIndex(() => {
      return typeof quoteIndex !== 'undefined' ? quoteIndex : undefined;
    });
  }, []);

  const changeIndex = useCallback((newIndex: number) => {
    setSelectedQuoteIndex(() => newIndex);
  }, []);

  return useMemo(
    () => ({
      toggleModal,
      modal: showModal ? (
        <>
          <Modal
            open={showModal}
            onClose={() => toggleModal()}
            maxWidth={96}
            dialogTitle={t('Quotes.modalTile')}
            dialogContent={
              <Stack flex={1} width="100%" overflow="hidden" paddingTop={1.8}>
                <Stack flex={1} width="100%" sx={{overflowY: 'auto'}}>
                  <Transaction
                    country={userCountry!}
                    isQuote
                    transaction={quotes[selectedQuoteIndex!]}
                  />
                </Stack>
                <QuoteModalPagination
                  changeIndex={changeIndex}
                  quotesLength={quotes?.length}
                  selectedQuoteIndex={selectedQuoteIndex!}
                />
              </Stack>
            }
          />
        </>
      ) : null,
    }),
    [
      changeIndex,
      quotes,
      selectedQuoteIndex,
      showModal,
      t,
      toggleModal,
      userCountry,
    ],
  );
};

const QuoteModalPagination = memo(
  ({
    quotesLength,
    selectedQuoteIndex,
    changeIndex,
  }: {
    quotesLength: number;
    selectedQuoteIndex: number;
    changeIndex: (newIndex: number) => void;
  }) => {
    const styles = usePaginationStyles();

    const enabledCta = useMemo(() => {
      return {
        prev: selectedQuoteIndex + 1 !== 1,
        next: selectedQuoteIndex + 1 !== quotesLength,
      };
    }, [quotesLength, selectedQuoteIndex]);

    const handlePagination = useCallback(
      (type: 'prev' | 'next') => {
        let newIndex = selectedQuoteIndex;
        if (type === 'prev') {
          newIndex -= 1;
        } else {
          newIndex += 1;
        }
        changeIndex(newIndex);
      },
      [changeIndex, selectedQuoteIndex],
    );

    return (
      <Stack
        gap={1.8}
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding={1.6}>
        <Box
          {...(enabledCta?.prev && {
            onClick: () => handlePagination('prev'),
          })}
          sx={[styles.iconContainer, styles.iconStatus(enabledCta?.prev)]}>
          <IconSVG icon="chevron_left" size={24} />
        </Box>
        <Text medium variant="tiny" sx={{lineHeight: '14px'}}>
          {selectedQuoteIndex + 1} of {quotesLength}
        </Text>
        <Box
          {...(enabledCta?.next && {
            onClick: () => handlePagination('next'),
          })}
          sx={[styles.iconContainer, styles.iconStatus(enabledCta?.next)]}>
          <IconSVG icon="chevron_right" size={24} />
        </Box>
      </Stack>
    );
  },
);

QuoteModalPagination.displayName = 'QuoteModalPagination';
