import React, {useMemo, useState, useEffect} from 'react';

import {Skeleton, Box, Stack, Divider} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {LabelValue} from '@/atoms/LabelValue';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useQuoteModal} from '@/hooks/useQuoteModal';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {useStyles} from '@/organisms/Quotes/styles';
import {QuotationData, GetQuotationsResponse} from '@/store/quotations';
import {Colors} from '@/themes/variables';

interface QuotesProps {
  response?: GetQuotationsResponse;
}

const Quotes = ({response}: QuotesProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {getTranslationWithValue} = useBaseTranslation('Quotes');

  useEffect(() => {
    if (!!response && response?.length === 0) {
      setIsOpen(true);
    }
  }, [response]);

  const renderContent = useMemo(() => {
    if (!response) {
      return <Skeleton height="25px" animation="wave" variant="rounded" />;
    }

    if (response?.length === 0) {
      return (
        <Box textAlign="center">
          <BodyText>{getTranslationWithValue(0, 'emptyState')}</BodyText>
        </Box>
      );
    }

    return <QuoteList quotes={response} />;
  }, [getTranslationWithValue, response]);

  return (
    <Card>
      <CustomAccordion
        bottomContent
        labelAlign="center"
        disableClick={typeof response === 'undefined' || response?.length === 0}
        hideAccordionLabel={
          typeof response === 'undefined' || response?.length === 0
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        accordionTitle={
          <HeadlineText superHeavy>
            {getTranslationWithValue(0, 'title')}
          </HeadlineText>
        }
        expandedContent={() => renderContent}
      />
    </Card>
  );
};

export default React.memo(Quotes);

interface QuoteListProps {
  quotes: Array<QuotationData>;
}

const QuoteList = React.memo(({quotes}: QuoteListProps) => {
  const {toggleModal, modal} = useQuoteModal(quotes);

  return (
    <>
      <Stack
        direction="column"
        gap={1.6}
        divider={<Divider orientation="horizontal" />}>
        {quotes.map((quote, index) => {
          return (
            <SingleQuote
              key={quote?.orderId}
              onClick={() => toggleModal(index)}
              quote={quote}
            />
          );
        })}
      </Stack>
      {modal}
    </>
  );
});

QuoteList.displayName = 'QuoteList';

interface SingleQuoteProps {
  quote: QuotationData;
  onClick: () => unknown;
}

const SingleQuote = React.memo(({quote, onClick}: SingleQuoteProps) => {
  const styles = useStyles();
  const {getTranslationWithValue} = useBaseTranslation('Quotes.statuses');

  const quoteStatus = useMemo(():
    | {icon: IconNames; status: string}
    | undefined => {
    if (!quote?.quotationStatus) {
      return undefined;
    }
    let icon: IconNames = 'not_consent';
    let status = getTranslationWithValue(0, quote?.quotationStatus);

    switch (quote?.quotationStatus) {
      case 'PENDING_QUOTATION':
        icon = 'not_consent';
        break;
      case 'PURCHASED_QUOTATION':
        icon = 'consent';
        break;
    }

    return {
      icon,
      status,
    };
  }, [getTranslationWithValue, quote?.quotationStatus]);

  return (
    <Stack onClick={onClick} key={quote?.orderId} sx={styles.singleQuotation}>
      <LabelValue
        value={
          <BodyText underline medium>
            {quote?.orderId}
          </BodyText>
        }
        label={
          quote?.transactionDate
            ? dayjs(quote?.transactionDate)
                .utc()
                .format(
                  dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
                )
            : '-'
        }
      />
      <LabelValue
        value={
          quoteStatus ? (
            <Stack
              direction="row"
              alignItems="center"
              gap={0.4}
              justifyContent="end">
              <IconSVG icon={quoteStatus?.icon} size={16} />
              <XSText textAlign="right" marginTop={0.2}>
                {quoteStatus?.status}
              </XSText>
            </Stack>
          ) : (
            <XSText>-</XSText>
          )
        }
        label={
          <XSText color={Colors.GreyDark} textAlign="right">
            <XSText superHeavy>{quote?.store?.storeCode || ''}</XSText>{' '}
            {quote?.store?.storeName}
          </XSText>
        }
      />
    </Stack>
  );
});

SingleQuote.displayName = 'SingleQuote';
