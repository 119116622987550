import React, {useState, useMemo, useCallback} from 'react';
import {BsChevronRight} from 'react-icons/bs';

import {Box, Skeleton, Stack} from '@mui/material';
import dayjs from 'dayjs';

import {Badge} from '@/atoms/Badge';
import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {TinyText} from '@/atoms/TinyText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useCurrentModule} from '@/hooks/useCurrentModule';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {useNavigation} from '@/navigation/useNavigation';
import {useStyles} from '@/organisms/LinkedTasks/styles';
import {PostLinkedTasksResponse} from '@/store/tasks/types';

export type LinkedTasksProps = {
  response?: PostLinkedTasksResponse;
  loading: boolean;
  currentStoreId?: string;
};

const BASE_TRANSLATION = 'LinkedTasks';

const LinkedTasks = ({response, loading, currentStoreId}: LinkedTasksProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  /**
   * Check if the accordion should be disabled
   */
  const disableClick = useMemo(() => {
    return typeof response === 'undefined' || loading;
  }, [loading, response]);

  const renderContent = useMemo(() => {
    if (disableClick) {
      return <Skeleton height="80px" animation="wave" variant="rounded" />;
    }

    if (!isOpen) return <Box />;

    return (
      <LinkedTasksList response={response} currentStoreId={currentStoreId} />
    );
  }, [disableClick, isOpen, response, currentStoreId]);

  return (
    <Card>
      <CustomAccordion
        bottomContent
        labelAlign={'center'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableClick={disableClick}
        hideAccordionLabel={disableClick}
        accordionTitle={
          <HeadlineText superHeavy>
            {getTranslationWithValue(0, 'header')}
          </HeadlineText>
        }
        expandedContent={() => renderContent}
      />
    </Card>
  );
};

export default React.memo(LinkedTasks);

type LinkedTasksListProps = Pick<
  LinkedTasksProps,
  'response' | 'currentStoreId'
>;

const LinkedTasksList = React.memo(
  ({response, currentStoreId}: LinkedTasksListProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const {linkedTasksContainer} = useStyles();
    const {goToTask} = useNavigation();
    const {currentModule} = useCurrentModule();

    const handleTaskAction = useCallback(
      (taskId: string) => {
        goToTask(taskId, currentModule!);
        return;
      },
      [currentModule, goToTask],
    );

    const checkStoreId = useCallback(
      (storeId: string) => {
        if (!currentStoreId) {
          return true;
        }

        return storeId === currentStoreId;
      },
      [currentStoreId],
    );

    const dataTable = useMemo(() => {
      if (!response) {
        return [];
      }

      return response?.map(task => {
        return {
          sameStore: checkStoreId(task?.storeUuid),
          uuid: task?.taskUuid,
          badge: {
            color: task?.campaignColor,
            badgeLabel: task?.campaignName,
          },
          dueDate: task?.dueDateTask
            ? dayjs(task?.dueDateTask)
                .utc()
                .format(
                  dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMM YYYY',
                )
            : '-',
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            handleTaskAction(task?.taskUuid);
          },
        };
      });
    }, [checkStoreId, handleTaskAction, response]);

    if (response?.length === 0) {
      return (
        <Stack direction="column" alignItems="center">
          <BodyText>{getTranslationWithValue(0, 'emptyState')}</BodyText>
        </Stack>
      );
    }

    return (
      <Stack sx={linkedTasksContainer}>
        <table>
          <thead className={'row row__heading'}>
            <tr>
              <th>
                <TinyText medium>
                  {getTranslationWithValue(0, 'campaign')}
                </TinyText>
              </th>
              <th>
                <TinyText medium justifySelf={'center'}>
                  {getTranslationWithValue(0, 'dueDate')}
                </TinyText>
              </th>
              <th>
                <></>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataTable?.map((data, index) => {
              const {badge, dueDate, uuid, onClick, sameStore} = data;
              return (
                <tr
                  key={uuid}
                  className={`row row__task ${
                    sameStore ? 'row__task__clickable' : ''
                  }`}
                  {...(sameStore && {onClick: e => onClick(e)})}>
                  <td>
                    <Badge
                      {...(sameStore && {onClick: e => onClick(e)})}
                      color={badge?.color}
                      badgeLabel={badge?.badgeLabel}
                    />
                  </td>
                  <td>
                    <TinyText justifySelf={'center'} className={'dueDate'}>
                      {dueDate}
                    </TinyText>
                  </td>
                  <td>
                    {sameStore && (
                      <span>
                        <BsChevronRight fontSize={24} />
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Stack>
    );
  },
);

LinkedTasksList.displayName = 'LinkedTasksList';
