import dayjs from 'dayjs';

import {
  ReportFilters,
  ReportFiltersAction,
  ReportFiltersActions,
} from '@/store/reportFilters/interfaces.d';

export const reportFiltersInitialStartDate = dayjs().utc().startOf('month');
export const reportFiltersInitialEndDate = dayjs().utc();

export const getInitialState = (): ReportFilters => ({
  regions: [],
  stores: [],
  status: [],
  codes: [],
  startDate: reportFiltersInitialStartDate,
  endDate: reportFiltersInitialEndDate,
  page: 1,
  orderBy: 'ASC',
  sortBy: null,
});

export const reportFiltersReducer = (
  state = getInitialState(),
  action: ReportFiltersAction,
) => {
  switch (action.type) {
    case ReportFiltersActions.changeField:
      const {key, value} = action?.payload;
      return {
        ...state,
        [key]: value,
        ...(key === 'regions' && {
          stores: [],
        }),
        page: 1,
      };
    case ReportFiltersActions.changeDates:
      const hasDiffStart = !dayjs(state.startDate)
        .utc()
        .isSame(action?.payload?.startDate, 'date');
      const hasDiffEnd = !dayjs(state.endDate)
        .utc()
        .isSame(action?.payload?.endDate, 'date');
      return {
        ...state,
        ...((hasDiffStart || hasDiffEnd) && {
          codes: [],
        }),
        startDate: action?.payload?.startDate,
        endDate: action?.payload?.endDate,
        page: 1,
      };
    case ReportFiltersActions.changePage:
      return {
        ...state,
        page: action?.payload,
      };
    case ReportFiltersActions.resetFilters:
      return getInitialState();
    default:
      return state;
  }
};
