import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const productListContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(0.8),
      overflowY: 'auto',
      scrollbarWidth: 'none',
    }),
    [theme],
  );

  const productCard = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      alignItems: 'center',
      gap: theme.spacing(1.6),
      backgroundColor: Colors.GreyBackground,
      padding: theme.spacing(0.8),
      borderRadius: theme.spacing(1.2),
    }),
    [theme],
  );

  const productImageContainer = useMemo<SxProps<Theme>>(
    () => ({
      width: theme.spacing(16),
      height: theme.spacing(12),
      display: 'grid',
      placeItems: 'center',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.8),
    }),
    [theme],
  );

  return useMemo(
    () => ({productImageContainer, productCard, productListContainer}),
    [productImageContainer, productCard, productListContainer],
  );
};
