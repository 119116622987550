import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Skeleton, Box, useTheme} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {DashboardContainer} from '@/atoms/DashboardContainer';
import {Link} from '@/atoms/Link';
import {CampaignCard} from '@/molecules/CampaignCard';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useStyles} from '@/organisms/DashboardCampaignCards/styles';
import {DashboardCampaignCardsProps} from '@/organisms/DashboardCampaignCards/types';
import {useAuthentication} from '@/store/authentication';
import {useCampaigns, SingleCampaign} from '@/store/campaigns';

const DashboardCampaignCards = ({
  startDate,
  endDate,
}: DashboardCampaignCardsProps) => {
  const {t} = useTranslation();
  const {userData} = useAuthentication();
  const {getDashboardCards} = useCampaigns();
  const {campaignsContainer} = useStyles();
  const theme = useTheme();
  const {goToPath} = useNavigation();

  const dashboardSubtitle = useMemo(() => {
    let date = dayjs().utc().format('MMMM YYYY');
    return `${date.charAt(0).toUpperCase()}${date.slice(1)}`;
  }, []);

  const {data: CampaignCards, isLoading: CampaignCardsLoading} = useQuery(
    ['campaign_card', startDate, endDate, userData?.currentStore?.id],
    () =>
      getDashboardCards({
        storeId: userData?.currentStore?.id!,
        startDate,
        endDate,
        page: 1,
        limit: 6,
      }),
    {
      retry: false,
    },
  );

  const renderCampaignsCard = useMemo(() => {
    if (CampaignCardsLoading || !CampaignCards) {
      return Array(6)
        .fill('')
        .map((_, index) => {
          return (
            <Skeleton
              className="campaignCard"
              key={index}
              animation="wave"
              variant="rounded"
              width="100%"
              height={200}
            />
          );
        });
    }

    return CampaignCards?.data.map(item => {
      return (
        <Box className="campaignCard" key={`${item?.code}_campaign`}>
          <CampaignCard campaign={item as SingleCampaign} />
        </Box>
      );
    });
  }, [CampaignCards, CampaignCardsLoading]);

  return (
    <DashboardContainer
      title={t('StoreManager.dashboard.activeCampaigns')}
      subtitle={dashboardSubtitle}
      action={
        <Link
          position="center"
          linkVariant="dark"
          textVariant="body"
          lineHeight={theme.typography.body.fontSize}
          href="#"
          handleOnClick={() => goToPath(AuthRoutes.CLIENTELING_CAMPAIGNS)}>
          {t('StoreManager.dashboard.viewAll')}
        </Link>
      }>
      <Box sx={campaignsContainer}>{renderCampaignsCard}</Box>
    </DashboardContainer>
  );
};

export default React.memo(DashboardCampaignCards);
