import React, {useMemo, useCallback, useState, useRef, useEffect} from 'react';
import {useInView} from 'react-intersection-observer';

import {Stack, Button, Box} from '@mui/material';
import dayjs from 'dayjs';

import {Headline5} from '@/atoms/Headline5';
import {IconSVG} from '@/atoms/IconSVG';
import {Modal} from '@/atoms/Modal';
import {TinyText} from '@/atoms/TinyText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {tabLiteBasicConfiguration} from '@/lib/utils';
import {AppointmentCard} from '@/molecules/AppointmentCard';
import {ScheduledAppointments} from '@/molecules/ScheduledAppointments';
import {FeedbackAppointmentProps} from '@/organisms/Feedback/FeedbackAppointment/interfaces.d';
import {useStyles} from '@/organisms/Feedback/FeedbackAppointment/styles';
import {
  useAppointmentsV2,
  PatchAppointmentV2,
  AppointmentV2,
  WidgetResponseV2,
  CreateUpdateAppointmentWidgetV2,
} from '@/store/appointments/v2';
import {useAuthentication} from '@/store/authentication';
import {APPOINTMENT_SETTINGS, FEEDBACK_STATUS} from '@/store/feedback/v2';

const FeedbackAppointment = ({
  configuration,
  appointment,
  taskHeaderData,
  taskId,
  handleCreateTaskAppointment,
  handleDisassociateTaskAppointment,
  handleUpdateTaskAppointment,
  handleDeleteAppointment,
  taskIsEditable,
  feedbackStatus,
  customer,
}: FeedbackAppointmentProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.APPOINTMENT_SETTING',
  );
  const styles = useStyles();
  const {ref: modalRef} = useInView();
  const {userData} = useAuthentication();
  const [showBookingDialog, setShowBookingDialog] = useState(false);
  const dialogAction = useRef<'CREATE' | 'EDIT' | 'DELETE' | undefined>(
    undefined,
  );
  const {formatWidgetData} = useAppointmentsV2();

  const deleteAppointment = useCallback(() => {
    handleDeleteAppointment({
      storeId: taskHeaderData?.storeId!,
      taskId,
      banner: taskHeaderData?.banner!,
      country: taskHeaderData?.country!,
    });
  }, [
    handleDeleteAppointment,
    taskHeaderData?.banner,
    taskHeaderData?.country,
    taskHeaderData?.storeId,
    taskId,
  ]);

  /**
   * Save appointment to task
   */
  const disassociateTaskAppointment = useCallback(() => {
    handleDisassociateTaskAppointment({
      storeId: taskHeaderData?.storeId!,
      taskId,
      banner: taskHeaderData?.banner!,
      country: taskHeaderData?.country!,
    });
  }, [
    handleDisassociateTaskAppointment,
    taskHeaderData?.banner,
    taskHeaderData?.country,
    taskHeaderData?.storeId,
    taskId,
  ]);

  /**
   * Update appointment
   */
  const updateTaskAppointment = useCallback(
    (appointment: PatchAppointmentV2) => {
      handleUpdateTaskAppointment({
        storeId: taskHeaderData?.storeId!,
        taskId,
        banner: taskHeaderData?.banner!,
        country: taskHeaderData?.country!,
        searchId: appointment?.searchId,
        date: dayjs(appointment?.date).utc().format(),
        type: appointment?.type,
        firstname: appointment?.firstname,
        lastname: appointment?.lastname,
        email: appointment?.email,
        storeCode: appointment?.storeCode,
        phone: appointment?.phone,
      });
    },
    [
      handleUpdateTaskAppointment,
      taskHeaderData?.banner,
      taskHeaderData?.country,
      taskHeaderData?.storeId,
      taskId,
    ],
  );

  /**
   * Create appointment
   */
  const createTaskAppointment = useCallback(
    (appointment: AppointmentV2) => {
      handleCreateTaskAppointment({
        storeId: taskHeaderData?.storeId!,
        taskId,
        banner: taskHeaderData?.banner!,
        country: taskHeaderData?.country!,
        id: appointment?.id,
        date: dayjs(appointment?.date).utc().format(),
        type: appointment?.type,
        firstname: appointment?.firstname,
        lastname: appointment?.lastname,
        email: appointment?.email,
        storeCode: appointment?.storeCode,
        phone: appointment?.phone,
      });
    },
    [
      handleCreateTaskAppointment,
      taskHeaderData?.banner,
      taskHeaderData?.country,
      taskHeaderData?.storeId,
      taskId,
    ],
  );

  const handleMessage = useCallback(
    (event: MessageEvent<WidgetResponseV2>) => {
      if (event.data.id === 'cancellation') {
        deleteAppointment();
      } else {
        const payload = formatWidgetData(
          event.data as CreateUpdateAppointmentWidgetV2,
          dialogAction.current,
        );

        switch (dialogAction.current) {
          case 'CREATE':
            createTaskAppointment(payload as AppointmentV2);
            break;
          case 'EDIT':
            updateTaskAppointment(payload as PatchAppointmentV2);
            break;
        }
      }

      setTimeout(() => {
        setShowBookingDialog(() => false);
      }, 1000);
    },
    [
      createTaskAppointment,
      deleteAppointment,
      formatWidgetData,
      updateTaskAppointment,
    ],
  );

  useEffect(() => {
    const listener = (event: MessageEvent<WidgetResponseV2>) => {
      if (
        event?.origin !== process.env.REACT_APP_BASE_URL ||
        !(typeof event?.data === 'object' && 'id' in event?.data)
      ) {
        return;
      }
      handleMessage(event);
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [handleMessage]);

  const openTabLite = useCallback(
    (type: 'CREATE' | 'EDIT' | 'DELETE') => {
      dialogAction.current = type;
      let config = {
        ...tabLiteBasicConfiguration(
          userData?.currentStore!,
          customer!,
          userData?.language!,
        ),
      };

      if (type !== 'CREATE') {
        config.appointmentId = appointment?.id;
        if (type === 'EDIT') {
          config.updateAppointment = true;
        } else {
          config.cancelAppointment = true;
        }
      }

      setShowBookingDialog(true);
      setTimeout(() => {
        console.log('TabLite configuration: ', JSON.stringify(config));
        const tabLite = window.TabLt.tabWidget.new(config);
        tabLite.render();
      }, 100);
    },
    [appointment?.id, customer, userData?.currentStore, userData?.language],
  );

  const renderButtons = useMemo(() => {
    const buttons = [
      <ScheduledAppointments
        disabled={!taskIsEditable}
        key="searchAppointmentBtn"
        q={taskHeaderData?.email}
        storeId={taskHeaderData?.storeId!}
        taskId={taskId}
        banner={taskHeaderData?.banner!}
        country={taskHeaderData?.country!}
        onConfirm={createTaskAppointment}
        children={
          <Button
            disabled={!taskIsEditable}
            variant="basePrimary"
            endIcon={<IconSVG icon="search" size={16} />}>
            {getTranslationWithValue(0, 'noAppointment.searchBtn')}
          </Button>
        }
      />,
    ];

    if (
      configuration?.fieldAcceptedValue?.TAB_LITE ===
      APPOINTMENT_SETTINGS.READ_AND_WRITE
    ) {
      buttons.push(
        <Button
          key="scheduleAppointmentBtn"
          variant="basePrimary"
          disabled={!taskIsEditable}
          endIcon={<IconSVG icon="calendar" size={16} />}
          {...(taskIsEditable && {onClick: () => openTabLite('CREATE')})}>
          {getTranslationWithValue(0, 'noAppointment.scheduleBtn')}
        </Button>,
      );
    }

    return buttons;
  }, [
    configuration?.fieldAcceptedValue?.TAB_LITE,
    createTaskAppointment,
    getTranslationWithValue,
    openTabLite,
    taskHeaderData?.banner,
    taskHeaderData?.country,
    taskHeaderData?.email,
    taskHeaderData?.storeId,
    taskId,
    taskIsEditable,
  ]);

  const renderContent = useMemo(() => {
    if (!appointment) {
      return (
        <Stack sx={styles.emptyAppointmentContainer}>
          <Stack direction="column" gap={0.8}>
            <Headline5 heavy>
              {getTranslationWithValue(
                0,
                `noAppointment.${
                  feedbackStatus === FEEDBACK_STATUS.CONTACTED_POSITIVE
                    ? `${feedbackStatus}.`
                    : ''
                }title`,
              )}
            </Headline5>
            <TinyText whiteSpace="pre-line">
              {getTranslationWithValue(
                0,
                `noAppointment.${
                  feedbackStatus === FEEDBACK_STATUS.CONTACTED_POSITIVE
                    ? `${feedbackStatus}.`
                    : ''
                }subTitle`,
              )}
            </TinyText>
          </Stack>
          <Stack direction="row" gap={1.6} alignItems="center">
            {renderButtons}
          </Stack>
        </Stack>
      );
    }

    return (
      <AppointmentCard
        {...appointment}
        taskIsEditable={taskIsEditable}
        openTabLite={openTabLite}
        onDisassociation={disassociateTaskAppointment}
        configuration={configuration}
      />
    );
  }, [
    appointment,
    configuration,
    disassociateTaskAppointment,
    feedbackStatus,
    getTranslationWithValue,
    openTabLite,
    renderButtons,
    styles.emptyAppointmentContainer,
    taskIsEditable,
  ]);

  return (
    <>
      {renderContent}
      {showBookingDialog && (
        <Modal
          open={showBookingDialog}
          onClose={() => setShowBookingDialog(false)}
          dialogContent={
            <Box ref={modalRef} id="tabLite" sx={styles.tabLite} />
          }
        />
      )}
    </>
  );
};

export default React.memo(FeedbackAppointment);
