import React, {useMemo, useState, useCallback} from 'react';
import {Trans} from 'react-i18next';
import {RxPlusCircled} from 'react-icons/rx';

import {Stack, Button, Box} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {IconSVG} from '@/atoms/IconSVG';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {NoteInput} from '@/atoms/Note';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useStyles} from '@/organisms/Feedback/FeedbackNotes/styles';
import {GetFeedbackResponseV2} from '@/store/feedback/v2';

interface FeedbackNotesProps {
  currentNote: GetFeedbackResponseV2['note'];
  updatedOn: GetFeedbackResponseV2['updatedNote'];
  onNoteChange: (note: string | null) => unknown;
  taskIsEditable: boolean;
}

const FeedbackNotes = ({
  currentNote,
  updatedOn,
  onNoteChange,
  taskIsEditable,
}: FeedbackNotesProps) => {
  const styles = useStyles();
  const {getTranslationWithValue} = useBaseTranslation('FeedbackBox.NOTE');
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showDeleteNoteModal, setShowDeleteNoteModal] =
    useState<boolean>(false);

  /**
   * @brief Toggle note modal
   * @note This function is used to toggle the note modal
   */
  const toggleNoteModal = useCallback((value?: boolean) => {
    setShowNoteModal(prev => (value !== undefined ? value : !prev));
  }, []);

  /**
   * @brief Toggle delete note modal
   * @note This function is used to toggle the delete note modal
   */
  const toggleDeleteNoteModal = useCallback((value?: boolean) => {
    setShowDeleteNoteModal(prev => (value !== undefined ? value : !prev));
  }, []);

  /**
   * @brief Label value
   * @note This variable is used to set the label and value of the notes section
   */
  const labelValue = useMemo(() => {
    if (!!currentNote) {
      return {
        label: updatedOn
          ? getTranslationWithValue(0, 'noteLabel', {
              updatedOn: dayjs(updatedOn).utc().format('LL').toString(),
            })
          : '',
        value: getTranslationWithValue(0, 'noteValue'),
      };
    }

    return {
      label: getTranslationWithValue(0, 'noNoteLabel'),
      value: getTranslationWithValue(0, 'noNoteValue'),
    };
  }, [currentNote, getTranslationWithValue, updatedOn]);

  /**
   * @brief Note modal title
   * @note This variable is used to set the title of the note modal based on the current note
   */
  const noteModalTitle = useMemo(() => {
    if (!!currentNote) {
      return getTranslationWithValue(0, 'editNote');
    }
    return getTranslationWithValue(0, 'addNote');
  }, [currentNote, getTranslationWithValue]);

  return (
    <>
      <Stack sx={styles.notesContainer}>
        <Stack className="labelValueCtaContainer">
          <LabelValue label={labelValue?.label} value={labelValue?.value} />
          {currentNote === null && (
            <Button
              {...(taskIsEditable && {
                onClick: () => toggleNoteModal(true),
              })}
              disabled={!taskIsEditable}
              variant="baseSecondary"
              endIcon={<RxPlusCircled fontSize={16} />}>
              {getTranslationWithValue(0, 'addNote')}
            </Button>
          )}
        </Stack>
        {currentNote !== null && (
          <Stack className="singleNote">
            <BodyText sx={{wordBreak: 'break-word'}}>{currentNote}</BodyText>
            <Stack className="buttons">
              <Button
                disabled={!taskIsEditable}
                variant="baseSecondary"
                endIcon={<IconSVG icon="bucket" size={16} />}
                {...(taskIsEditable && {
                  onClick: () => toggleDeleteNoteModal(true),
                })}>
                {getTranslationWithValue(0, 'delete')}
              </Button>
              <Button
                disabled={!taskIsEditable}
                variant="baseSecondary"
                endIcon={<IconSVG icon="edit" size={16} />}
                {...(taskIsEditable && {
                  onClick: () => toggleNoteModal(true),
                })}>
                {getTranslationWithValue(0, 'edit')}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
      {showNoteModal && (
        <Modal
          open={showNoteModal}
          onClose={() => toggleNoteModal(false)}
          maxWidth={54}
          dialogTitle={noteModalTitle}
          dialogContent={
            <NoteModalContent
              toggleNoteModal={toggleNoteModal}
              currentNote={currentNote}
              onNoteChange={onNoteChange}
            />
          }
        />
      )}
      {showDeleteNoteModal && (
        <Modal
          open={showDeleteNoteModal}
          onClose={() => toggleDeleteNoteModal(false)}
          maxWidth={54}
          dialogTitle={getTranslationWithValue(0, 'deleteNote')}
          dialogContent={
            <DeleteNoteModalContent
              toggleDeleteNoteModal={toggleDeleteNoteModal}
              onNoteChange={onNoteChange}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(FeedbackNotes);

interface NoteModalProps
  extends Pick<FeedbackNotesProps, 'currentNote' | 'onNoteChange'> {
  toggleNoteModal: (value?: boolean) => unknown;
}

const NoteModalContent = React.memo(
  ({toggleNoteModal, currentNote, onNoteChange}: NoteModalProps) => {
    const {getTranslationWithValue} = useBaseTranslation('FeedbackBox.NOTE');
    const styles = useStyles();
    const [localNote, setLocalNote] = useState<string>(currentNote ?? '');

    /**
     * @brief Handle note save
     * @note This function is used to handle note save
     */
    const handleNoteSave = useCallback(() => {
      onNoteChange(localNote.trim());
      toggleNoteModal(false);
    }, [localNote, onNoteChange, toggleNoteModal]);

    /**
     * @brief Can submit
     * @note This variable is used to check if the note can be submitted
     */
    const canSubmit = useMemo(() => {
      const noteIsDifferent = localNote !== currentNote;
      const noteIsNotEmpty = localNote.trim().length > 0;
      return noteIsDifferent && noteIsNotEmpty;
    }, [currentNote, localNote]);

    /**
     * @brief Secondary button label
     * @note This variable is used to set the secondary button label based on the current note
     */
    const secondaryButtonLabel = useMemo(() => {
      return getTranslationWithValue(0, 'cancelAction');
    }, [getTranslationWithValue]);

    /**
     * @brief Primary button label
     * @note This variable is used to set the primary button label based on the current note
     */
    const primaryButtonLabel = useMemo(() => {
      return getTranslationWithValue(0, currentNote ? 'saveEdit' : 'addNote');
    }, [currentNote, getTranslationWithValue]);

    /**
     * @brief Primary button icon
     * @note This variable is used to set the primary button icon based on the current note
     */
    const primaryButtonIcon = useMemo(() => {
      return currentNote ? (
        <IconSVG icon="edit" size={16} color="white" />
      ) : (
        <RxPlusCircled fontSize={16} />
      );
    }, [currentNote]);

    return (
      <Stack sx={styles.addEditNoteContainer}>
        <NoteInput
          label={getTranslationWithValue(0, 'noteValue')}
          placeholder=""
          characterLimit={1000}
          size={6}
          onChange={value => setLocalNote(value)}
          value={localNote}
        />
        <Stack sx={styles.buttonContainer}>
          <Button
            className="buttonCta"
            variant="baseSecondary"
            fullWidth
            onClick={() => toggleNoteModal(false)}>
            {secondaryButtonLabel}
          </Button>
          <Button
            className="buttonCta"
            variant="basePrimary"
            fullWidth
            disabled={!canSubmit}
            endIcon={primaryButtonIcon}
            {...(canSubmit && {onClick: () => handleNoteSave()})}>
            {primaryButtonLabel}
          </Button>
        </Stack>
      </Stack>
    );
  },
);

NoteModalContent.displayName = 'NoteModalContent';

interface DeleteNoteModalContentProps
  extends Pick<FeedbackNotesProps, 'onNoteChange'> {
  toggleDeleteNoteModal: (value?: boolean) => unknown;
}

const DeleteNoteModalContent = React.memo(
  ({toggleDeleteNoteModal, onNoteChange}: DeleteNoteModalContentProps) => {
    const {getTranslationWithValue} = useBaseTranslation('FeedbackBox.NOTE');
    const styles = useStyles();
    const handleDeleteNote = useCallback(() => {
      onNoteChange(null);
      toggleDeleteNoteModal(false);
    }, [onNoteChange, toggleDeleteNoteModal]);

    return (
      <Stack sx={styles.deleteNoteContainer}>
        <Box className="noteText">
          <Trans
            i18nKey="FeedbackBox.NOTE.deleteNoteText"
            components={{
              wrapper: <BodyText />,
              heavy: <BodyText heavy />,
            }}
          />
        </Box>
        <Stack sx={styles.buttonContainer}>
          <Button
            className="buttonCta"
            variant="baseSecondary"
            fullWidth
            onClick={() => handleDeleteNote()}>
            {getTranslationWithValue(0, 'deleteNote')}
          </Button>
          <Button
            className="buttonCta"
            variant="basePrimary"
            fullWidth
            onClick={() => toggleDeleteNoteModal()}>
            {getTranslationWithValue(0, 'mantainNote')}
          </Button>
        </Stack>
      </Stack>
    );
  },
);

DeleteNoteModalContent.displayName = 'DeleteNoteModalContent';
