import React, {
  useCallback,
  useState,
  PropsWithChildren,
  useMemo,
  useEffect,
} from 'react';

import {Checkbox, Box, Stack, Tooltip, ClickAwayListener} from '@mui/material';

import theme from '../../../../themes/theme';
import {useStyle} from '../../../atoms/DataTable/styles';
import {IconSVG} from '../../../atoms/IconSVG';
import {Text} from '../../../atoms/Typography/Text';
import {BaseCellProps} from './interfaces';

const BaseCell = ({
  getValue,
  hasCheckbox,
  hasSorting,
  sx,
  title,
  tooltip,
}: BaseCellProps) => {
  const {baseCellContainer} = useStyle();

  const [itemsSelected, setItemsSelected] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      console.log('Sorting');
    },
    [],
  );

  const handleChange = useCallback((checked: boolean) => {
    setItemsSelected(checked);
  }, []);

  useEffect(() => {
    const table = document.getElementById('rewardsListTable');

    const hideTooltip = () => {
      setOpen(false);
    };

    if (!!tooltip && !!table) {
      table.addEventListener('scroll', hideTooltip);
    }

    return () => {
      if (!!table) {
        table.removeEventListener('scroll', hideTooltip);
      }
    };
  }, [tooltip]);

  const Wrapper = useCallback(
    ({children}: PropsWithChildren<any>) => {
      if (tooltip) {
        return (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              sx={{
                cursor: 'pointer',
              }}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltip}
              placement="top"
              arrow
              enterTouchDelay={0}
              slotProps={{
                tooltip: {
                  sx: {
                    width: 'auto',
                  },
                },
              }}>
              <Box>{children}</Box>
            </Tooltip>
          </ClickAwayListener>
        );
      }

      return <>{children}</>;
    },
    [open, tooltip],
  );

  const formattedValue = useMemo(() => {
    const val = getValue();

    if (typeof val === 'undefined' || val === null) {
      return '-';
    }

    return val;
  }, [getValue]);

  return (
    <Box
      sx={[baseCellContainer, sx]}
      onClick={hasSorting === true ? handleClick : () => null}>
      {hasCheckbox && (
        <Checkbox
          checked={itemsSelected}
          onChange={(_, checked) => handleChange(checked)}
          sx={{
            color: theme.palette.common.black,
            '&.Mui-checked': {
              color: theme.palette.common.black,
            },
          }}
        />
      )}

      <Stack>
        {title && (
          <Text variant="xxs" uppercase>
            {title}
          </Text>
        )}
        <Wrapper>
          <Text
            medium
            sx={{
              textDecoration: tooltip ? 'underline' : 'default',
            }}
            onClick={handleTooltipOpen}>
            {formattedValue}
          </Text>
        </Wrapper>
      </Stack>

      {hasSorting && (
        <IconSVG
          icon="reorder"
          size={20}
          color={theme.palette.common.black}
          className={'sorting'}
        />
      )}
    </Box>
  );
};

export default React.memo(BaseCell);
