import {Gender, UserStatus} from '@/definitions/user';
import {GetCustomerTierResponse} from '@/store/customerbalance';
import {GetPersonalInformationCustomersParams} from '@/store/personas';

export interface PostCustomerInfoBody {
  bannerSearch?: string;
  countrySearch?: string;
  q?: string;
}

export interface PostCustomerInfoResponse {
  cardNumber: string;
  cardActivationDate: string;
  cardStatus: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  brandId: string;
  country: string;
  language: string;
  currency: string;
  gender: Gender;
  userStatus: UserStatus;
  communications: boolean;
}

export interface PatchCostumerInfoBody {
  bannerSearch: string;
  countrySearch: string;
  cardNumberSearch: string;
  firstName: string;
  lastName: string;
  gender?: Gender;
  email: string;
  dayAndMonthOfBirth: string | null;
  yearOfBirth: number | null;
  communications: boolean;
}

export interface PatchCostumerInfoResponse {
  errorApi: boolean;
  errorDescription: string;
}

export interface RecoveryEmailBody {
  bannerSearch: string;
  countrySearch: string;
  email: string;
}

export interface RecoveryEmailResponse {
  cardNumber: string;
}

export interface PostCustomerinfoSubscribeBody {
  bannerSearch: string;
  countrySearch: string;
  firstName: string;
  lastName: string;
  gender?: Gender;
  email: string;
  dayAndMonthOfBirth?: string;
  yearOfBirth?: number;
  communications: boolean;
}

export interface PostLoyaltyTiersInfoBody
  extends Omit<GetPersonalInformationCustomersParams, 'singleCustomerId'> {}

export enum LOYALTY_STATUS {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export interface SingleLoyaltyTierInfo extends GetCustomerTierResponse {
  email: string;
  cardNumber: string;
  status: LOYALTY_STATUS;
}

export type PostLoyaltyTiersInfoResponse = Array<SingleLoyaltyTierInfo>;

export interface PostLoyaltyCustomerSubscriptionBody {
  email: string;
  storeId: string;
  bannerSearch: string;
  countrySearch: string;
  firstname: string;
  lastname: string;
}

export interface PostLoyaltyCustomerSubscriptionResponse {
  QRCode: string;
}
