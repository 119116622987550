import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetBenefitsParams,
  GetBenefitsResponse,
} from '@/store/benefit/interfaces';

export const useBenefit = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  // POST v1/benefits
  const getBenefits = useCallback(
    async ({...params}: GetBenefitsParams) => {
      try {
        const {data} = await api.post<GetBenefitsResponse>(
          getApiPath(APIS.V1_BENEFITS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getBenefits,
    }),
    [getBenefits],
  );
};
