import React, {useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {Stack, Box, Skeleton, CircularProgress} from '@mui/material';
import {useInfiniteQuery} from '@tanstack/react-query';

import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {useToast} from '@/hooks/toast';
import {useParamsAndClean} from '@/hooks/useParamsAndClean';
import {useParamsState} from '@/hooks/useParamsState';
import {useNavigation} from '@/navigation/useNavigation';
import {PurchaseListFilters} from '@/organisms/PurchaseListFilters';
import {Transaction} from '@/organisms/Transaction';
import {useStyles} from '@/pages/MyAccount/PurchaseList/styles';
import {useOrder, GetOrdersV2Params} from '@/store/order';
import {Base} from '@/templates/Base';
import {Colors} from '@/themes/variables';

const BASE_TRANSLATION_PATH = 'PurchaseList';

const PurchaseList = () => {
  const {t} = useTranslation();
  const {goBack} = useNavigation();
  const {showErrorToast} = useToast();
  const styles = useStyles();
  const {getOrdersV2} = useOrder();
  const {ref, inView} = useInView();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Purchase list',
    });
  }, []);

  const params = useParamsAndClean<
    Pick<GetOrdersV2Params, 'name' | 'email' | 'bannerSearch' | 'countrySearch'>
  >('name', 'email', 'bannerSearch', 'countrySearch');
  const {paramsState, setParamsState} = useParamsState<
    Omit<GetOrdersV2Params, 'email' | 'bannerSearch' | 'countrySearch'>
  >(
    {orderBy: 'DESC'},
    ['category', 'channel', 'customerId'],
    'q',
    'startDate',
    'endDate',
    'customerId',
    'channel',
    'category',
    'sortBy',
    'orderBy',
  );

  // Check if mandatory params are present
  const paramsRetrieved = useMemo(() => {
    return (
      Object.keys(params).length > 0 &&
      'name' in params &&
      'email' in params &&
      'bannerSearch' in params &&
      'countrySearch' in params
    );
  }, [params]);

  // Format filter for API
  const formattedFilters = useMemo<GetOrdersV2Params>(() => {
    return {
      email: paramsRetrieved ? params?.email : '',
      bannerSearch: paramsRetrieved ? params?.bannerSearch : '',
      countrySearch: paramsRetrieved ? params?.countrySearch : '',
      ...paramsState,
    };
  }, [
    params?.bannerSearch,
    params?.countrySearch,
    params?.email,
    paramsRetrieved,
    paramsState,
  ]);

  // Call order API
  const {
    data: OrdersPaginatedResponse,
    isLoading: isLoadingOrders,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['getOrdersV2', formattedFilters],
    ({pageParam = 1}) =>
      getOrdersV2({
        ...formattedFilters,
        page: pageParam,
        limit: 10,
      }),
    {
      retry: false,
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      onError: () => {
        showErrorToast(t('PurchaseHistory.ordersToast.error'));
      },
      getNextPageParam: ({metadataDTO: {page, total_pages}}) =>
        page >= total_pages ? undefined : page + 1,
    },
  );

  useEffect(() => {
    if (inView && !isLoadingOrders) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, isLoadingOrders]);

  // Merge infiniteQuery results into a single list of TransactionProps
  const OrdersResponse = useMemo(
    () => OrdersPaginatedResponse?.pages.flatMap(({data}) => data),
    [OrdersPaginatedResponse?.pages],
  );

  const renderTransactions = useMemo(() => {
    if (!OrdersResponse || isLoadingOrders) {
      return (
        <Skeleton
          className="loadingSkeleton"
          animation="wave"
          variant="rounded"
        />
      );
    }

    if (OrdersResponse && OrdersResponse.length > 0) {
      return (
        <>
          {OrdersResponse?.map((transaction, index) => {
            return (
              <Transaction
                country={params?.countrySearch}
                key={`${transaction?.transactionId}_${index}`}
                transaction={transaction}
              />
            );
          })}
          {hasNextPage && (
            <Stack
              ref={ref}
              width="100%"
              height={200}
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
        </>
      );
    }

    return <EmptyState paramsState={paramsState} />;
  }, [
    OrdersResponse,
    hasNextPage,
    isLoadingOrders,
    params?.countrySearch,
    paramsState,
    ref,
  ]);

  return (
    <Base>
      <Stack sx={styles.pageWrapper}>
        <Stack sx={styles.headerWrapper}>
          <Link
            linkVariant="dark"
            textVariant="body"
            href="#"
            handleOnClick={goBack}
            iconStart={<IconSVG icon="arrow_left_2" size={16} color="#000" />}>
            {t('Generic.ctas.back')}
          </Link>
          {paramsRetrieved && (
            <PurchaseListFilters
              isLoading={!OrdersResponse || isLoadingOrders}
              params={params}
              orderCount={OrdersResponse?.length}
              currentFilters={paramsState}
              updateFilters={setParamsState}
            />
          )}
        </Stack>
        <Box sx={styles.cardsListWrapper}>{renderTransactions}</Box>
      </Stack>
    </Base>
  );
};

export default React.memo(PurchaseList);

interface EmptyStateProps {
  paramsState: Omit<
    GetOrdersV2Params,
    'email' | 'bannerSearch' | 'countrySearch'
  >;
}

const EmptyState = React.memo(({paramsState}: EmptyStateProps) => {
  const {t} = useTranslation();

  const filteredParams = useMemo(
    () => Object.keys(paramsState).filter(k => k !== 'orderBy'),
    [paramsState],
  );

  const isFiltersSet = useMemo(
    () => filteredParams.length > 0,
    [filteredParams],
  );

  const isFreeSearchSet = useMemo(
    () => filteredParams.includes('q'),
    [filteredParams],
  );

  const isOtherFiltersSer = useMemo(
    () => filteredParams.length > 1,
    [filteredParams.length],
  );

  const emptyMessage = useMemo(() => {
    if (!isFiltersSet) {
      return t(`${BASE_TRANSLATION_PATH}.emptyState.notFound`);
    }

    if (!isFreeSearchSet) {
      return t(`${BASE_TRANSLATION_PATH}.emptyState.notFoundFilter`);
    }

    if (!isOtherFiltersSer) {
      return t(`${BASE_TRANSLATION_PATH}.emptyState.notFoundOrderId`, {
        orderId: paramsState.q,
      });
    }

    return t(`${BASE_TRANSLATION_PATH}.emptyState.notFoundMixed`, {
      orderId: paramsState.q,
    });
  }, [isFiltersSet, isFreeSearchSet, isOtherFiltersSer, paramsState.q, t]);

  return (
    <Card className="emptyStateCard">
      <Stack
        display="grid"
        sx={{placeItems: 'center'}}
        height="100%"
        textAlign="center">
        <HeadlineText superHeavy color={Colors.GreyText} whiteSpace="pre-line">
          {emptyMessage}
        </HeadlineText>
      </Stack>
    </Card>
  );
});

EmptyState.displayName = 'EmptyState';
