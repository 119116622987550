import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useScrollPosition} from '@/hooks/useScrollPosition/useScrollPosition';
import {sendGaEvent, GaEventCategory} from '@/lib/utils';
import {MyAccountSearchBar} from '@/molecules/MyAccountSearchBar';
import {useNavigation} from '@/navigation/useNavigation';
import {useAuthentication} from '@/store/authentication';
import {
  usePersonas,
  GetPersonasParams,
  GetPersonasResponse,
} from '@/store/personas';
import {Base} from '@/templates/Base';
import {Colors} from '@/themes/variables';

import {useStyle} from './styles';

const MyAccount = () => {
  const {deleteScrollPositionById} = useScrollPosition();
  const styles = useStyle();
  const {t} = useTranslation();
  const {goToClientResults} = useNavigation();
  const {
    getAuth,
    userData,
    isStoreAssociate,
    isStoreManager,
    isAreaManager,
    isContactCenterSupervisor,
    isContactCenterAgent,
  } = useAuthentication();
  const {getPersonas, getBannerCountry} = usePersonas();
  const {showErrorToast} = useToast();
  const currentStoreId = useRef<string>(userData?.currentStore?.storeId!);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'My account',
    });
  }, []);

  useEffect(() => {
    deleteScrollPositionById('clientResultsContainer');
  }, [deleteScrollPositionById]);

  const [payloadState, setPayloadState] = useState<{
    q: string;
    onlyInThisStore: string | undefined;
    bannerSearch: string;
    countrySearch: string;
    tab: string;
  }>({
    q: '',
    bannerSearch: userData?.banner!,
    countrySearch: userData?.country!,
    onlyInThisStore: undefined,
    tab: 'CUSTOMER_DATA',
  });

  const [localState, setLocalState] = useState<{
    q: string;
    onlyInThisStore: string | undefined;
    bannerSearch: string;
    countrySearch: string;
    tab: string;
  }>(payloadState);

  const [toggleSearch, setToggleSearch] = useState<boolean>(false);

  const resetState = useCallback(() => {
    setPayloadState(prevState => ({
      ...prevState,
      q: '',
      bannerSearch: userData?.banner!,
      countrySearch: userData?.country!,
      onlyInThisStore: undefined,
      tab: 'CUSTOMER_DATA',
    }));

    setLocalState(prevState => ({
      ...prevState,
      q: '',
      bannerSearch: userData?.banner!,
      countrySearch: userData?.country!,
      onlyInThisStore: undefined,
      tab: 'CUSTOMER_DATA',
    }));

    setToggleSearch(false);
    currentStoreId.current = userData?.currentStore?.storeId!;
  }, [userData?.banner, userData?.country, userData?.currentStore?.storeId]);

  useEffect(() => {
    if (currentStoreId?.current !== userData?.currentStore?.storeId) {
      resetState();
    }
  }, [resetState, userData?.currentStore?.storeId]);

  const isSAorSM = useMemo(() => {
    return isStoreAssociate || isStoreManager;
  }, [isStoreAssociate, isStoreManager]);

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  const isAMorCC = useMemo(() => {
    return isAreaManager || isCC;
  }, [isAreaManager, isCC]);

  useEffect(() => {
    if (userData?.banner !== localState?.bannerSearch && !isCC) {
      setLocalState(prevState => ({
        ...prevState,
        bannerSearch: userData?.banner!,
      }));
    }

    if (userData?.country !== localState?.countrySearch && !isCC) {
      setLocalState(prevState => ({
        ...prevState,
        countrySearch: userData?.country!,
      }));
    }
  }, [
    isCC,
    localState?.bannerSearch,
    localState?.countrySearch,
    userData?.banner,
    userData?.country,
  ]);

  const personasPayload = useMemo<GetPersonasParams>(() => {
    return {
      q: payloadState.q,
      bannerSearch: payloadState.bannerSearch,
      countrySearch: payloadState.countrySearch,
      ...(!!payloadState.onlyInThisStore && {
        storeId: userData?.currentStore?.storeId,
      }),
      searchType: payloadState.tab,
    };
  }, [
    payloadState.bannerSearch,
    payloadState.countrySearch,
    payloadState.onlyInThisStore,
    payloadState.q,
    userData?.currentStore?.storeId,
    payloadState.tab,
  ]);

  const {data: Personas, isFetching: PersonasLoading} =
    useQuery<GetPersonasResponse>(
      ['customer', personasPayload, userData?.currentStore?.storeId],
      {
        queryFn: () => getPersonas(personasPayload),
        retry: false,
        refetchOnWindowFocus: false,
        enabled: toggleSearch,
        onSuccess: async data => {
          if (data?.data?.length > 0) {
            goToClientResults(
              localState?.q,
              localState?.bannerSearch,
              localState?.countrySearch,
              localState?.tab,
              localState?.onlyInThisStore,
            );
          }
        },
        onError: () => {
          showErrorToast(t('ClientResults.personasToast.error'));
        },
        onSettled: async () => {
          if (isSAorSM) {
            sendGaEvent(
              GaEventCategory.SEARCH,
              'only_in_this_store',
              !!payloadState.onlyInThisStore
                ? userData?.currentStore?.storeId!
                : 'FALSE',
            );
          }
          setToggleSearch(false);
          await refetchUserData();
        },
      },
    );

  const {refetch: refetchUserData} = useQuery({
    queryKey: ['profile'],
    queryFn: () => getAuth(),
    enabled: false,
    retry: false,
  });

  const handleToggleOnlyInThisStore = useCallback(
    (_e: any, checked: boolean) => {
      setLocalState(prevState => ({
        ...prevState,
        onlyInThisStore: checked ? userData?.currentStore?.storeId : undefined,
      }));
    },
    [userData?.currentStore?.storeId],
  );

  const {data: BannerCountry} = useQuery(
    ['getBannerCountry', localState?.bannerSearch],
    () => getBannerCountry(localState?.bannerSearch),
    {
      retry: false,
      enabled: isCC,
      refetchOnWindowFocus: false,
      onSuccess: ({countries}) => {
        if (
          !countries?.find(
            country => country.name === localState?.countrySearch,
          )
        ) {
          setLocalState(prevState => ({
            ...prevState,
            countrySearch: countries[0]?.name || prevState.countrySearch,
          }));
        }
      },
    },
  );

  const searchBarProps = useMemo(
    () => ({
      freeSearch: localState?.q,
      onReset: () => {
        setLocalState(prevState => ({...prevState, q: ''}));
      },
      onFreeSearchChange: (newValue: string) =>
        setLocalState(prevState => ({...prevState, q: newValue})),
      onTabChange: (newValue: string) =>
        setLocalState(prevState => ({
          ...prevState,
          q: payloadState?.tab === newValue ? payloadState?.q : '',
          tab: newValue,
        })),
      onSubmit: () => {
        setPayloadState(localState);
        setToggleSearch(true);
      },
      onlyInThisStoreVisible: isSAorSM && localState?.tab !== 'LOYALTY_CARD',
      onlyInThisStoreValue: !!localState?.onlyInThisStore,
      handleToggleOnlyInThisStore,
      showPickers: isAMorCC,
      disablePickers: isAreaManager,
      selectedBanner: localState?.bannerSearch,
      setSelectedBanner: (newBanner: string) =>
        setLocalState(prevState => ({
          ...prevState,
          bannerSearch: newBanner,
        })),
      selectedCountry: localState?.countrySearch,
      setSelectedCountry: (newCountry: string) =>
        setLocalState(prevState => ({
          ...prevState,
          countrySearch: newCountry,
        })),
      loading: PersonasLoading,
      selectedTabParam: localState?.tab,
    }),
    [
      PersonasLoading,
      handleToggleOnlyInThisStore,
      isAMorCC,
      isAreaManager,
      isSAorSM,
      localState,
      payloadState?.q,
      payloadState?.tab,
    ],
  );

  return (
    <Base
      noContainer={
        <Box sx={styles.container}>
          <Text
            variant="h2"
            medium
            color={Colors.White}
            marginTop="38px"
            marginBottom="26px">
            {t('MyAccount.Search.title')}
          </Text>
        </Box>
      }>
      <Box sx={styles.content}>
        <Text variant="headline2" superHeavy>
          {t('MyAccount.Search.subtitle')}
        </Text>
        <MyAccountSearchBar
          {...searchBarProps}
          personas={Personas}
          banners={
            isCC ? BannerCountry?.banners : [{name: localState?.bannerSearch}]
          }
          countries={
            isCC
              ? BannerCountry?.countries
              : [{name: localState?.countrySearch}]
          }
        />
      </Box>
    </Base>
  );
};

export default React.memo(MyAccount);
