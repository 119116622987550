import React, {PropsWithChildren} from 'react';

import {Box} from '@mui/material';

import {useStyles} from '@/atoms/LineText/styles';

interface LineTextProps extends PropsWithChildren {}

const LineText = ({children}: LineTextProps) => {
  const styles = useStyles();

  return (
    <Box sx={styles.lineWithText}>
      {React.cloneElement(children as any, {className: 'lineText'})}
    </Box>
  );
};

export default React.memo(LineText);
