import React, {useState, useMemo, useRef, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Skeleton,
  Stack,
  Button,
  CircularProgress,
  Divider,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {Dialog} from '@/atoms/Dialog';
import {HeadlineText} from '@/atoms/HeadlineText';
import {LabelValue} from '@/atoms/LabelValue';
import {Link} from '@/atoms/Link';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {useNavigation} from '@/navigation/useNavigation';
import {CardTag} from '@/organisms/Card';
import {useStyles} from '@/organisms/LoyaltyBox/styles';
import {useAuthentication} from '@/store/authentication';
import {useCustomerInfo} from '@/store/customersInfo';
import {
  PostCustomerinfoSubscribeBody,
  PostLoyaltyTiersInfoResponse,
  SingleLoyaltyTierInfo,
  LOYALTY_STATUS,
  PostLoyaltyCustomerSubscriptionBody,
} from '@/store/customersInfo/interfaces.d';
import {CustomerResponse} from '@/store/personas';
import {Colors} from '@/themes/variables';

export interface LoyaltyBoxProps {
  response?: PostLoyaltyTiersInfoResponse | null;
  loading: boolean;
  customer?: CustomerResponse;
  subscribeCB: (payload: PostCustomerinfoSubscribeBody) => unknown;
  bannerSearch: string;
  countrySearch: string;
  origin: 'customer' | 'task';
}

const BASE_TRANSLATION = 'LoyaltyBox';

const LoyaltyBox = ({
  response,
  loading,
  bannerSearch,
  countrySearch,
  origin,
  customer,
}: LoyaltyBoxProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const {postLoyaltyCustomerSubscription} = useCustomerInfo();
  const [isLoyaltyModalOpen, setIsLoyaltyModalOpen] = useState<boolean>(false);
  const {t} = useTranslation();
  const styles = useStyles();
  const {userData, isContactCenterSupervisor, isContactCenterAgent} =
    useAuthentication();

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  /**
   * @brief Last purchases payload
   * @note This payload is used to get last purchases
   */
  const loyaltyCustomerSubscriptionBody =
    useMemo<PostLoyaltyCustomerSubscriptionBody>(() => {
      return {
        email: customer?.email || '',
        bannerSearch,
        countrySearch,
        storeId: userData?.currentStore?.storeId || '',
        firstname: customer?.firstName || '',
        lastname: customer?.lastName || '',
      };
    }, [
      bannerSearch,
      countrySearch,
      customer?.email,
      customer?.firstName,
      customer?.lastName,
      userData?.currentStore?.storeId,
    ]);

  /**
   * @brief Get last purchases
   * @note This query is used to get last purchases
   */
  const {data: LoyaltyCustomerSubscriptionData} = useQuery(
    ['postLoyaltyCustomerSubscription', loyaltyCustomerSubscriptionBody],
    () => postLoyaltyCustomerSubscription(loyaltyCustomerSubscriptionBody),
    {
      enabled: !!customer?.email,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * State to manage the accordion open/close
   */
  const [isOpen, setIsOpen] = useState<boolean>(true);

  /**
   * Check if the accordion should be disabled
   */
  const disableClick = useMemo(() => {
    return typeof response === 'undefined' || response === null || loading;
  }, [loading, response]);

  const renderContent = useMemo(() => {
    if (typeof response === 'undefined' || loading) {
      return <Skeleton height="80px" animation="wave" variant="rounded" />;
    }

    if (!isOpen) return <Box />;

    if (response === null) {
      return (
        <Stack direction="column" gap={1.6} alignItems="center">
          <BodyText>{getTranslationWithValue(0, 'emptyState.title')}</BodyText>
          {/* Commented because is not compliant */}
          {/* {!isCC && ( */}
          {/*   <Button */}
          {/*     variant="primary" */}
          {/*     onClick={() => setIsLoyaltyModalOpen(true)}> */}
          {/*     {getTranslationWithValue(0, 'emptyState.cta')} */}
          {/*   </Button> */}
          {/* )} */}
        </Stack>
      );
    }

    return (
      <LoyaltyList
        boxes={response}
        bannerSearch={bannerSearch}
        countrySearch={countrySearch}
        origin={origin}
      />
    );
  }, [
    isCC,
    bannerSearch,
    countrySearch,
    getTranslationWithValue,
    isOpen,
    loading,
    origin,
    response,
  ]);

  return (
    <>
      <Card>
        <CustomAccordion
          bottomContent
          labelAlign="center"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disableClick={disableClick}
          hideAccordionLabel={disableClick}
          accordionTitle={
            <HeadlineText superHeavy>
              {getTranslationWithValue(0, 'title')}
            </HeadlineText>
          }
          expandedContent={() => renderContent}
        />
      </Card>
      {isLoyaltyModalOpen ? (
        <Dialog
          open={isLoyaltyModalOpen}
          sx={styles.dialog}
          onClose={() => setIsLoyaltyModalOpen(false)}
          closeIcon="close"
          dialogTitle={t(`${BASE_TRANSLATION}.modal.title`)}
          titleUppercase={false}
          dialogContent={
            <Stack direction="column" gap={3.2} width="100%">
              <BodyText sx={{whiteSpace: 'pre-line'}}>
                {t(`${BASE_TRANSLATION}.modal.subTitle`)}
              </BodyText>
              <Stack direction="row" justifyContent="center">
                <img
                  src={LoyaltyCustomerSubscriptionData?.QRCode}
                  alt="QRCode"
                />
              </Stack>
            </Stack>
          }
          dialogActions={
            <Button
              variant="secondary"
              onClick={() => setIsLoyaltyModalOpen(false)}>
              {t(`${BASE_TRANSLATION}.modal.close`)}
            </Button>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(LoyaltyBox);

interface LoyaltyListProps
  extends Pick<LoyaltyBoxProps, 'bannerSearch' | 'countrySearch' | 'origin'> {
  boxes: PostLoyaltyTiersInfoResponse;
}

const LoyaltyList = React.memo(
  ({boxes, ...bannerCountryOrigin}: LoyaltyListProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const styles = useStyles();
    /**
     * State to manage the offset of the boxes
     */
    const [offset, setOffset] = useState<number>(3);

    /**
     * Ref to the box list container
     */
    const boxListContainerRef = useRef<HTMLDivElement | null>(null);

    /**
     * Ref to the boxes
     */
    const boxRefs = useRef<Array<HTMLDivElement>>([]);

    const visibleBoxes = useMemo(() => {
      return boxes.slice(0, offset);
    }, [boxes, offset]);

    const remainingBoxes = useMemo(() => {
      return boxes.length - offset;
    }, [boxes.length, offset]);

    /**
     * Set the max height of the notification list container so that max 6 notifications are visible
     */
    useEffect(() => {
      if (boxListContainerRef.current && boxRefs.current) {
        if (boxRefs.current.length === 6) {
          boxListContainerRef.current.style.maxHeight = `${boxListContainerRef.current.clientHeight}px`;
        }
      }
    }, [boxRefs, visibleBoxes]);

    /**
     * Get the next offset based on the remaining boxes
     */
    const nextOffset = useMemo(() => {
      return remainingBoxes >= 3 ? offset + 3 : offset + remainingBoxes;
    }, [offset, remainingBoxes]);

    /**
     * Show more boxes
     */
    const showMoreBoxes = useCallback(() => {
      setOffset(nextOffset);
    }, [nextOffset]);

    /**
     * Scroll to the bottom of the box list container when new boxes are added
     */
    useEffect(() => {
      if (boxListContainerRef.current) {
        boxListContainerRef.current.scrollTo({
          top: boxListContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, [visibleBoxes]);

    return (
      <Stack sx={styles.loyaltyList}>
        <Stack
          ref={boxListContainerRef}
          className="boxList"
          divider={<Divider orientation="horizontal" />}>
          {visibleBoxes?.map((tierInfo, index) => {
            return (
              <Box
                ref={(el: HTMLDivElement) => (boxRefs.current[index] = el)}
                key={tierInfo?.cardNumber}>
                <LoyaltyGraph tierInfo={tierInfo} {...bannerCountryOrigin} />
              </Box>
            );
          })}
        </Stack>
        {remainingBoxes > 0 && (
          <Box margin="0 auto">
            <Button variant="baseSecondary" onClick={showMoreBoxes}>
              {getTranslationWithValue(0, 'showMore', {
                remaining: remainingBoxes >= 3 ? 3 : remainingBoxes,
                total: remainingBoxes,
              })}
            </Button>
          </Box>
        )}
      </Stack>
    );
  },
);

LoyaltyList.displayName = 'LoyaltyList';

interface LoyaltyGraphProps
  extends Pick<LoyaltyBoxProps, 'bannerSearch' | 'countrySearch' | 'origin'> {
  tierInfo: SingleLoyaltyTierInfo;
}

const LoyaltyGraph = React.memo(
  ({tierInfo, bannerSearch, countrySearch, origin}: LoyaltyGraphProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const {goToLoyaltyProfile} = useNavigation();
    const {email, cardNumber, currentTier, nextTierConfig, status} = tierInfo;

    const currentValue = useMemo(() => {
      if (status === LOYALTY_STATUS.SUSPENDED) {
        return 0;
      }

      return currentTier?.currentBalanceValue;
    }, [currentTier?.currentBalanceValue, status]);

    const totalValue = useMemo(() => {
      if (status === LOYALTY_STATUS.SUSPENDED) {
        return 0;
      }

      return nextTierConfig?.minThreshold;
    }, [nextTierConfig?.minThreshold, status]);

    return (
      <Stack direction="column" gap={1.6}>
        <Stack
          display="grid"
          gridTemplateColumns="1fr max-content"
          alignItems="center">
          <BodyText heavy>{email ?? '-'}</BodyText>
          <Link
            position="center"
            linkVariant="dark"
            textVariant="body"
            href="#"
            handleOnClick={() =>
              goToLoyaltyProfile(
                cardNumber,
                bannerSearch,
                countrySearch,
                origin,
              )
            }>
            {getTranslationWithValue(0, 'graph.viewDetails')}
          </Link>
        </Stack>
        <Stack
          display="grid"
          gridTemplateColumns={
            status === LOYALTY_STATUS.ACTIVE ? 'max-content 1fr' : '1fr'
          }
          alignItems="center"
          gap={1.6}>
          {status === LOYALTY_STATUS.ACTIVE && (
            <Box className="progressContainer">
              <CircularProgress
                className="absolute"
                sx={{
                  color: Colors.GreyBackground2,
                }}
                variant="determinate"
                value={100}
                size={110}
                thickness={4.5}
              />

              <CircularProgress
                sx={{
                  strokeLinecap: 'round',
                  color: Colors.TertiaryDark,
                }}
                variant="determinate"
                value={
                  (currentValue * 100) /
                  (currentTier?.pointsToNextTier > 0
                    ? totalValue
                    : currentValue)
                }
                size={110}
                thickness={4.5}
              />
              <Stack
                className="absolute"
                alignItems="center"
                justifyContent="center">
                <Stack direction="column" alignItems="center" gap={0.2}>
                  <BodyText medium>{currentValue}</BodyText>
                  <XSText>/{totalValue}</XSText>
                  <XSText color={Colors.GreyText}>
                    {getTranslationWithValue(0, 'graph.points')}
                  </XSText>
                </Stack>
              </Stack>
            </Box>
          )}
          <Stack
            gap={1.6}
            {...(status === LOYALTY_STATUS.ACTIVE
              ? {
                  direction: 'column',
                  divider: <Divider orientation={'horizontal'} />,
                }
              : {
                  direction: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                })}>
            <LabelValue label="Card number" value={cardNumber} />
            {status === LOYALTY_STATUS.ACTIVE ? (
              <LabelValue
                label="Tier"
                value={`${tierInfo?.currentTier?.configuration?.tierCode} - ${tierInfo?.currentTier?.configuration?.tierName}`}
              />
            ) : (
              <CardTag variant="status" value={status} />
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

LoyaltyGraph.displayName = 'LoyaltyGraph';
