import React, {useMemo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {Stack, Skeleton} from '@mui/material';
import {GridRowId} from '@mui/x-data-grid';
import {useQuery, useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {Avatar} from '@/atoms/Avatar';
import {Badge} from '@/atoms/Badge';
import {BodyText} from '@/atoms/BodyText';
import Headline3 from '@/atoms/Headline3';
import {LabelValue} from '@/atoms/LabelValue';
import {useToast} from '@/hooks/toast';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {formatToAssignee} from '@/lib/utils';
import {Assignee} from '@/molecules/Assignee';
import {TaskHeaderDetailsProps} from '@/organisms/TaskHeaderDetails/interfaces.d';
import {useStyles} from '@/organisms/TaskHeaderDetails/styles';
import {useAuthentication} from '@/store/authentication';
import {useMembersV2} from '@/store/members/v2';
import {useTasks} from '@/store/tasks';
import {SingleTaskAssignee} from '@/store/tasks/types';

const BASE_TRANSLATION = 'TaskHeaderDetails';

const TaskHeaderDetails = ({
  taskHeaderData,
  onAssignRefetch,
  feedbackChanged,
  changeLocalAssignee,
}: TaskHeaderDetailsProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const styles = useStyles();
  const {userData} = useAuthentication();
  const {assignTaskToMe, assignTask} = useTasks();
  const {taskId} = useParams<{taskId: string}>();
  const {showSuccessToastAssignTask} = useToast();

  const {getMembers} = useMembersV2();

  /**
   * @brief Check if response is present
   * @note This variable is used to check if response is present
   */
  const hasResponse = useMemo(() => {
    return typeof taskHeaderData !== 'undefined';
  }, [taskHeaderData]);

  /**
   * @brief Assign task to me
   * @note This function is used to assign task to me
   */
  const {mutate: AssignTaskToMe} = useMutation(
    (params: {storeId: number | string; taskIds: GridRowId[]}) =>
      assignTaskToMe(params?.storeId, params?.taskIds),
    {
      onSuccess: async () => {
        showSuccessToastAssign();
        await onAssignRefetch();
      },
    },
  );

  /**
   * @brief Assign task
   * @note This function is used to assign task
   */
  const {mutate: AssignTask} = useMutation(
    (params: {
      storeId: number | string;
      taskIds: GridRowId[] | null;
      assigneId: number | string;
    }) => assignTask(params?.storeId, params?.taskIds, params?.assigneId),
    {
      onSuccess: async () => {
        showSuccessToastAssign();
        await onAssignRefetch();
      },
    },
  );

  /**
   * @brief Handle assign task
   * @note This function is used to handle assign task
   */
  const handleAssignTask = useCallback(
    (newAssignee: SingleTaskAssignee) => {
      const isMe = newAssignee?.luxId === userData?.luxId;

      const payload = {
        storeId: taskHeaderData?.storeId!,
        taskIds: [taskId],
      };

      if (isMe) {
        AssignTaskToMe(payload);
      } else {
        AssignTask({...payload, assigneId: newAssignee?.id!});
      }
    },
    [
      AssignTask,
      AssignTaskToMe,
      taskHeaderData?.storeId,
      taskId,
      userData?.luxId,
    ],
  );

  /**
   * @brief Show success toast assign
   * @note This function is used to show success toast assign
   */
  const showSuccessToastAssign = useCallback(() => {
    showSuccessToastAssignTask(getTranslationWithValue(0, 'assignSuccess'));
  }, [getTranslationWithValue, showSuccessToastAssignTask]);

  /**
   * @brief Get members
   * @note This function is used to get members
   */
  const {data: Members} = useQuery(
    [
      'members',
      taskHeaderData?.storeId,
      taskHeaderData?.banner,
      taskHeaderData?.country,
    ],
    () =>
      getMembers({
        storeId: taskHeaderData?.storeId!,
        includeMe: true,
        banner: taskHeaderData?.banner!,
        country: taskHeaderData?.country!,
      }),
    {
      enabled: hasResponse,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  /**
   * @brief Me as assignee
   * @note This variable is used to get me as assignee
   */
  const meAsAssignee = useMemo(() => {
    const meIndex = Members?.data?.findIndex(
      member => member?.luxId === userData?.luxId,
    );
    if (typeof meIndex !== 'undefined' && meIndex !== -1) {
      return formatToAssignee(Members?.data[meIndex]!);
    }
    return undefined;
  }, [Members?.data, userData?.luxId]);

  /**
   * @brief Current assignee
   * @note This variable is used to get current assignee
   */
  const currentAssignee = useMemo(() => {
    return taskHeaderData?.assignee;
  }, [taskHeaderData?.assignee]);

  /**
   * @brief Check assignment
   * @note This function is used to check assignment when feedback state changes
   */
  const checkAssignment = useCallback(() => {
    if (!feedbackChanged) {
      return;
    }

    if (!currentAssignee && meAsAssignee) {
      changeLocalAssignee(meAsAssignee);
    }
  }, [changeLocalAssignee, currentAssignee, feedbackChanged, meAsAssignee]);

  /**
   * @brief Check assignment
   * @note This function is used to check assignment when feedback state changes
   */
  useEffect(() => {
    checkAssignment();
  }, [checkAssignment]);

  /**
   * @brief Skeleton
   * @note This function is used to render skeleton if response is not present
   */
  if (!hasResponse)
    return <Skeleton height="60px" animation="wave" variant="rounded" />;

  return (
    <Stack sx={styles.headerContainer}>
      <Stack sx={styles.avatarContainer}>
        <Avatar
          big
          name={taskHeaderData?.firstName}
          surname={taskHeaderData?.lastName}
          variant="dark"
        />
        <Stack className="nameCustomerId">
          <Headline3 superHeavy>
            {taskHeaderData?.firstName} {taskHeaderData?.lastName}
          </Headline3>
          {!!taskHeaderData?.customerId && (
            <BodyText className="customerId">
              {taskHeaderData?.customerId}
            </BodyText>
          )}
        </Stack>
      </Stack>
      <Stack sx={styles.taskInfoContainer}>
        <LabelValue
          className="campaignAssignContainer"
          label={getTranslationWithValue(0, 'assignee')}
          value={
            <Assignee
              taskStatus={taskHeaderData?.taskStatus}
              assignee={taskHeaderData?.assignee}
              userData={userData}
              storeId={taskHeaderData?.storeId}
              isTaskEditable={taskHeaderData?.taskIsEditable!}
              meAsAssignee={meAsAssignee}
              onChange={handleAssignTask}
            />
          }
        />
        <LabelValue
          className="campaignBadgeContainer"
          label={getTranslationWithValue(0, 'campaign')}
          value={
            <Badge
              color={taskHeaderData?.campaignColor}
              badgeLabel={taskHeaderData?.campaignName}
            />
          }
        />
        <LabelValue
          className="campaignEndDateContainer"
          label={getTranslationWithValue(0, 'campaignEndDate')}
          value={
            <BodyText medium>
              {!!taskHeaderData?.campaignEndDate
                ? dayjs(taskHeaderData?.campaignEndDate).utc().format('ll')
                : '-'}
            </BodyText>
          }
        />
      </Stack>
    </Stack>
  );
};

export default React.memo(TaskHeaderDetails);
