import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ColumnDef} from '@tanstack/react-table';

import {Data} from '@/atoms/DataTable/interfaces';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import IconsCell from '@/molecules/TableCell/IconsCell/IconsCell';
import {LOYALTY_CHANNEL} from '@/store/benefits/interfaces.d';

import {
  BaseCell,
  ButtonCell,
  DateCell,
  StatusCell,
} from '../../molecules/TableCell';
import {useStyle} from './styles';

export const useColumnDef = () => {
  const {t} = useTranslation();
  const {chip} = useStyle();

  /* TODO: correct accessorKey based on api*/
  const MissionListColumnDef = useMemo<ColumnDef<Data>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => t('CustomerProfile.tables.missionList.header.id'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'name',
        header: () => t('CustomerProfile.tables.missionList.header.mission'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'description',
        header: () =>
          t('CustomerProfile.tables.missionList.header.description'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'status',
        header: () => t('CustomerProfile.tables.missionList.header.status'),
        cell: info => (
          <StatusCell {...info} sx={chip(info.row.original.statusColor)} />
        ),
      },
      {
        accessorKey: 'channel',
        header: () => t('CustomerProfile.tables.rewardList.header.channel'),
        cell: info => {
          const value = info?.getValue() as LOYALTY_CHANNEL;
          const icons = [] as Array<IconNames>;
          switch (value) {
            case LOYALTY_CHANNEL.ECOMM:
              icons.push('web');
              break;
            case LOYALTY_CHANNEL.RETAIL:
              icons.push('shop');
              break;
            case LOYALTY_CHANNEL.ECOMM_RETAIL:
              icons.push('shop');
              icons.push('web');
              break;
          }

          return <IconsCell {...info} icons={icons} />;
        },
      },
      {
        accessorKey: 'completedDate',
        header: () =>
          t('CustomerProfile.tables.missionList.header.accomplishmentDT'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'expirationDate',
        header: () =>
          t('CustomerProfile.tables.missionList.header.expirationDate'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'actions',
        header: () => t('CustomerProfile.tables.missionList.header.action'),
        cell: info => <ButtonCell {...info} />,
      },
    ],
    [chip, t],
  );

  return {MissionListColumnDef};
};
