import React, {useCallback, useMemo, useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';

import {Box, Container, Skeleton, Stack} from '@mui/material';
import {GridColumnHeaderParams, GridSortModel} from '@mui/x-data-grid';
import {useQuery} from '@tanstack/react-query';
import dayjs, {Dayjs} from 'dayjs';

import {ChipDatepicker} from '@/atoms/ChipDatepicker';
import {ChipDropdown} from '@/atoms/ChipDropdown';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {Table} from '@/atoms/Table';
import {useTableColumns} from '@/atoms/Table/TableColumns';
import {Text} from '@/atoms/Typography/Text';
import {truncate} from '@/lib/utils';
import {HeadingCard} from '@/molecules/HeadingCard';
import {useNavigation} from '@/navigation/useNavigation';
import {useAuthentication} from '@/store/authentication';
import {useCampaigns} from '@/store/campaigns';
import {useReportFilters} from '@/store/reportFilters/hooks';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

import {useStyle} from './styles';

const defaultStartDate = dayjs().utc().startOf('month');
const defaultEndDate = dayjs().utc();

const CampaignsPage = () => {
  const {t} = useTranslation();
  const styles = useStyle();

  const {userData} = useAuthentication();
  const {goBack, goToCampaignsReportDetail} = useNavigation();

  const {allCampaignsColumns} = useTableColumns();

  // Filters API
  const {getFilterCampaignDetail} = useReportFilters();

  // Header and Table API
  const {getAllCampaignsTable, getAllCampaignsHeader} = useCampaigns();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Clienteling campaigns',
    });
  }, []);

  /* Filters -- calendar / dates */
  const [dates, setDates] = useState<{startDate: Dayjs; endDate: Dayjs}>({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  const handleDateChange = useCallback((startDate: Dayjs, endDate: Dayjs) => {
    setDates({
      startDate: startDate,
      endDate: endDate,
    });
    setCodes([]);
  }, []);

  // Campaigns filter
  const {data: CampaignsFilter, isLoading: CampaignsFilterLoading} = useQuery(
    [
      'campaignDetailFilters',
      dates?.startDate,
      dates?.endDate,
      userData?.currentStore?.id,
    ],
    () =>
      getFilterCampaignDetail({
        storeId: userData?.currentStore?.id!,
        startDate: dates?.startDate?.utc()?.format('YYYY-MM-DD'),
        endDate: dates?.endDate?.utc()?.format('YYYY-MM-DD'),
      }),
    {
      keepPreviousData: true,
    },
  );

  /* Table */

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: '',
      sort: 'asc',
    },
  ]);

  const [sortBy, setSortBy] = useState<string | null>(null);

  const [orderBy, setOrderBy] = useState<'ASC' | 'DESC'>('ASC');

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [codes, setCodes] = useState<string[]>([]);

  /* Filters -- reset */
  const canReset = useMemo(() => {
    const hasCodes = codes.length > 0;
    const hasDiffStartDate = !defaultStartDate
      .utc()
      .isSame(dates?.startDate, 'date');
    const hasDiffEndDate = !defaultEndDate.utc().isSame(dates?.endDate, 'date');
    return hasDiffStartDate || hasDiffEndDate || hasCodes;
  }, [dates?.endDate, dates?.startDate, codes]);

  const handleReset = useCallback(() => {
    setDates({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
    setCodes([]);
  }, []);

  /* Filters */
  const campaignsOptions = useMemo(() => {
    if (!CampaignsFilter) return [];

    return CampaignsFilter?.map(campaign => {
      return {
        label: campaign?.name,
        value: campaign?.code,
      };
    });
  }, [CampaignsFilter]);

  // All campaigns detail header query
  const {data: AllCampaignsHeader, isLoading: AllCampaignsHeaderLoading} =
    useQuery(
      [
        'getAllCampaignsHeader',
        dates?.startDate,
        dates?.endDate,
        userData?.currentStore?.id,
        codes,
      ],
      () =>
        getAllCampaignsHeader({
          storeId: userData?.currentStore?.id!,
          startDate: dates?.startDate?.utc()?.format('YYYY-MM-DD'),
          endDate: dates?.endDate?.utc()?.format('YYYY-MM-DD'),
          codes: codes.length > 0 ? codes : null,
        }),
    );

  // Sort table columns
  const handleColumnHeaderClick = useCallback(
    (params: GridColumnHeaderParams) => {
      const {field, colDef} = params;
      const {sortable} = colDef;

      if (sortable) {
        setOrderBy(prevState => (prevState === 'ASC' ? 'DESC' : 'ASC'));
        setSortBy(field.toUpperCase());

        setSortModel([
          {
            field: field,
            sort: orderBy?.toLocaleLowerCase() as any,
          },
        ]);
      }
    },
    [orderBy],
  );

  // Get table data

  const {data: AllCampaignsData, isFetching: AllCampaignsDataLoading} =
    useQuery(
      [
        'getAllCampaignsTable',
        currentPage,
        sortBy,
        orderBy,
        dates,
        codes,
        userData?.currentStore.id,
      ],
      () =>
        getAllCampaignsTable({
          page: currentPage,
          sortBy: sortBy,
          orderBy: orderBy,
          startDate: dates.startDate?.utc()?.format('YYYY-MM-DD'),
          endDate: dates.endDate?.utc()?.format('YYYY-MM-DD'),
          codes: codes?.length > 0 ? codes : undefined,
          storeId: userData?.currentStore.id!,
        }),
      {
        keepPreviousData: true,
        retry: false,
      },
    );

  // Update table pagination
  const changePage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleChange = useCallback((key: 'codes', value: any[]) => {
    switch (key) {
      case 'codes':
        setCodes(value);
        break;
      default:
        break;
    }
  }, []);

  // Handle and parse table response keys
  const reportsRows = useMemo(
    () =>
      AllCampaignsData?.data?.map(item => ({
        id: item.id,
        code: item.code,
        NAME: {
          title: item.name,
          color: item.color,
        },
        TASK_NUMBER: item.taskNumber,
        NEW_TASK: `${item.newTask} (${Math.round(item.newTaskPerc)}%)`,
        COMPLETED_TASK: `${item.completedTask} (${Math.round(
          item.completedTaskPerc,
        )}%)`,
        MOM: Math.round(item.mom),
        POSITIVE_FEEDBACK: `${item.positiveFeedback} (${Math.round(
          item.positiveFeedbackPerc,
        )}%)`,
        APPOINTMENT_BOOKED: `${item.appointmentBooked} (${Math.round(
          item.appointmentBookedPerc,
        )}%)`,
        APPOINTMENT_CHECKIN: `${item.appointmentCheckIn} (${Math.round(
          item.appointmentCheckInPerc,
        )}%)`,
      })) || [],
    [AllCampaignsData],
  );

  return (
    <Base
      title={t('CampaignsViewAll.headerTitle')}
      noContainer={
        <Container
          maxWidth="xl"
          sx={{paddingTop: `${theme.spacing(2.4)} !important`}}>
          <Stack direction="column" gap={2}>
            {/* Header, back button on the left and store info box on the right */}
            <Box sx={styles.row}>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                handleOnClick={goBack}
                iconStart={
                  <IconSVG icon="arrow_left_2" size={16} color="#000" />
                }>
                {t('Generic.ctas.back')}
              </Link>
              {!userData?.currentStore?.storeId ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={240}
                  sx={{float: 'right'}}
                  height={44}
                />
              ) : (
                <Box sx={styles.row}>
                  <Box sx={styles.headingBox}>
                    <Text variant="body" medium display="block">
                      {truncate(
                        `${userData?.currentStore?.storeId} - ${userData?.currentStore?.storeName}`,
                      )}
                    </Text>
                    <Text variant="caption" sx={styles.label}>
                      {t('ReportDetail.CampaignHeader.store')}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
            {/* --- Filters --- */}
            <Stack sx={styles.filterContainer}>
              <Stack
                direction="row"
                alignItems="center"
                columnGap={0.8}
                flexWrap="wrap">
                <ChipDropdown
                  filterLabel={t('ReportsFilters.CAMPAIGNS.multiple')}
                  allFilterLabel={t('ReportsFilters.CAMPAIGNS.all')}
                  searchPlaceholder={t('ReportsFilters.CAMPAIGNS.placeholder')}
                  selectedValues={codes}
                  options={campaignsOptions}
                  onApply={codes => handleChange('codes', codes)}
                  disabled={CampaignsFilterLoading || !CampaignsFilter}
                />
                <ChipDatepicker
                  startDate={dates?.startDate}
                  endDate={dates?.endDate}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  onApply={handleDateChange}
                  minDate={dayjs().utc().subtract(60, 'days')}
                  maxDate={dayjs()}
                />
              </Stack>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                iconStart={<IconSVG icon="reset" size={16} />}
                handleOnClick={handleReset}
                disabled={!canReset}
                noDecoration>
                {t('PurchaseHistory.filters.reset')}
              </Link>
            </Stack>
            {/* Hading card, grid of 10 info elements */}
            {AllCampaignsHeaderLoading || !AllCampaignsHeader ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={80}
              />
            ) : (
              <HeadingCard
                translationPath="ReportHeader"
                items={AllCampaignsHeader}
              />
            )}
          </Stack>
        </Container>
      }>
      {/* Table */}
      {!AllCampaignsDataLoading ? (
        <Table
          sx={{
            '.MuiDataGrid-row': {
              cursor: 'pointer',
              opacity: 1,
              transition: 'all 0.2s linear',
              '&:hover': {
                opacity: 0.7,
              },
            },
          }}
          columnHeaderHeight={88}
          sortModel={sortModel}
          getRowId={row => row.id}
          onRowClick={(_e, row) => {
            goToCampaignsReportDetail(_e?.row?.code);
          }}
          checkbox={false}
          columns={allCampaignsColumns}
          rows={reportsRows}
          currentPage={currentPage}
          onColumnHeaderClick={handleColumnHeaderClick}
          totalPages={
            AllCampaignsData?.metadataDTO
              ? AllCampaignsData.metadataDTO.total_pages
              : 1
          }
          loading={AllCampaignsDataLoading || !AllCampaignsData}
          setPage={(_e, page) => changePage(page)}
        />
      ) : (
        <Skeleton
          height={400}
          animation="wave"
          variant="rounded"
          width="100%"
        />
      )}
    </Base>
  );
};

export default React.memo(CampaignsPage);
