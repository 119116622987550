import dayjs from 'dayjs';

import {
  MembersReportFilters,
  MembersReportFiltersActions,
  MembersReportFiltersAction,
} from '@/store/membersReportFilters/definitions';

export const membersReportFiltersInitialStartDate = dayjs()
  .utc()
  .startOf('month');
export const membersReportFiltersInitialEndDate = dayjs().utc();

export const getInitialState = (): MembersReportFilters => ({
  assignees: [],
  codes: [],
  startDate: membersReportFiltersInitialStartDate,
  endDate: membersReportFiltersInitialEndDate,
  page: 1,
  orderBy: 'DESC',
  sortBy: 'COMPLETED_TASK',
});

export const membersReportFiltersReducer = (
  state = getInitialState(),
  action: MembersReportFiltersAction,
) => {
  switch (action.type) {
    case MembersReportFiltersActions.changeField:
      const {key, value} = action?.payload;
      return {
        ...state,
        [key]: value,
        page: 1,
      };
    case MembersReportFiltersActions.changeDates:
      const hasDiffStart = !state.startDate
        .utc()
        .isSame(action?.payload?.startDate, 'date');
      const hasDiffEnd = !state.endDate
        .utc()
        .isSame(action?.payload?.endDate, 'date');
      return {
        ...state,
        ...((hasDiffStart || hasDiffEnd) && {
          codes: [],
        }),
        startDate: action?.payload?.startDate,
        endDate: action?.payload?.endDate,
        page: 1,
      };
    case MembersReportFiltersActions.changePage:
      return {
        ...state,
        page: action?.payload,
      };
    case MembersReportFiltersActions.resetFilters:
      return getInitialState();
    default:
      return state;
  }
};
