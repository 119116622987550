import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useApiPath, APIS} from '@/store/api/useApiPath';

import {useAPI} from '../api';
import {
  GetPersonasParams,
  GetPersonasResponse,
  GetIndividualParams,
  GetIndividualResponse,
  GetPersonalInformationCustomersParams,
  GetPersonalInformationCustomersResponse,
  GetBannersResponse,
  GetCountriesParams,
  GetCountriesResponse,
  GetIndividualsSearchParams,
  GetIndividualsSearchResponse,
} from './interfaces';

/**
 * @brief Hook to post/get stores relative api
 */
export const usePersonas = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getPersonas = useCallback(
    async ({...params}: GetPersonasParams) => {
      try {
        const {data} = await api.post<GetPersonasResponse>(
          getApiPath(APIS.PERSONAS_SEARCH),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return {
          data: [],
          size: 0,
        };
      }
    },
    [api, getApiPath],
  );

  const getIndividual = useCallback(
    async ({...params}: GetIndividualParams) => {
      try {
        const {data} = await api.post<GetIndividualResponse>(
          getApiPath(APIS.PERSONAS_INDIVIDUALS_SEARCH),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  // POST v2/personas/customers
  const getPersonalInformationCustomers = useCallback(
    async ({...params}: GetPersonalInformationCustomersParams) => {
      try {
        const {data} = await api.post<GetPersonalInformationCustomersResponse>(
          getApiPath(APIS.PERSONAS_CUSTOMERS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getBanners = useCallback(async () => {
    try {
      const {data} = await api.get<GetBannersResponse>(
        getApiPath(APIS.MY_ACCOUNT_COMMON_BANNERS),
      );

      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }, [api, getApiPath]);

  const getCountries = useCallback(
    async ({bannerId}: GetCountriesParams) => {
      try {
        const {data} = await api.get<GetCountriesResponse>(
          getApiPath(APIS.MY_ACCOUNT_COMMON_COUNTRIES, {
            banner: bannerId,
          }),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getBannerCountry = useCallback(
    async (bannerId?: string) => {
      try {
        if (!!bannerId) {
          const bannerPromise = api.get<GetBannersResponse>(
            getApiPath(APIS.MY_ACCOUNT_COMMON_BANNERS),
          );

          const countriesPromise = api.get<GetCountriesResponse>(
            getApiPath(APIS.MY_ACCOUNT_COMMON_COUNTRIES, {
              banner: bannerId,
            }),
          );

          const [banners, countries] = await Promise.all([
            bannerPromise,
            countriesPromise,
          ]);
          return {
            banners: banners.data,
            countries: countries.data,
          };
        } else {
          const banners = await api.get<GetBannersResponse>(
            getApiPath(APIS.MY_ACCOUNT_COMMON_BANNERS),
          );

          const countries = await api.get<GetCountriesResponse>(
            getApiPath(APIS.MY_ACCOUNT_COMMON_COUNTRIES, {
              banner: banners.data[0].name,
            }),
          );

          return {
            banners: banners.data,
            countries: countries.data,
          };
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  // POST v2/personas/individuals/search
  const getIndividualsSearch = useCallback(
    async ({...params}: GetIndividualsSearchParams) => {
      try {
        const {data} = await api.post<GetIndividualsSearchResponse>(
          getApiPath(APIS.V2_PERSONAS_INDIVIDUALS_SEARCH),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getPersonas,
      getIndividual,
      getPersonalInformationCustomers,
      getBanners,
      getCountries,
      getIndividualsSearch,
      getBannerCountry,
    }),
    [
      getPersonas,
      getIndividual,
      getPersonalInformationCustomers,
      getBanners,
      getCountries,
      getIndividualsSearch,
      getBannerCountry,
    ],
  );
};
