import React, {useState, useCallback, useMemo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {PiCalendarBlankLight} from 'react-icons/pi';

import {Chip, Box, Menu, Stack, Button, ChipProps} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

import {Calendar} from '@/atoms/Calendar';
import {
  ChipDatepickerProps,
  DatepickerMenuProps,
} from '@/atoms/ChipDatepicker/interfaces';
import {useStyles} from '@/atoms/ChipDatepicker/styles';
import {TinyText} from '@/atoms/TinyText';
import {useLocale} from '@/store/locale';

const ChipDatepicker = ({
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate,
  minDate,
  maxDate,
  onApply,
}: ChipDatepickerProps) => {
  const styles = useStyles();
  const {formatDate} = useLocale();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  const label = useMemo<ReactNode>(() => {
    return (
      <Stack
        display="grid"
        gridTemplateColumns="1fr max-content"
        gap={0.4}
        alignItems="center">
        <TinyText
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          sx={{textTransform: 'capitalize'}}>
          {formatDate(startDate)} - {formatDate(endDate)}
        </TinyText>
        <PiCalendarBlankLight fontSize={20} />
      </Stack>
    );
  }, [endDate, formatDate, startDate]);

  const variant = useMemo<ChipProps['variant']>(() => {
    const sameStart = defaultStartDate.isSame(startDate, 'date');
    const sameEnd = defaultEndDate.isSame(endDate, 'date');

    return sameStart && sameEnd ? 'filterDefault' : 'filterActive';
  }, [defaultEndDate, defaultStartDate, endDate, startDate]);

  return (
    <>
      <Box onClick={toggleMenu} paddingY={0.8}>
        <Chip
          sx={styles.chip}
          disabled={false}
          label={label}
          clickable={true}
          variant={variant}
        />
      </Box>
      {anchorEl && (
        <DatepickerMenu
          onApply={onApply}
          anchorEl={anchorEl}
          minDate={minDate}
          maxDate={maxDate}
          toggleMenu={toggleMenu}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

const DatepickerMenu = React.memo(
  ({
    anchorEl,
    toggleMenu,
    minDate,
    maxDate,
    onApply,
    startDate: StartDate,
    endDate: EndDate,
  }: DatepickerMenuProps) => {
    const {t} = useTranslation();
    const styles = useStyles();

    const [startDate, setStartDate] = useState<Dayjs | undefined>(
      StartDate || dayjs(),
    );
    const [endDate, setEndDate] = useState<Dayjs | undefined>(EndDate || dayjs);

    const canSubmit = useMemo(() => {
      return typeof startDate !== 'undefined' && typeof endDate !== 'undefined';
    }, [endDate, startDate]);

    const handleDateSelect = useCallback((date: Dayjs) => {
      const isSameMonthAsToday = date.isSame(dayjs(), 'month');
      if (isSameMonthAsToday) {
        setStartDate(dayjs().set('date', 1));
        setEndDate(date);
      } else {
        setStartDate(date.startOf('month'));
        setEndDate(date.endOf('month'));
      }
    }, []);

    return (
      <Menu
        sx={styles.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={true}
        onClose={() => toggleMenu()}>
        <Stack
          direction="column"
          gap={3.2}
          alignItems="center"
          width={400}
          paddingTop={3.2}
          paddingBottom={2.4}>
          <Calendar
            wholeMonthSelection
            onSelect={handleDateSelect}
            {...(startDate && {
              selectedStart: startDate,
            })}
            maxDate={maxDate}
            selectedDate={endDate}
          />
          <Stack
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={1.6}
            width="100%"
            paddingBottom={1.2}
            paddingLeft={2.4}
            paddingRight={2.4}>
            <Button
              variant="baseSecondary"
              disabled={!canSubmit}
              {...(canSubmit && {
                onClick: () => {
                  setEndDate(undefined);
                  setStartDate(undefined);
                },
              })}
              autoFocus>
              {t('ChipFilters.reset')}
            </Button>
            <Button
              variant="basePrimary"
              disabled={!canSubmit}
              {...(canSubmit && {
                onClick: () => {
                  onApply(startDate!, endDate!);
                  toggleMenu();
                },
              })}
              autoFocus>
              {t('ChipFilters.apply')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    );
  },
);

DatepickerMenu.displayName = 'DatepickerMenu';

export default React.memo(ChipDatepicker);
