import React, {useCallback, useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
  Box,
  Skeleton,
  Stack,
  Container,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import {GridColumnHeaderParams} from '@mui/x-data-grid';
import {useQuery} from '@tanstack/react-query';
import dayjs, {Dayjs} from 'dayjs';
import {Dispatch} from 'redux';

import {Badge} from '@/atoms/Badge';
import {ChipDatepicker} from '@/atoms/ChipDatepicker';
import {ChipDropdown} from '@/atoms/ChipDropdown';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {Table} from '@/atoms/Table';
import {useTableColumns} from '@/atoms/Table/TableColumns';
import {Text} from '@/atoms/Typography/Text';
import {truncate} from '@/lib/utils';
import {HeadingCard} from '@/molecules/HeadingCard';
import {useNavigation} from '@/navigation/useNavigation';
import {useAuthentication} from '@/store/authentication';
import {useLocale} from '@/store/locale';
import {
  ReportDetailFilters,
  ReportDetailFiltersReducer,
  ReportDetailsFiltersAction,
} from '@/store/reportDetailsFilters/definitions';
import {
  ReportDetailsFiltersInitialize,
  ReportDetailsFiltersChangePages,
  ReportDetailsFiltersChangeField,
  ReportDetailsFiltersChangeDates,
} from '@/store/reportDetailsFilters/reportDetailsFilters.actions';
import {selectReportDetailFilters} from '@/store/reportDetailsFilters/selectors';
import {useReportFilters} from '@/store/reportFilters/hooks';
import {useReport} from '@/store/reports/hooks';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

import {useStyle} from './styles';

const defaultStartDate = dayjs().startOf('month');
const defaultEndDate = dayjs();

const CampaignReportDetail = () => {
  const {t} = useTranslation();
  const {formatDate} = useLocale();
  const styles = useStyle();
  const {campaignCode} = useParams<{campaignCode: string}>();
  const {goBack} = useNavigation();
  const {userData} = useAuthentication();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const {reportDetailColumns} = useTableColumns();
  const {
    getReportsDetailHeader,
    getReportsDetailInfo,
    getReportDetailCampaigns,
    getReportsDetailUnassigned,
  } = useReport();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Campaign report detail',
    });
  }, []);

  const dispatch = useDispatch<Dispatch<ReportDetailsFiltersAction>>();

  // Report detail info
  const {data: HeaderInfo, isLoading: HeaderInfoLoading} = useQuery(
    ['reportsDetailInfo', userData?.currentStore?.id, campaignCode],
    () => {
      return getReportsDetailInfo({
        storeId: userData?.currentStore?.id!,
        campaignCode: campaignCode,
      });
    },
    {
      enabled: Boolean(campaignCode),
    },
  );

  // Filters state
  const reportDetailFilters = useSelector<
    {reportDetailFilters: ReportDetailFiltersReducer},
    ReportDetailFilters | null
  >(state => {
    if (!(campaignCode in state?.reportDetailFilters)) {
      dispatch(ReportDetailsFiltersInitialize(campaignCode));
    }

    return selectReportDetailFilters(campaignCode, state?.reportDetailFilters);
  });

  const handleDateChange = useCallback(
    (startDate: Dayjs, endDate: Dayjs) => {
      dispatch(
        ReportDetailsFiltersChangeDates(campaignCode, startDate, endDate),
      );
    },
    [campaignCode, dispatch],
  );

  const handleAssigneeChange = useCallback(
    (value: any[]) => {
      dispatch(
        ReportDetailsFiltersChangeField(campaignCode, 'assignees', value),
      );
    },
    [campaignCode, dispatch],
  );

  const canReset = useMemo(() => {
    if (!reportDetailFilters) return false;

    const hasDiffStartDate = !defaultStartDate
      .utc()
      .isSame(reportDetailFilters?.startDate, 'date');
    const hasDiffEndDate = !defaultEndDate
      .utc()
      .isSame(reportDetailFilters?.endDate, 'date');
    const hasAssignees = reportDetailFilters?.assignees?.length > 0;

    return hasAssignees || hasDiffStartDate || hasDiffEndDate;
  }, [reportDetailFilters]);

  // Filters API
  const {getFilterAssignees} = useReportFilters();

  // Assignees filter
  const {data: AssigneesFilter, isFetching: AssigneesFilterLoading} = useQuery(
    ['campaignAssigneesFilters', userData?.currentStore?.id],
    () =>
      getFilterAssignees({
        storeId: userData?.currentStore?.id!,
        codes: [campaignCode],
      }),
    {
      enabled: Boolean(reportDetailFilters),
    },
  );

  const assigneesOptions = useMemo(() => {
    if (!AssigneesFilter) return [];

    return AssigneesFilter?.map(assignee => {
      return {
        label: `${assignee?.assigneeFirstname?.toLowerCase()} ${assignee?.assigneeLastname?.toLowerCase()}`,
        value: assignee?.uuid,
      };
    });
  }, [AssigneesFilter]);

  // Report detail header
  const {data: ReportsHeader, isLoading: ReportsHeaderLoading} = useQuery(
    [
      'reportsDetailHeader',
      reportDetailFilters?.startDate,
      reportDetailFilters?.endDate,
      reportDetailFilters?.assignees,
      userData?.currentStore?.id,
      campaignCode,
    ],
    () =>
      getReportsDetailHeader({
        storeId: userData?.currentStore?.id!,
        startDate: reportDetailFilters!.startDate?.utc()?.format('YYYY-MM-DD'),
        endDate: reportDetailFilters!.endDate?.utc()?.format('YYYY-MM-DD'),
        codes: [campaignCode],
        userUuids:
          reportDetailFilters!.assignees?.length > 0
            ? reportDetailFilters!.assignees
            : undefined,
      }),
    {
      keepPreviousData: true,
      enabled: Boolean(reportDetailFilters),
    },
  );

  // Report detail unassigned
  const {data: ReportsUnassigned} = useQuery(
    [
      'reportsDetailUnassigned',
      reportDetailFilters?.startDate,
      reportDetailFilters?.endDate,
      campaignCode,
    ],
    () =>
      getReportsDetailUnassigned({
        storeId: userData?.currentStore?.id!,
        startDate: reportDetailFilters!.startDate?.utc()?.format('YYYY-MM-DD'),
        endDate: reportDetailFilters!.endDate?.utc()?.format('YYYY-MM-DD'),
        codes: [campaignCode],
      }),
    {
      keepPreviousData: true,
      enabled: Boolean(reportDetailFilters),
    },
  );

  const {
    data: ReportDetailCampaigns,
    isFetching: ReportDetailCampaignsLoading,
  } = useQuery(
    [
      'reportDetailCampaigns',
      reportDetailFilters,
      userData?.currentStore.id,
      campaignCode,
    ],
    () =>
      getReportDetailCampaigns({
        page: reportDetailFilters!.page,
        sortBy: reportDetailFilters!.sortBy,
        orderBy: reportDetailFilters!.orderBy,
        startDate: reportDetailFilters!.startDate?.utc()?.format('YYYY-MM-DD'),
        endDate: reportDetailFilters!.endDate?.utc()?.format('YYYY-MM-DD'),
        storeId: userData?.currentStore.id!,
        codes: [campaignCode],
        userUuids:
          reportDetailFilters!.assignees?.length > 0
            ? reportDetailFilters!.assignees
            : undefined,
      }),
    {
      keepPreviousData: true,
      retry: false,
      enabled: Boolean(reportDetailFilters),
    },
  );

  // Table Rows
  const reportsRows = useMemo(
    () =>
      ReportDetailCampaigns?.data?.map(item => ({
        id: item.id,
        ASSIGNEE: {
          firstName: item.assigneeFirstname,
          lastName: item.assigneeLastname,
          id: item.assigneeLuxId,
        },
        TASK_NUMBER: item.taskNumber,
        NEW_TASK: `${item.newTask} (${Math.round(item.newTaskPerc)}%)`,
        COMPLETED_TASK: `${item.completedTask} (${Math.round(
          item.completedTaskPerc,
        )}%)`,
        MOM: Math.round(item.mom),
        POSITIVE_FEEDBACK: `${item.positiveFeedback} (${Math.round(
          item.positiveFeedbackPerc,
        )}%)`,
        APPOINTMENT_BOOKED: `${item.appointmentBooked} (${Math.round(
          item.appointmentBookedPerc,
        )}%)`,
        APPOINTMENT_CHECKIN: `${item.appointmentCheckIn} (${Math.round(
          item.appointmentCheckInPerc,
        )}%)`,
      })) || [],
    [ReportDetailCampaigns],
  );

  // Update table pagination
  const changePage = useCallback(
    (page: number) => {
      dispatch(ReportDetailsFiltersChangePages(campaignCode, page));
    },
    [campaignCode, dispatch],
  );

  // Sort table columns
  const handleColumnHeaderClick = useCallback(
    (params: GridColumnHeaderParams) => {
      const {field, colDef} = params;
      const {sortable} = colDef;

      if (sortable) {
        dispatch(
          ReportDetailsFiltersChangeField(
            campaignCode,
            'orderBy',
            reportDetailFilters?.orderBy === 'ASC' ? 'DESC' : 'ASC',
          ),
        );
        dispatch(
          ReportDetailsFiltersChangeField(campaignCode, 'sortBy', field),
        );
      }
    },
    [campaignCode, dispatch, reportDetailFilters?.orderBy],
  );

  // If no store associates are selected in the filter, add a row on top for unassigned tasks
  const parsedRows = useMemo(() => {
    if (
      ReportsUnassigned?.assigneeLuxId &&
      reportDetailFilters?.assignees &&
      reportDetailFilters?.assignees.length < 1
    ) {
      return [
        {
          id: ReportsUnassigned.assigneeLuxId,
          ASSIGNEE: {
            firstName: t('ReportsTable.USER_UNASSIGNED_NAME'),
            lastName: t('ReportsTable.USER_UNASSIGNED_LAST_NAME'),
            id: ReportsUnassigned.assigneeLuxId,
          },
          TASK_NUMBER: ReportsUnassigned.taskNumber,
          NEW_TASK: '-',
          COMPLETED_TASK: '-',
          MOM: null,
          POSITIVE_FEEDBACK: '-',
          APPOINTMENT_BOOKED: '-',
          APPOINTMENT_CHECKIN: '-',
        },
        ...reportsRows,
      ];
    }
    return reportsRows;
  }, [reportsRows, ReportsUnassigned, t, reportDetailFilters]);

  return (
    <Base
      title={t('ReportDetail.headerTitle')}
      noContainer={
        <Container
          maxWidth="xl"
          sx={{paddingTop: `${theme.spacing(2.4)} !important`}}>
          <Stack direction="column" gap={2}>
            {/* Header, back button on the left and three info boxes on the right */}
            <Box sx={styles.row}>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                handleOnClick={goBack}
                iconStart={
                  <IconSVG icon="arrow_left_2" size={16} color="#000" />
                }>
                {t('Generic.ctas.back')}
              </Link>
              {HeaderInfoLoading || !HeaderInfo ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={440}
                  sx={{float: 'right'}}
                  height={44}
                />
              ) : (
                <Box sx={styles.row}>
                  <Tooltip
                    title={`
                      ${formatDate(
                        HeaderInfo.campaignStartDate,
                      )} - ${formatDate(HeaderInfo.campaignEndDate)}
                    `}
                    placement="top"
                    arrow
                    enterTouchDelay={0}>
                    <Box sx={styles.headingBox}>
                      <Badge
                        color={HeaderInfo?.campaignColor}
                        badgeLabel={HeaderInfo?.campaignName}
                      />
                      <Text
                        variant="caption"
                        marginLeft={isDesktop ? '12px' : '4px'}
                        sx={styles.label}>
                        {t('ReportDetail.CampaignHeader.campaign')}
                      </Text>
                    </Box>
                  </Tooltip>
                  <Box sx={styles.headingBox}>
                    <Text variant="body" medium display="block">
                      {truncate(
                        `${userData?.currentStore?.storeId} - ${userData?.currentStore?.storeName}`,
                      )}
                    </Text>
                    <Text variant="caption" sx={styles.label}>
                      {t('ReportDetail.CampaignHeader.store')}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
            <Stack sx={styles.filterContainer}>
              <Stack
                direction="row"
                alignItems="center"
                columnGap={0.8}
                flexWrap="wrap">
                <ChipDropdown
                  filterLabel={t('ReportsFilters.STORE_ASSOCIATE.multiple')}
                  allFilterLabel={t('ReportsFilters.STORE_ASSOCIATE.all')}
                  searchPlaceholder={t(
                    'ReportsFilters.STORE_ASSOCIATE.placeholder',
                  )}
                  selectedValues={reportDetailFilters?.assignees || []}
                  options={assigneesOptions}
                  onApply={handleAssigneeChange}
                  disabled={
                    AssigneesFilterLoading ||
                    !AssigneesFilter ||
                    !Boolean(reportDetailFilters)
                  }
                  capitalize
                />
                <ChipDatepicker
                  startDate={reportDetailFilters?.startDate!}
                  endDate={reportDetailFilters?.endDate!}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  onApply={handleDateChange}
                  minDate={dayjs().utc().subtract(60, 'days')}
                  maxDate={dayjs()}
                />
              </Stack>

              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                iconStart={<IconSVG icon="reset" size={16} />}
                handleOnClick={() => {
                  dispatch(ReportDetailsFiltersInitialize(campaignCode));
                }}
                disabled={!canReset}
                noDecoration>
                {t('PurchaseHistory.filters.reset')}
              </Link>
            </Stack>
            {/* Hading card, grid of 10 info elements */}
            {ReportsHeaderLoading || !ReportsHeader ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={80}
              />
            ) : (
              <HeadingCard
                translationPath="ReportHeader"
                items={ReportsHeader}
              />
            )}
          </Stack>
        </Container>
      }>
      {!ReportDetailCampaignsLoading ? (
        <Table
          sx={{
            '.MuiDataGrid-row': {
              padding: '16px 0 !important',
            },
          }}
          columnHeaderHeight={88}
          sortModel={[
            {
              field: reportDetailFilters?.sortBy ?? '',
              sort: reportDetailFilters?.orderBy?.toLocaleLowerCase() as any,
            },
          ]}
          getRowId={row => row.id}
          checkbox={false}
          columns={reportDetailColumns}
          rows={parsedRows}
          currentPage={reportDetailFilters?.page || 1}
          onColumnHeaderClick={handleColumnHeaderClick}
          totalPages={
            ReportDetailCampaigns?.metadataDTO
              ? ReportDetailCampaigns?.metadataDTO?.total_pages
              : 1
          }
          setPage={(_e, page) => changePage(page)}
          loading={ReportDetailCampaignsLoading || !ReportDetailCampaigns}
        />
      ) : (
        <Skeleton
          height={400}
          animation="wave"
          variant="rounded"
          width="100%"
        />
      )}
    </Base>
  );
};

export default React.memo(CampaignReportDetail);
