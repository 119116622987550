import {useMemo, useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetQuotationsParams,
  GetQuotationsResponse,
} from '@/store/quotations/interfaces';

export const useQuotations = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  // POST v1/quotations
  const getQuotations = useCallback(
    async (params: GetQuotationsParams) => {
      try {
        const {data} = await api.post<GetQuotationsResponse>(
          getApiPath(APIS.V1_QUOTATIONS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return [];
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getQuotations,
    }),
    [getQuotations],
  );
};
