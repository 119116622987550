import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {CardAction} from '@/atoms/CardAction';
import {Dialog} from '@/atoms/Dialog';
import {CommonButtons} from '@/atoms/DialogButtons';
import {useToast} from '@/hooks/toast';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import {Card, CardHeader, CardTitle, CardTag} from '@/organisms/Card';
import {useCustomerInfo} from '@/store/customersInfo';
import {PatchCostumerInfoBody} from '@/store/customersInfo/interfaces';

import {EditForm} from './EditForm';
import {CustomerInfoProps} from './interfaces';
import {useStyle} from './styles';

const CustomerInfo = ({
  refetchCustomersInfo,
  isSuspended,
  ...customerInfo
}: CustomerInfoProps) => {
  const {
    cardNumber,
    userStatus,
    email,
    firstName,
    lastName,
    birthDate,
    gender,
    brandId,
    country,
    cardActivationDate,
    communications,
  } = customerInfo;
  const {t} = useTranslation();
  const styles = useStyle(isSuspended);

  const {showSuccessToast, showErrorToast} = useToast();

  const {patchCostumerInfo} = useCustomerInfo();

  const [thereAreChanges, setThereAreChanges] = useState<boolean>(false);

  const hasYear = useMemo(() => {
    return (
      customerInfo?.birthDate?.split('').filter(v => v === '/').length === 2
    );
  }, [customerInfo]);

  const displayBirthDate = useMemo(() => {
    if (!birthDate) return '-';

    if (hasYear) {
      return dayjs(birthDate, 'YYYY/MM/DD').format(t('Date.formatShort'));
    } else {
      return dayjs(birthDate, 'MM/DD').format('MM/DD');
    }
  }, [hasYear, birthDate, t]);

  const displayedInfo = useMemo(
    () => [
      {
        name: 'CardNumber',
        keyLabel: t('CustomerInfo.cardNumber'),
        value: cardNumber,
      },
      {
        name: 'status',
        keyLabel: t('CustomerInfo.userStatus.title'),
        value: t(`CustomerInfo.userStatus.values.${userStatus}`),
      },
      {name: 'email', keyLabel: t('CustomerInfo.email'), value: email},
      {
        name: 'name',
        keyLabel: t('CustomerInfo.firstName', {context: 'short'}),
        value: firstName,
      },
      {
        name: 'surname',
        keyLabel: t('CustomerInfo.surname', {context: 'short'}),
        value: lastName,
      },
      {
        name: 'birthDate',
        keyLabel: t('CustomerInfo.birthDate'),
        value: displayBirthDate,
      },
      {
        name: 'gender',
        keyLabel: t('CustomerInfo.gender.title'),
        value: gender ? t(`CustomerInfo.gender.values.${gender}`) : '-',
      },
      {name: 'banner', keyLabel: t('CustomerInfo.brand'), value: brandId},
      {name: 'country', keyLabel: t('CustomerInfo.country'), value: country},
      {
        name: 'activationDate',
        keyLabel: t('CustomerInfo.activationDate'),
        value: dayjs(cardActivationDate).utc().format(t('Date.formatShort')),
      },
    ],
    [
      t,
      cardNumber,
      userStatus,
      email,
      firstName,
      lastName,
      displayBirthDate,
      gender,
      brandId,
      country,
      cardActivationDate,
    ],
  );

  const [showModal, setShowModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [updatedInfo, setUpdatedInfo] = useState<PatchCostumerInfoBody>();

  const toggleDialog = useCallback((value?: boolean) => {
    setShowModal(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const {mutate: patchUserMutate} = useMutation({
    mutationKey: ['patchUser'],
    mutationFn: patchCostumerInfo,
    onSuccess: () => {
      showSuccessToast(t('CustomerInfo.edit.toast.success'));
      refetchCustomersInfo();
    },
    onError: () => {
      showErrorToast(t('CustomerInfo.edit.toast.error'));
    },
  });

  const handleChange = useCallback((infos: PatchCostumerInfoBody) => {
    setUpdatedInfo(infos);
  }, []);

  const onSubmit = useCallback(() => {
    if (updatedInfo) {
      patchUserMutate(updatedInfo);
      setUpdatedInfo(undefined);
      setShowModal(false);
      setThereAreChanges(false);
    }
  }, [patchUserMutate, updatedInfo]);

  const renderInfos = useCallback(
    (keyValue: {name: string; keyLabel: string; value: string}) => {
      if (isSuspended) {
        if (keyValue.name === 'status') {
          return (
            <KeyValuePair
              key={keyValue.name}
              keyLabel={keyValue.keyLabel}
              children={<CardTag variant="status" value={keyValue.value} />}
              isFlex={true}
              flexWrap="wrap"
            />
          );
        } else {
          <KeyValuePair
            key={keyValue.name}
            {...keyValue}
            isFlex={true}
            flexWrap="wrap"
          />;
        }
      }

      return (
        <KeyValuePair
          key={keyValue.name}
          {...keyValue}
          isFlex={true}
          flexWrap="wrap"
        />
      );
    },
    [isSuspended],
  );

  return (
    <>
      <Card>
        <Stack gap={1.6} height="100%">
          <CardHeader
            title={
              <Stack sx={styles.cardHeader}>
                <CardTitle title={t('CustomerInfo.sectionTitle')} />
                <CardTag communications={communications} />
              </Stack>
            }
            {...(!isSuspended && {
              action: (
                <CardAction icon="edit" onClick={() => toggleDialog(true)} />
              ),
            })}
          />
          <Box sx={styles.infoContainer}>
            <Box sx={styles.infoColumn}>
              {displayedInfo.slice(0, 5).map(keyValue => renderInfos(keyValue))}
            </Box>
            <Box sx={styles.infoColumn}>
              {displayedInfo.slice(5, 10).map((keyValue, i) => (
                <KeyValuePair
                  key={i}
                  {...keyValue}
                  isFlex={true}
                  flexWrap="wrap"
                />
              ))}
            </Box>
          </Box>
        </Stack>
      </Card>
      {showModal && (
        <Dialog
          sx={styles.dialog}
          dialogTitle={t('CustomerInfo.edit.title')}
          dialogActions={
            <CommonButtons
              onClose={() => toggleDialog(false)}
              confirmDisabled={!isFormValid}
              gap={3}
              {...(isFormValid && {onConfirm: onSubmit})}
            />
          }
          open={showModal}
          dialogContent={
            <EditForm
              {...customerInfo}
              hasYear={hasYear}
              formValidationCB={setIsFormValid}
              onChange={handleChange}
              thereAreChanges={thereAreChanges}
              setThereAreChanges={setThereAreChanges}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(CustomerInfo);
