import React, {useState, useCallback, useMemo, useEffect} from 'react';

import {Box, Stack} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useClipboard} from '@/hooks/useClipboard';
import {useStyles} from '@/hooks/useCommunicationModal/styles';
import {truncate} from '@/lib/utils';
import {BenefitIcon} from '@/organisms/Benefits/Benefits';
import {Communication} from '@/store/benefit';

const BASE_TRANSLATION = 'Benefits.modal';

export const useCommunicationModal = () => {
  const [communication, setCommunication] = useState<Communication | undefined>(
    undefined,
  );

  /**
   * Toggle communication modal
   */
  const toggleCommunicationModal = useCallback(
    (communication: Communication | undefined) => {
      setCommunication(communication);
    },
    [],
  );

  const communicationModal = useMemo(() => {
    if (typeof communication === 'undefined') return null;

    return (
      <Modal
        open={!!communication}
        onClose={() => toggleCommunicationModal(undefined)}
        maxWidth={60}
        dialogTitle={<HeadlineText heavy>{communication?.title}</HeadlineText>}
        dialogContent={
          <CommunicationModalContent communication={communication} />
        }
      />
    );
  }, [communication, toggleCommunicationModal]);

  return useMemo(
    () => ({
      toggleCommunicationModal,
      communicationModal,
    }),
    [toggleCommunicationModal, communicationModal],
  );
};

interface CommunicationModalContentProps {
  communication: Communication;
}

const CommunicationModalContent = React.memo(
  ({communication}: CommunicationModalContentProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const {copyToClipboard} = useClipboard();
    const styles = useStyles();
    const textContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [textExpanded, setTextExpanded] = useState<boolean>(false);

    /**
     * Update text container height and overflow
     */
    useEffect(() => {
      if (!!textContainerRef.current) {
        textContainerRef.current.style.maxHeight = textExpanded
          ? '280px'
          : `${textContainerRef.current?.clientHeight}px`;

        textContainerRef.current.style.overflowY = textExpanded
          ? 'auto'
          : 'hidden';
      }
    }, [textExpanded, textContainerRef]);

    /**
     * Check if communication is a promo
     */
    const isPromo = useMemo(
      () => communication?.notificationType === 'PROMO',
      [communication],
    );

    /**
     * Check if text can be expanded
     */
    const canExpand = useMemo(() => {
      if (!communication?.cardDescription) return false;

      return !textExpanded && communication?.cardDescription?.length > 200;
    }, [communication?.cardDescription, textExpanded]);

    /**
     * Get shown text, based on textExpanded state
     */
    const shownText = useMemo(() => {
      if (!communication?.cardDescription) return '-';

      return textExpanded
        ? communication?.cardDescription
        : truncate(communication?.cardDescription, 200);
    }, [communication?.cardDescription, textExpanded]);

    /**
     * Check if communication has promo code retail
     */
    const hasRetailPromoCode = useMemo(() => {
      return !!communication?.promoCodeRetail;
    }, [communication?.promoCodeRetail]);

    /**
     * Check if communication has promo code online
     */
    const hasOnlinePromoCode = useMemo(() => {
      return !!communication?.promoCodeOnline;
    }, [communication?.promoCodeOnline]);

    /**
     * Render single promo code
     */
    const renderSingleCode = useCallback(
      (
        promoCode: string,
        copy: boolean,
        type: 'shop' | 'web' | 'both',
      ): JSX.Element => {
        return (
          <Stack className="codeRow row">
            <LabelValue
              label={getTranslationWithValue(0, 'promoCode')}
              value={
                <Stack direction="row" gap={1} alignItems="center">
                  <BodyText medium>{promoCode}</BodyText>
                  {copy && (
                    <IconSVG
                      icon="copy"
                      size={16}
                      onClick={() => copyToClipboard(promoCode!)}
                    />
                  )}
                </Stack>
              }
            />
            <Stack direction="row" gap={0.8} alignItems="center">
              {type !== 'web' && <IconSVG icon="shop" size={16} />}
              {type !== 'shop' && <IconSVG icon="web" size={16} />}
              <XSText marginTop={0.2}>
                {getTranslationWithValue(0, type)}
              </XSText>
            </Stack>
          </Stack>
        );
      },
      [copyToClipboard, getTranslationWithValue],
    );

    /**
     * Render promo codes
     */
    const renderPromoCodes = useMemo(() => {
      if (!isPromo) return null;

      if (!!communication?.promoCodeEquals) {
        return renderSingleCode(communication?.promoCodeRetail!, true, 'both');
      }

      return (
        <Stack direction="column">
          {hasRetailPromoCode &&
            renderSingleCode(communication?.promoCodeRetail!, true, 'shop')}
          {hasOnlinePromoCode &&
            renderSingleCode(communication?.promoCodeOnline!, false, 'web')}
        </Stack>
      );
    }, [
      communication?.promoCodeEquals,
      communication?.promoCodeOnline,
      communication?.promoCodeRetail,
      hasOnlinePromoCode,
      hasRetailPromoCode,
      isPromo,
      renderSingleCode,
    ]);

    return (
      <Stack sx={styles.modalContentContainer(isPromo)}>
        <Stack className="firstContainer">
          {isPromo && (
            <Box className="iconContainer">
              <BenefitIcon type={communication?.promoType!} size={40} />
            </Box>
          )}
          <Stack className="textContainer" ref={textContainerRef}>
            <BodyText medium paddingBottom={0.8}>
              {shownText}
            </BodyText>
            {canExpand && (
              <BodyText
                className="expandBtn"
                heavy
                underline
                onClick={() => setTextExpanded(true)}>
                {getTranslationWithValue(0, 'showAll')}
              </BodyText>
            )}
          </Stack>
        </Stack>
        <Stack className="secondContainer">
          <Stack className="infoContainer row">
            {isPromo && (
              <LabelValue
                label={getTranslationWithValue(0, 'expiringOn')}
                value={
                  communication?.endDate
                    ? dayjs(communication?.endDate)
                        .utc()
                        .format(
                          dayjs.locale() === 'it'
                            ? 'D MMM YYYY'
                            : 'Do MMMM YYYY',
                        )
                    : '-'
                }
              />
            )}
            <LabelValue
              label={getTranslationWithValue(0, 'sentOn')}
              value={
                communication?.startDate
                  ? dayjs(communication?.startDate)
                      .utc()
                      .format(
                        dayjs.locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
                      )
                  : '-'
              }
            />
          </Stack>
          {renderPromoCodes && renderPromoCodes}
        </Stack>
      </Stack>
    );
  },
);

CommunicationModalContent.displayName = 'CommunicationModalContent';
