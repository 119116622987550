import React, {useMemo, useState, useCallback} from 'react';
import {Trans} from 'react-i18next';

import {Stack, Button, Box} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Calendar} from '@/atoms/Calendar';
import {ChipAmPm} from '@/atoms/ChipAmPm';
import {ChipNumber} from '@/atoms/ChipNumber';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {
  setHourToDayjs,
  capitalizeWords,
  setHourMinutesSeconds,
} from '@/lib/utils';
import {useStyles} from '@/organisms/Feedback/FeedbackReminder/styles';
import {GetFeedbackResponseV2} from '@/store/feedback/v2';
import {GetTaskHeaderDetailsResponse} from '@/store/tasks/types';
import {Colors} from '@/themes/variables';

interface FeedbackReminderProps {
  taskHeaderData?: GetTaskHeaderDetailsResponse;
  currentReminder: GetFeedbackResponseV2['callBackDateTime'];
  onReminderChange: (reminder: string | null) => unknown;
  taskIsEditable: boolean;
}

const FeedbackReminder = ({
  taskHeaderData,
  currentReminder,
  onReminderChange,
  taskIsEditable,
}: FeedbackReminderProps) => {
  const styles = useStyles();

  const locale = useMemo(() => dayjs.locale(), []);

  /**
   * @brief Is italian
   * @note This variable is used to check if the locale is italian
   */
  const isItalian = useMemo(() => locale === 'it', [locale]);

  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.C_DATE_TIME',
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * @brief Toggle modal
   * @note This function is used to toggle the modal
   */
  const toggleModal = useCallback((value?: boolean) => {
    setShowModal(prev => (value !== undefined ? value : !prev));
  }, []);

  /**
   * @brief Label value
   * @note This variable is used to set the label and value of the reminder section
   */
  const labelValue = useMemo(() => {
    if (!!currentReminder) {
      return {
        label: getTranslationWithValue(0, 'contactReminderDate'),
        value: capitalizeWords(
          dayjs(currentReminder)
            .utc()
            .format(isItalian ? 'll LT' : 'Do MMMM YYYY LT')
            .toString(),
        ),
      };
    }

    return {
      label: getTranslationWithValue(0, 'contactReminderDate'),
      value: getTranslationWithValue(0, 'noReminderSet'),
    };
  }, [currentReminder, getTranslationWithValue, isItalian]);

  /**
   * @brief Button label icon
   * @note This variable is used to set the label and icon of the button
   */
  const buttonLabelIcon = useMemo<{label: string; icon: IconNames}>(() => {
    if (!!currentReminder) {
      return {
        label: getTranslationWithValue(0, 'changeDate'),
        icon: 'open_external',
      };
    }

    return {
      label: getTranslationWithValue(0, 'setReminder'),
      icon: 'open_external',
    };
  }, [currentReminder, getTranslationWithValue]);

  return (
    <>
      <Stack sx={styles.labelValueCtaContainer}>
        <LabelValue label={labelValue?.label} value={labelValue?.value} />
        <Button
          disabled={!taskIsEditable}
          {...(taskIsEditable && {
            onClick: () => toggleModal(true),
          })}
          variant="baseSecondary"
          endIcon={<IconSVG icon={buttonLabelIcon?.icon} size={16} />}>
          {buttonLabelIcon?.label}
        </Button>
      </Stack>
      {showModal && (
        <Modal
          open={showModal}
          maxWidth={40}
          onClose={() => toggleModal(false)}
          dialogTitle={getTranslationWithValue(0, 'setReminder')}
          dialogContent={
            <ReminderModalContent
              taskHeaderData={taskHeaderData}
              currentReminder={currentReminder}
              onReminderChange={onReminderChange}
              toggleModal={toggleModal}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(FeedbackReminder);

interface ReminderModalContentProps
  extends Omit<FeedbackReminderProps, 'taskIsEditable'> {
  toggleModal: (value?: boolean) => unknown;
}

const ReminderModalContent = React.memo(
  ({
    taskHeaderData,
    currentReminder,
    onReminderChange,
    toggleModal,
  }: ReminderModalContentProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'FeedbackBox.C_DATE_TIME',
    );
    const styles = useStyles();

    const locale = useMemo(() => dayjs.locale(), []);

    /**
     * @brief Is italian
     * @note This variable is used to check if the locale is italian
     */
    const isItalian = useMemo(() => locale === 'it', [locale]);

    /**
     * @brief Step
     * @note This variable is used to set the step of the reminder modal
     */
    const [step, setStep] = useState<'date' | 'time'>('date');

    /**
     * @brief Local reminder
     * @note This variable is used to set the local reminder
     */
    const [localReminder, setLocalReminder] = useState<Dayjs | undefined>(
      currentReminder ? dayjs(currentReminder).utc() : undefined,
    );

    /**
     * @brief AM PM state
     * @note This variable is used to set the AM/PM state
     */
    const [amPmState, setAmPmState] = useState<'AM' | 'PM'>(() => {
      if (!isItalian && localReminder) {
        return localReminder.utc().format('A').toString().toUpperCase() as
          | 'AM'
          | 'PM';
      }
      return 'PM';
    });

    /**
     * @brief Can submit
     * @note This variable is used to check if the reminder can be submitted
     */
    const canSubmit = useMemo(() => {
      return !!localReminder;
    }, [localReminder]);

    /**
     * @brief Handle reset selection
     * @note This function is used to handle the reset selection
     */
    const handleResetSelection = useCallback(() => {
      setLocalReminder(undefined);
      setAmPmState('PM');
      setStep('date');
    }, []);

    /**
     * @brief Go to next step
     * @note This function is used to go to the next step
     */
    const goToNextStep = useCallback(() => {
      if (step === 'date') {
        setStep('time');
        return;
      }

      onReminderChange(localReminder!.utc().toISOString());
      toggleModal(false);
    }, [localReminder, onReminderChange, step, toggleModal]);

    /**
     * @brief Primary button label
     * @note This variable is used to set the primary button label based on the current step
     */
    const primaryButtonLabel = useMemo(() => {
      return getTranslationWithValue(
        0,
        step === 'date' ? 'goToTimeSelection' : 'saveDateAndTime',
      );
    }, [getTranslationWithValue, step]);

    /**
     * @brief Hour format
     * @note This variable is used to set the hour format based on the locale
     */
    const hourFormat = useMemo(() => (isItalian ? 'HH' : 'hh'), [isItalian]);

    const formatNewDate = useCallback(
      (date: Dayjs) => {
        if (localReminder) {
          return localReminder.set('date', date?.get('date'));
        }

        return setHourMinutesSeconds(date?.subtract(1, 'day'), 24);
      },
      [localReminder],
    );

    return (
      <Stack direction="column" gap={3.2} width="100%" marginTop={0.8}>
        <Trans
          i18nKey="FeedbackBox.C_DATE_TIME.modalSubTitle"
          components={{
            wrapper: <BodyText color={Colors.GreyText} />,
            heavy: <BodyText heavy color={Colors.GreyText} />,
          }}
        />
        <Box margin="0 auto">
          {step === 'date' && (
            <Calendar
              onSelect={date => {
                const updatedDate = formatNewDate(date);
                setLocalReminder(updatedDate);
                setAmPmState(
                  updatedDate.utc().format('A').toString().toUpperCase() as
                    | 'AM'
                    | 'PM',
                );
              }}
              minDate={dayjs(taskHeaderData?.campaignStartDate!)}
              maxDate={dayjs(taskHeaderData?.campaignEndDate!).add(7, 'days')}
              selectedDate={localReminder ? dayjs(localReminder) : undefined}
            />
          )}
          {step === 'time' && (
            <Stack direction="row" gap={0.8} alignItems="center">
              <ChipNumber
                id="hours"
                selectedValue={parseInt(
                  localReminder!.utc().format(hourFormat),
                )}
                onSelected={hours =>
                  setLocalReminder(
                    setHourToDayjs(
                      localReminder!,
                      hours,
                      !isItalian ? amPmState === 'PM' : undefined,
                    ),
                  )
                }
                startNumber={isItalian ? 0 : 1}
                endNumber={isItalian ? 23 : 12}
              />
              <BodyText>:</BodyText>
              <ChipNumber
                id="minutes"
                selectedValue={parseInt(localReminder!.utc().format('mm'))}
                onSelected={minutes =>
                  setLocalReminder(localReminder!.minute(minutes))
                }
                startNumber={0}
                endNumber={59}
              />
              {!isItalian && !!amPmState && (
                <ChipAmPm
                  selectedValue={amPmState}
                  onSelected={amPm => {
                    setAmPmState(amPm);
                    setLocalReminder(
                      setHourToDayjs(
                        localReminder!,
                        localReminder!.hour(),
                        amPm === 'PM',
                      ),
                    );
                  }}
                />
              )}
            </Stack>
          )}
        </Box>
        <Stack sx={styles.buttonContainer}>
          <Button
            className="buttonCta"
            variant="baseSecondary"
            fullWidth
            disabled={!canSubmit}
            {...(canSubmit && {onClick: handleResetSelection})}>
            {getTranslationWithValue(0, 'resetSelection')}
          </Button>
          <Button
            className="buttonCta"
            variant="basePrimary"
            fullWidth
            disabled={!canSubmit}
            {...(canSubmit && {onClick: goToNextStep})}>
            {primaryButtonLabel}
          </Button>
        </Stack>
      </Stack>
    );
  },
);

ReminderModalContent.displayName = 'ReminderModalContent';
