import React, {useState, useMemo, useCallback, useEffect} from 'react';
import ReactGA from 'react-ga4';

import {Box, Skeleton} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {useParamsState} from '@/hooks/useParamsState';
import {EmptySearchResult} from '@/molecules/EmptySearchResult';
import {HomepageFormbar} from '@/molecules/HomepageFormbar';
import {HomepageFormBarProps} from '@/molecules/HomepageFormbar/interfaces';
import {SearchResult} from '@/organisms/SearchResult';
import {useStyle} from '@/pages/Loyalty/styles';
import {useAuthentication} from '@/store/authentication';
import {useCustomerInfo} from '@/store/customersInfo';
import {PostCustomerInfoBody} from '@/store/customersInfo/interfaces';
import {usePersonas} from '@/store/personas';
import {Base} from '@/templates/Base';

const Loyalty = () => {
  const styles = useStyle();
  const {
    userData,
    isAreaManager,
    isContactCenterSupervisor,
    isContactCenterAgent,
  } = useAuthentication();

  /* Query to get user data  */
  const {postCustomerInfo} = useCustomerInfo();
  const {getBannerCountry} = usePersonas();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Loyalty',
    });
  }, []);

  const [toggleSearch, setToggleSearch] = useState<boolean>(false);

  const {paramsState, setParamsState} = useParamsState<{
    q: string;
    bannerSearch: string;
    countrySearch: string;
  }>(null, [], 'q', 'bannerSearch', 'countrySearch');

  const [localState, setLocalState] = useState<{
    q: string;
    bannerSearch: string;
    countrySearch: string;
  }>({
    ...paramsState,
    q: paramsState?.q || '',
    bannerSearch: paramsState?.bannerSearch || userData?.banner!,
    countrySearch: paramsState?.countrySearch || userData?.country!,
  });
  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  useEffect(() => {
    if (userData?.banner !== localState?.bannerSearch && !isCC) {
      setLocalState(prevState => ({
        ...prevState,
        bannerSearch: userData?.banner!,
      }));
    }

    if (userData?.country !== localState?.countrySearch && !isCC) {
      setLocalState(prevState => ({
        ...prevState,
        countrySearch: userData?.country!,
      }));
    }
  }, [
    isCC,
    localState?.bannerSearch,
    localState?.countrySearch,
    userData?.banner,
    userData?.country,
  ]);

  const personasPayload = useMemo<PostCustomerInfoBody>(() => {
    return {
      q: paramsState.q,
      bannerSearch: paramsState.bannerSearch,
      countrySearch: paramsState.countrySearch,
    };
  }, [paramsState.bannerSearch, paramsState.countrySearch, paramsState.q]);

  const {
    data: CustomersInfo,
    isFetching: CustomersInfoFetching,
    isFetched: CustomersInfoFetched,
  } = useQuery(
    ['postCustomerInfo', personasPayload, userData?.currentStore?.storeId],
    () => postCustomerInfo(personasPayload),
    {
      retry: false,
      enabled: toggleSearch,
      refetchOnWindowFocus: false,
      onSettled: () => {
        setToggleSearch(false);
      },
    },
  );

  const {data: BannerCountry} = useQuery(
    ['getBannerCountry', localState?.bannerSearch],
    () => getBannerCountry(localState?.bannerSearch),
    {
      retry: false,
      enabled: isCC,
      refetchOnWindowFocus: false,
      onSuccess: ({countries}) => {
        if (
          !countries?.find(
            country => country.name === localState?.countrySearch,
          )
        ) {
          setLocalState(prevState => ({
            ...prevState,
            countrySearch: countries[0]?.name || prevState.countrySearch,
          }));
        }
      },
    },
  );

  const submitSearch = useCallback(() => {
    setParamsState({
      q: localState?.q,
      bannerSearch: localState?.bannerSearch,
      countrySearch: localState?.countrySearch,
    });
    setToggleSearch(true);
  }, [
    localState?.bannerSearch,
    localState?.countrySearch,
    localState?.q,
    setParamsState,
  ]);

  const handleValueChange = useCallback(
    (
      key: keyof Pick<
        HomepageFormBarProps,
        'q' | 'bannerSearch' | 'countrySearch'
      >,
      val: string,
    ) => {
      setLocalState(prevState => ({
        ...prevState,
        [key]: val,
      }));
    },
    [],
  );

  const homeFormbarProps: HomepageFormBarProps = useMemo(() => {
    return {
      q: localState?.q,
      onFreeSearchChange: newValue => handleValueChange('q', newValue),
      bannerSearch: localState?.bannerSearch,
      bannerOptions: isCC
        ? BannerCountry?.banners
        : [{name: localState?.bannerSearch}],
      onBannerChange: newValue => handleValueChange('bannerSearch', newValue),
      countrySearch: localState?.countrySearch,
      countryOptions: isCC
        ? BannerCountry?.countries
        : [{name: localState?.countrySearch}],
      onCountryChange: newValue => handleValueChange('countrySearch', newValue),
      onSubmit: submitSearch,
      disableSelect: isAreaManager,
    };
  }, [
    localState?.q,
    localState?.bannerSearch,
    localState?.countrySearch,
    isCC,
    BannerCountry?.banners,
    BannerCountry?.countries,
    submitSearch,
    isAreaManager,
    handleValueChange,
  ]);

  const renderData = useMemo(() => {
    if (CustomersInfoFetching) {
      return (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{
            width: '100%',
            height: '90%',
            maxWidth: {
              xs: '705px',
              md: '930px',
            },
          }}
        />
      );
    }

    if (CustomersInfoFetched && !CustomersInfo) {
      return <EmptySearchResult />;
    }

    if (CustomersInfo) {
      return <SearchResult {...CustomersInfo} />;
    }

    return null;
  }, [CustomersInfo, CustomersInfoFetched, CustomersInfoFetching]);

  return (
    <Base extendedNavbar>
      <Box sx={styles.searchBar}>
        <HomepageFormbar {...homeFormbarProps} />
      </Box>
      <Box sx={styles.resultsContainer}>
        <Box sx={styles.results}>{renderData}</Box>
      </Box>
    </Base>
  );
};

export default React.memo(Loyalty);
