export enum LOYALTY_CHANNEL {
  RETAIL = 'RETAIL',
  ECOMM = 'ECOMM',
  ECOMM_RETAIL = 'ECOMM,RETAIL',
}

export interface RewardsTableBody {
  bannerSearch: string;
  countrySearch: string;
  cardNumberSearch: string;
  page?: number;
  limit?: number;
  benefitCode?: string;
  status?: string;
  startDate?: string | null;
  endDate?: string | null;
}

export interface RewardsData {
  id: 0;
  code: string;
  status: {
    name: string;
    color: string;
    clickBook: boolean;
  };
  lastUsage: Date;
  usageNumber: number;
  maxUsageNumber: number;
  bookedDate: Date;
  expirationDate: Date;
  acquisitionDate: Date;
  name: string;
  description: string;
  category: string;
  mechanism: string;
  redemptionRule: string;
  resendEmail: boolean;
  book: boolean;
  upc: string[] | null;
  promocodeRetail: string;
  promocodeEcommerce: string;
  channel: LOYALTY_CHANNEL;
}

export interface RewardsTableResponse {
  data: RewardsData[];
  metadataDTO: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
  extra: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
}

export interface ExportRewardsTableBody {
  bannerSearch: string;
  countrySearch: string;
  cardNumberSearch: string;
  page?: number;
  limit?: number;
  benefitCode?: string;
  status?: string;
  startDate?: string | null;
  endDate?: string | null;
}

export interface GetRewardSelectionsParams {
  banner: string;
  country: string;
}

export interface GetRewardSelectionsResponse {
  code: string;
  description: string;
}

export interface RewardBookBody {
  bannerSearch: string;
  countrySearch: string;
  cardNumberSearch: string;
  code: string;
  promocodeRetail: string;
  widthBarCode: string | number;
  heightBarCode: string | number;
  confirmOpening?: boolean;
}

export interface RewardBookResponse {
  barCodeBase64BenefitCode: string;
  code: string;
}
