import {PropsWithChildren} from 'react';
import {I18nextProvider, initReactI18next} from 'react-i18next';

import i18n from 'i18next';
import HTTPApi from 'i18next-http-backend';

i18n.use(initReactI18next).use(HTTPApi).init({
  lowerCaseLng: true,
  fallbackLng: 'en',
  debug: true,
});

const TranslationProvider = ({children}: PropsWithChildren) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default TranslationProvider;
