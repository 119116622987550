import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const linkedTasksContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      '.row': {
        alignItems: 'center',
        '&__heading': {
          tr: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            th: {
              whiteSpace: 'nowrap',
              '&:first-child': {
                textAlign: 'left',
              },
              '&:nth-child(2)': {
                width: theme.spacing(0.1),
              },
              paddingY: theme.spacing(1.6),
              paddingX: theme.spacing(0.8),
              '&:last-child': {
                width: theme.spacing(2.4),
              },
            },
          },
        },
        '&__task': {
          pointerEvents: 'none',
          '&__clickable': {
            cursor: 'pointer',
            pointerEvents: 'auto',
            opacity: 1,
            transition: 'all 0.2s linear',
            ':hover': {
              opacity: 0.7,
            },
          },
          td: {
            whiteSpace: 'nowrap',
            padding: theme.spacing(0.8),
            verticalAlign: 'middle',
            '&:first-child': {
              textAlign: 'left',
              borderTopLeftRadius: theme.spacing(0.6),
              borderBottomLeftRadius: theme.spacing(0.6),
            },
            '&:nth-child(2)': {
              width: theme.spacing(0.1),
            },
            '&:last-child': {
              span: {
                width: theme.spacing(2.4),
                position: 'relative',
                display: 'block',
                svg: {
                  width: theme.spacing(2.4),
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
              },
              borderTopRightRadius: theme.spacing(0.6),
              borderBottomRightRadius: theme.spacing(0.6),
            },
          },
          '&:nth-child(even)': {
            backgroundColor: Colors.GreyLight,
          },
          '.dueDate': {
            textTransform: 'capitalize',
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({linkedTasksContainer}), [linkedTasksContainer]);
};
