import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {IconSVG} from '../IconSVG';
import {Text} from '../Typography/Text';
import {noop} from '../Utils/noop';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const DataTable = ({
  columnDef,
  data = [],
  maxHeight = '100%',
  handleExport,
  handleFilter,
  handleFiltersReset,
  fetchNextPage = noop,
  isLoadingMore = false,
  columnVisibility,
  canExport = false,
  canResetFilters = false,
  filterNumber = 0,
  tableId,
}: IProps) => {
  const {
    tableContainer,
    tableHeadCell,
    tableBodyCell,
    tableBodyRow,
    button,
    text,
    rightSticky,
    leftSticky,
  } = useStyle();

  const table = useReactTable({
    data,
    columns: columnDef,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: columnVisibility,
    },
  });

  const {t} = useTranslation();

  const {ref: loaderRef, inView} = useInView();

  // if loaderRef is in view, fetch next page
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <>
      {handleExport && handleFilter && handleFiltersReset ? (
        <Stack direction="row" justifyContent="end">
          {canExport && (
            <Button sx={button()} onClick={handleExport}>
              <IconSVG icon="export" size={22} />
              <Text paragraph variant="body" sx={text}>
                {t('CustomerProfile.dataTable.buttons.export')}
              </Text>
            </Button>
          )}
          <Button sx={button()} onClick={handleFilter}>
            <IconSVG icon="filter" size={22} />
            <Text paragraph variant="body" sx={text}>
              {t('CustomerProfile.dataTable.buttons.filter')} ({filterNumber})
            </Text>
          </Button>
          <Button
            sx={button(canResetFilters)}
            {...(canResetFilters && {onClick: handleFiltersReset})}
            disabled={!canResetFilters}>
            <IconSVG icon="reset" size={22} />
            <Text paragraph variant="body" sx={text}>
              {t('CustomerProfile.dataTable.buttons.filterReset')}
            </Text>
          </Button>
        </Stack>
      ) : (
        <></>
      )}
      <TableContainer sx={{maxHeight}} {...(!!tableId && {id: tableId})}>
        <Table sx={tableContainer} stickyHeader>
          {/* HEADER */}
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell
                    key={header.id}
                    sx={[
                      tableHeadCell,
                      header?.column?.columnDef?.meta?.sticky === 'right' &&
                        rightSticky,
                      header?.column?.columnDef?.meta?.sticky === 'left' &&
                        leftSticky,
                    ]}>
                    <Text variant="body" superHeavy>
                      {header.isPlaceholder ||
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {/* BODY */}
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} sx={tableBodyRow}>
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    sx={[
                      tableBodyCell,
                      cell?.column?.columnDef?.meta?.sticky === 'right' &&
                        rightSticky,
                      cell?.column?.columnDef?.meta?.sticky === 'left' &&
                        leftSticky,
                    ]}>
                    <Text medium>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box ref={loaderRef} textAlign="center">
          {isLoadingMore && (
            <CircularProgress disableShrink color="primary" size={25} />
          )}
        </Box>
      </TableContainer>
    </>
  );
};

export default React.memo(DataTable);
