import {faker} from '@faker-js/faker';

import {AuthenticatedUser, UserAppointmentType} from '@/definitions/user';
import {RoleName, Modules, AuthenticatedUserStatus} from '@/definitions/users';

const createRandomLoggedUser = (): AuthenticatedUser => ({
  luxId: faker.person.firstName(),
  banner: 'banner_4',
  country: 'country_4',
  language: 'IT',
  appointmentType: UserAppointmentType.TAB_LITE,
  roles: [
    {
      name: RoleName.STORE_ASSOCIATE,
      description: 'Sales Associate',
    },
  ],
  configuration: {
    moduleActive: [Modules.MYACCOUNT, Modules.CLIENTELING],
    loyaltyProgram: 'loyaltyProgram',
  },
  currentStore: {
    id: 1,
    storeId: 'A060',
    region: 'Emea',
    banner: 'SV',
    country: 'IT',
    zone: 'SV',
    city: 'Milano',
    stateProvince: 'MI',
    address: 'Corso Matteotti 22',
    storeName: '113 MI-MILANO PIAZZA SAN BABILA',
    division: 'Optical',
    businessModel: 'Corporate',
    storeHostName: 'Permanent Store Traditional',
  },
  firstName: faker.person.firstName(),
  lastName: faker.person.lastName(),
  status: AuthenticatedUserStatus.ACTIVE,
  email: faker.internet.email(),
  token: faker.string.hexadecimal(),
  namToken: faker.string.hexadecimal(),
  hasLoyalty: false,
});

export const randomLoggedUsers = Array.from({length: 7}, () =>
  createRandomLoggedUser(),
);

export const randomLoggedUser = createRandomLoggedUser();
