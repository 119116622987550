import React, {useCallback, useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {Container, Stack, Box, Skeleton} from '@mui/material';
import {GridColumnHeaderParams} from '@mui/x-data-grid';
import {useQuery} from '@tanstack/react-query';
import dayjs, {Dayjs} from 'dayjs';
import {Dispatch} from 'redux';

import {ChipDatepicker} from '@/atoms/ChipDatepicker';
import {ChipDropdown} from '@/atoms/ChipDropdown';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {Table} from '@/atoms/Table';
import {useTableColumns} from '@/atoms/Table/TableColumns';
import {Text} from '@/atoms/Typography/Text';
import {truncate} from '@/lib/utils';
import {HeadingCard} from '@/molecules/HeadingCard';
import {useNavigation} from '@/navigation/useNavigation';
import {useStyle} from '@/pages/Clienteling/SubPages/CampaignReportDetail/styles';
import {useAuthentication} from '@/store/authentication';
import {
  MembersReportFiltersAction,
  MembersReportFilters,
} from '@/store/membersReportFilters/definitions';
import {
  MembersReportFiltersChangeDates,
  MembersReportFiltersChangeField,
  MembersReportFiltersReset,
  MembersReportFiltersChangePage,
} from '@/store/membersReportFilters/membersReportFilters.actions';
import {selectMembersReportFilters} from '@/store/membersReportFilters/selector';
import {useReportFilters} from '@/store/reportFilters/hooks';
import {useReport} from '@/store/reports/hooks';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

const defaultStartDate = dayjs().startOf('month');
const defaultEndDate = dayjs();

const MembersReports = () => {
  const {t} = useTranslation();
  const styles = useStyle();
  const {goBack} = useNavigation();
  const {userData} = useAuthentication();

  const {reportDetailColumns} = useTableColumns();
  const {
    getReportsDetailHeader,
    getReportDetailCampaigns,
    getReportsDetailUnassigned,
  } = useReport();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Members report',
    });
  }, []);

  // Filters state
  const membersReportFilters = useSelector<
    {membersReportFilters: MembersReportFilters},
    MembersReportFilters
  >(selectMembersReportFilters);

  const dispatch = useDispatch<Dispatch<MembersReportFiltersAction>>();

  const handleDateChange = useCallback(
    (startDate: Dayjs, endDate: Dayjs) => {
      dispatch(MembersReportFiltersChangeDates(startDate, endDate));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (key: 'codes' | 'assignees', value: any[]) => {
      dispatch(MembersReportFiltersChangeField(key, value));
    },
    [dispatch],
  );

  const canReset = useMemo(() => {
    const hasDiffStartDate = !defaultStartDate
      .utc()
      .isSame(membersReportFilters?.startDate, 'date');
    const hasDiffEndDate = !defaultEndDate
      .utc()
      .isSame(membersReportFilters?.endDate, 'date');
    const hasCodes = membersReportFilters?.codes.length > 0;
    const hasAssignees = membersReportFilters?.assignees.length > 0;

    return hasAssignees || hasCodes || hasDiffStartDate || hasDiffEndDate;
  }, [
    membersReportFilters?.assignees.length,
    membersReportFilters?.codes.length,
    membersReportFilters?.endDate,
    membersReportFilters?.startDate,
  ]);

  // Filters API
  const {getFilterCampaignDetail, getFilterAssignees} = useReportFilters();

  // Assignees filter
  const {data: AssigneesFilter, isLoading: AssigneesFilterLoading} = useQuery(
    ['campaignAssigneesFilters', userData?.currentStore?.id],
    () =>
      getFilterAssignees({
        storeId: userData?.currentStore?.id!,
      }),
    {
      keepPreviousData: true,
    },
  );

  const assigneesOptions = useMemo(() => {
    if (!AssigneesFilter) return [];

    return AssigneesFilter?.map(assignee => {
      return {
        label: `${assignee?.assigneeFirstname?.toLowerCase()} ${assignee?.assigneeLastname?.toLowerCase()}`,
        value: assignee?.uuid,
      };
    });
  }, [AssigneesFilter]);

  // Campaigns filter
  const {data: CampaignsFilter, isLoading: CampaignsFilterLoading} = useQuery(
    [
      'campaignDetailFilters',
      membersReportFilters?.startDate,
      membersReportFilters?.endDate,
      userData?.currentStore?.id,
    ],
    () =>
      getFilterCampaignDetail({
        storeId: userData?.currentStore?.id!,
        startDate: membersReportFilters?.startDate?.utc().format('YYYY-MM-DD'),
        endDate: membersReportFilters?.endDate?.utc().format('YYYY-MM-DD'),
      }),
    {
      keepPreviousData: true,
    },
  );

  const campaignsOptions = useMemo(() => {
    if (!CampaignsFilter) return [];

    return CampaignsFilter?.map(campaign => {
      return {
        label: campaign?.name,
        value: campaign?.code,
      };
    });
  }, [CampaignsFilter]);

  // Report detail header
  const {data: ReportsHeader, isLoading: ReportsHeaderLoading} = useQuery(
    [
      'reportsDetailHeader',
      membersReportFilters?.startDate,
      membersReportFilters?.endDate,
      userData?.currentStore?.id,
      membersReportFilters?.assignees,
      membersReportFilters?.codes,
    ],
    () =>
      getReportsDetailHeader({
        storeId: userData?.currentStore?.id!,
        startDate: membersReportFilters?.startDate?.utc().format('YYYY-MM-DD'),
        endDate: membersReportFilters?.endDate?.utc().format('YYYY-MM-DD'),
        codes:
          membersReportFilters?.codes?.length > 0
            ? membersReportFilters?.codes
            : null,
        userUuids:
          membersReportFilters?.assignees?.length > 0
            ? membersReportFilters?.assignees
            : null,
      }),
  );

  // Report detail unassigned
  const {data: ReportsUnassigned} = useQuery(
    [
      'reportsDetailUnassigned',
      membersReportFilters?.startDate,
      membersReportFilters?.endDate,
      membersReportFilters?.codes,
    ],
    () =>
      getReportsDetailUnassigned({
        storeId: userData?.currentStore?.id!,
        startDate: membersReportFilters!.startDate?.utc().format('YYYY-MM-DD'),
        endDate: membersReportFilters!.endDate?.utc().format('YYYY-MM-DD'),
        codes:
          membersReportFilters?.codes?.length > 0
            ? membersReportFilters?.codes
            : null,
      }),
    {
      keepPreviousData: true,
      enabled: Boolean(membersReportFilters),
    },
  );

  const {
    data: ReportDetailCampaigns,
    isFetching: ReportDetailCampaignsLoading,
  } = useQuery(
    [
      'reportDetailCampaigns',
      membersReportFilters?.page,
      membersReportFilters?.sortBy,
      membersReportFilters?.orderBy,
      membersReportFilters?.startDate,
      membersReportFilters?.endDate,
      membersReportFilters?.codes,
      membersReportFilters?.assignees,
      userData?.currentStore.id,
    ],
    () =>
      getReportDetailCampaigns({
        page: membersReportFilters?.page,
        sortBy: membersReportFilters?.sortBy,
        orderBy: membersReportFilters?.orderBy,
        startDate: membersReportFilters?.startDate?.utc().format('YYYY-MM-DD'),
        endDate: membersReportFilters?.endDate?.utc().format('YYYY-MM-DD'),
        codes:
          membersReportFilters?.codes?.length > 0
            ? membersReportFilters?.codes
            : undefined,
        userUuids:
          membersReportFilters?.assignees?.length > 0
            ? membersReportFilters?.assignees
            : undefined,
        storeId: userData?.currentStore.id!,
      }),
    {
      keepPreviousData: true,
      retry: false,
    },
  );

  // Table Rows
  const reportsRows = useMemo(
    () =>
      ReportDetailCampaigns?.data?.map(item => ({
        id: item.id,
        ASSIGNEE: {
          firstName: item.assigneeFirstname,
          lastName: item.assigneeLastname,
          id: item.assigneeLuxId,
        },
        TASK_NUMBER: item.taskNumber,
        NEW_TASK: `${item.newTask} (${Math.round(item.newTaskPerc)}%)`,
        COMPLETED_TASK: `${item.completedTask} (${Math.round(
          item.completedTaskPerc,
        )}%)`,
        MOM: Math.round(item.mom),
        POSITIVE_FEEDBACK: `${item.positiveFeedback} (${Math.round(
          item.positiveFeedbackPerc,
        )}%)`,
        APPOINTMENT_BOOKED: `${item.appointmentBooked} (${Math.round(
          item.appointmentBookedPerc,
        )}%)`,
        APPOINTMENT_CHECKIN: `${item.appointmentCheckIn} (${Math.round(
          item.appointmentCheckInPerc,
        )}%)`,
      })) || [],
    [ReportDetailCampaigns],
  );

  // Update table pagination
  const changePage = useCallback(
    (page: number) => {
      dispatch(MembersReportFiltersChangePage(page));
    },
    [dispatch],
  );

  // Sort table columns
  const handleColumnHeaderClick = useCallback(
    (params: GridColumnHeaderParams) => {
      const {field, colDef} = params;
      const {sortable} = colDef;

      if (sortable) {
        dispatch(
          MembersReportFiltersChangeField(
            'orderBy',
            membersReportFilters?.orderBy === 'ASC' ? 'DESC' : 'ASC',
          ),
        );
        dispatch(MembersReportFiltersChangeField('sortBy', field));
      }
    },
    [dispatch, membersReportFilters?.orderBy],
  );

  // If no store associates are selected in the filter, add a row on top for unassigned tasks
  const parsedRows = useMemo(() => {
    if (
      ReportsUnassigned?.assigneeLuxId &&
      membersReportFilters?.assignees &&
      membersReportFilters?.assignees.length < 1
    ) {
      return [
        {
          id: ReportsUnassigned.assigneeLuxId,
          ASSIGNEE: {
            firstName: t('ReportsTable.USER_UNASSIGNED_NAME'),
            lastName: t('ReportsTable.USER_UNASSIGNED_LAST_NAME'),
            id: ReportsUnassigned.assigneeLuxId,
          },
          TASK_NUMBER: ReportsUnassigned.taskNumber,
          NEW_TASK: '-',
          COMPLETED_TASK: '-',
          MOM: null,
          POSITIVE_FEEDBACK: '-',
          APPOINTMENT_BOOKED: '-',
          APPOINTMENT_CHECKIN: '-',
        },
        ...reportsRows,
      ];
    }
    return reportsRows;
  }, [reportsRows, ReportsUnassigned, t, membersReportFilters]);

  return (
    <Base
      title={t('ReportDetail.headerTitle')}
      noContainer={
        <Container
          maxWidth="xl"
          sx={{paddingTop: `${theme.spacing(2.4)} !important`}}>
          <Stack direction="column" gap={2}>
            {/* Header, back button on the left and three info boxes on the right */}
            <Box sx={styles.row}>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                handleOnClick={goBack}
                iconStart={
                  <IconSVG icon="arrow_left_2" size={16} color="#000" />
                }>
                {t('Generic.ctas.back')}
              </Link>
              <Box sx={styles.row}>
                <Box sx={styles.headingBox}>
                  <Text variant="body" medium display="block">
                    {truncate(
                      `${userData?.currentStore?.storeId} - ${userData?.currentStore?.storeName}`,
                    )}
                  </Text>
                  <Text variant="caption" sx={styles.label}>
                    {t('ReportDetail.CampaignHeader.store')}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Stack sx={styles.filterContainer}>
              <Stack
                direction="row"
                alignItems="center"
                columnGap={0.8}
                flexWrap="wrap">
                <ChipDropdown
                  filterLabel={t('ReportsFilters.STORE_ASSOCIATE.multiple')}
                  allFilterLabel={t('ReportsFilters.STORE_ASSOCIATE.all')}
                  searchPlaceholder={t(
                    'ReportsFilters.STORE_ASSOCIATE.placeholder',
                  )}
                  selectedValues={membersReportFilters?.assignees}
                  options={assigneesOptions}
                  onApply={assignees => handleChange('assignees', assignees)}
                  disabled={AssigneesFilterLoading || !AssigneesFilter}
                  capitalize
                />
                <ChipDropdown
                  filterLabel={t('ReportsFilters.CAMPAIGNS.multiple')}
                  allFilterLabel={t('ReportsFilters.CAMPAIGNS.all')}
                  searchPlaceholder={t('ReportsFilters.CAMPAIGNS.placeholder')}
                  selectedValues={membersReportFilters?.codes}
                  options={campaignsOptions}
                  onApply={codes => handleChange('codes', codes)}
                  disabled={CampaignsFilterLoading || !CampaignsFilter}
                />
                <ChipDatepicker
                  startDate={membersReportFilters?.startDate}
                  endDate={membersReportFilters?.endDate}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  onApply={handleDateChange}
                  minDate={dayjs().utc().subtract(60, 'days')}
                  maxDate={dayjs()}
                />
              </Stack>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                iconStart={<IconSVG icon="reset" size={16} />}
                handleOnClick={() => {
                  dispatch(MembersReportFiltersReset());
                }}
                disabled={!canReset}
                noDecoration>
                {t('PurchaseHistory.filters.reset')}
              </Link>
            </Stack>
            {/* Hading card, grid of 10 info elements */}
            {ReportsHeaderLoading || !ReportsHeader ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={80}
              />
            ) : (
              <HeadingCard
                translationPath="ReportHeader"
                items={ReportsHeader}
              />
            )}
          </Stack>
        </Container>
      }>
      {!ReportDetailCampaignsLoading ? (
        <Table
          sx={{
            '.MuiDataGrid-row': {
              padding: '16px 0 !important',
            },
          }}
          columnHeaderHeight={88}
          sortModel={[
            {
              field: membersReportFilters?.sortBy ?? '',
              sort: membersReportFilters?.orderBy?.toLocaleLowerCase() as any,
            },
          ]}
          getRowId={row => row.id}
          checkbox={false}
          columns={reportDetailColumns}
          rows={parsedRows}
          currentPage={membersReportFilters?.page}
          onColumnHeaderClick={handleColumnHeaderClick}
          totalPages={
            ReportDetailCampaigns?.metadataDTO
              ? ReportDetailCampaigns?.metadataDTO?.total_pages
              : 1
          }
          setPage={(_e, page) => changePage(page)}
          loading={ReportDetailCampaignsLoading || !ReportDetailCampaigns}
        />
      ) : (
        <Skeleton
          height={400}
          animation="wave"
          variant="rounded"
          width="100%"
        />
      )}
    </Base>
  );
};

export default React.memo(MembersReports);
