import {Dayjs} from 'dayjs';

export enum MembersReportFiltersActions {
  changeField = 'change members report field',
  changeDates = 'change members report dates',
  changePage = 'change members report page',
  resetFilters = 'reset members report filters',
}

interface ChangeFieldPayload<T> {
  key: keyof T;
  value: any;
}

export interface MembersReportFilters {
  assignees: Array<string>;
  codes: Array<string>;
  startDate: Dayjs;
  endDate: Dayjs;
  sortBy: string | null;
  orderBy: 'ASC' | 'DESC';
  page: number;
}

export type MembersReportFiltersAction =
  | {
      type: MembersReportFiltersActions.changeField;
      payload: ChangeFieldPayload<MembersReportFilters>;
    }
  | {
      type: MembersReportFiltersActions.changeDates;
      payload: {
        startDate: Dayjs;
        endDate: Dayjs;
      };
    }
  | {
      type: MembersReportFiltersActions.changePage;
      payload: number;
    }
  | {
      type: MembersReportFiltersActions.resetFilters;
      payload: undefined;
    };
