import {Modules, RoleName} from '@/definitions/users';

export enum AnonymousRoutes {
  LOGIN = '/login',
  AUTH = '/auth',
  COMPONENTS = '/components',
}

export enum AuthRoutes {
  MAIN = '/',
  MYACCOUNT = '/my-account',
  MYACCOUNT_CUSTOMER = '/my-account/customer',
  MYACCOUNT_CUSTOMER_TASK_DETAILS = '/my-account/customer/task-details/:taskId',
  MYACCOUNT_CLIENT_RESULTS = '/my-account/client-results',
  MYACCOUNT_PURCHASE_LIST = '/my-account/purchase-list',
  CLIENTELING = '/clienteling',
  CLIENTELING_DASHBOARD = '/clienteling/dashboard',
  CLIENTELING_CAMPAIGNS = '/clienteling/campaigns',
  CLIENTELING_TASK_LIST = '/clienteling/task-list',
  CLIENTELING_TASK_DETAILS = '/clienteling/task-list/task-details/:taskId',
  CLIENTELING_CAMPAIGNS_REPORT_DETAIL = '/clienteling/campaigns/reports/:campaignCode',
  CLIENTELING_MEMBERS_REPORTS = '/clienteling/members/reports',
  CLIENTELING_CAMPAIGNS_REPORTS = '/clienteling/campaigns/reports',
  LOYALTY = '/loyalty',
  LOYALTY_PROFILE = '/loyalty/profile/:cardNumber/:banner/:country',
}

export const moduleToRoute: {[key in Modules]: AuthRoutes} = {
  [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
  [Modules.CLIENTELING]: AuthRoutes.CLIENTELING,
  [Modules.LOYALTY]: AuthRoutes.LOYALTY,
};

export const roleToRoute: {
  [role in RoleName]: Partial<{[module in Modules]: AuthRoutes}>;
} = {
  [RoleName.STORE_ASSOCIATE]: {
    [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
    [Modules.CLIENTELING]: AuthRoutes.CLIENTELING_TASK_LIST,
    [Modules.LOYALTY]: AuthRoutes.LOYALTY,
  },
  [RoleName.STORE_MANAGER]: {
    [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
    [Modules.CLIENTELING]: AuthRoutes.CLIENTELING_DASHBOARD,
    [Modules.LOYALTY]: AuthRoutes.LOYALTY,
  },
  [RoleName.AREA_MANAGER]: {
    [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
    [Modules.CLIENTELING]: AuthRoutes.CLIENTELING,
    [Modules.LOYALTY]: AuthRoutes.LOYALTY,
  },
  [RoleName.CC_SUPERVISOR]: {
    [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
    [Modules.LOYALTY]: AuthRoutes.LOYALTY,
  },
  [RoleName.CC_AGENT]: {
    [Modules.MYACCOUNT]: AuthRoutes.MYACCOUNT,
    [Modules.LOYALTY]: AuthRoutes.LOYALTY,
  },
};

export const moduleOrder: Modules[] = [
  Modules.MYACCOUNT,
  Modules.LOYALTY,
  Modules.CLIENTELING,
];
