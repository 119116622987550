import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {PiCaretDownBold} from 'react-icons/pi';

import {Stack, Box} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {useMatchMedia} from '@/hooks/useMatchMedia';
import {useStyles} from '@/molecules/CustomAccordion/styles';

interface CustomAccordionProps {
  topContent?: boolean;
  bottomContent?: boolean;
  baseContent?: (toggleAccordion: () => void) => JSX.Element;
  expandedContent: (toggleAccordion: () => void) => JSX.Element;
  accordionTitle?: JSX.Element;
  accordionLabel?: JSX.Element | string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disableClick?: boolean;
  hideAccordionLabel?: boolean;
  centerAccordionLabel?: boolean;
  labelAlign?: string;
}

const CustomAccordion = ({
  topContent = false,
  bottomContent = false,
  baseContent,
  expandedContent,
  accordionTitle = undefined,
  accordionLabel = undefined,
  isOpen,
  setIsOpen,
  disableClick = false,
  hideAccordionLabel = false,
  centerAccordionLabel = false,
  labelAlign = 'start',
}: CustomAccordionProps) => {
  const styles = useStyles();

  const baseContentRef = useRef<HTMLDivElement>(null);
  const fullContentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const {isPortrait} = useMatchMedia();

  const toggleAccordion = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    const updateMaxHeight = () => {
      if (isOpen) {
        setMaxHeight(fullContentRef?.current?.scrollHeight!);
      } else if (!isOpen && baseContentRef?.current) {
        setMaxHeight(baseContentRef?.current?.scrollHeight!);
      } else {
        setMaxHeight(0);
      }
    };

    updateMaxHeight();

    window.addEventListener('resize', updateMaxHeight);
    const resizeObserver = new ResizeObserver(updateMaxHeight);
    if (fullContentRef.current) {
      resizeObserver.observe(fullContentRef.current);
    }

    if (baseContentRef.current) {
      resizeObserver.observe(baseContentRef.current);
    }

    return () => window.removeEventListener('resize', updateMaxHeight);
  }, [isOpen, fullContentRef, baseContentRef, isPortrait]);

  const renderBaseContent = useMemo(() => {
    return (
      <Box className="baseContent" ref={baseContentRef}>
        {baseContent && baseContent(toggleAccordion)}
      </Box>
    );
  }, [baseContent, toggleAccordion]);

  const renderContent = useMemo(() => {
    return (
      <Box sx={styles.accordionContent(maxHeight, isOpen)}>
        {renderBaseContent && renderBaseContent}
        <Box className="fullContent" ref={fullContentRef}>
          {expandedContent(toggleAccordion)}
        </Box>
      </Box>
    );
  }, [
    expandedContent,
    isOpen,
    maxHeight,
    renderBaseContent,
    styles,
    toggleAccordion,
  ]);

  const renderAccordionLabel = useMemo(() => {
    if (!accordionLabel) return null;

    if (typeof accordionLabel === 'string') {
      return (
        <BodyText medium className="accordionLabel">
          {accordionLabel}
        </BodyText>
      );
    }

    return accordionLabel;
  }, [accordionLabel]);

  return (
    <Stack sx={styles.accordionContainer(isOpen, !!baseContent)}>
      {topContent && renderContent}
      <Stack
        sx={styles.accordionTitle(disableClick, !!accordionTitle)}
        {...(!disableClick && {onClick: toggleAccordion})}>
        {accordionTitle && (
          <Box className="accordionTitleBox">{accordionTitle}</Box>
        )}
        {!hideAccordionLabel && (
          <Stack
            sx={styles.accordionLabelContainer(
              centerAccordionLabel,
              labelAlign,
            )}>
            {renderAccordionLabel && renderAccordionLabel}
            <Box sx={styles.detailIcon(isOpen)}>
              <PiCaretDownBold fontSize={20} />
            </Box>
          </Stack>
        )}
      </Stack>
      {bottomContent && renderContent}
    </Stack>
  );
};

export default React.memo(CustomAccordion);
